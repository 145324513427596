import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { setFlexibleModal } from '../../../../actions/common';
import { setLimitationType, setShowHamburgerIcon } from '../../../../actions/subscriber';
import { FILTER_OPTIONS, LIMITATION_TYPE, LISTENER_TYPES, LOCAL_SHOW_ONLY_ENABLED_LISTENERS } from '../../../../constants';
import CenterSpinner from '../../../general/Loadings/CenterSpinner';
import LearnVideoPopup from '../../../general/popup/LearnVideoPopup';
import ListenersItems from './ListenersItems';

const ListenersLayout = () => {
     const dispatch = useDispatch();
     const swiperRef = useRef(null);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const accountListeners = useSelector((state) => state.subscriber.accountListeners);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);

     const listenerVideo = 'https://www.youtube.com/embed/3sgyMCj1cv4/?rel=0';
     const listType = LISTENER_TYPES.sort((a, b) => a.localeCompare(b));
     const listFilters = [FILTER_OPTIONS.SHOW_ALL, FILTER_OPTIONS.SHOW_ENABLED, ...listType];

     const [warningMessage] = useState(true);
     const [filterType, setFilterType] = useState();
     const [indexActive, setIndexActive] = useState(null);
     const [initClassName, setInitClassName] = useState('');
     const [listeners, setListeners] = useState(accountListeners);
     const [slideWrapperWidth, setSlideWrapperWidth] = useState(0);
     const [showListenerVideo, setShowListenerVideo] = useState(false);
     const [statusShowOnlyEnabled, setStatusShowOnlyEnabled] = useState(JSON.parse(localStorage.getItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS)) || false);

     useEffect(() => {
          if (flexibleModal.category) {
               const newListeners = accountListeners.filter((listener) => listener.type === flexibleModal.category);
               const _activePosition = listType.findIndex((type) => type === flexibleModal.category);

               setListeners(newListeners);
               setFilterType(flexibleModal.category);
               setIndexActive(_activePosition + 2);
          } else {
               if (!filterType) {
                    if (statusShowOnlyEnabled) {
                         const newListeners = accountListeners.filter((listener) => listener.configStatus === true);
                         setListeners(newListeners);
                         setFilterType(FILTER_OPTIONS.SHOW_ENABLED);
                         setIndexActive(1);
                    } else {
                         setFilterType(FILTER_OPTIONS.SHOW_ALL);
                         setIndexActive(0);
                    }
               } else {
                    if (filterType === FILTER_OPTIONS.SHOW_ENABLED) {
                         const newListeners = accountListeners.filter((listener) => listener.configStatus === true);
                         setListeners(newListeners);
                    }
               }
          }
     }, [statusShowOnlyEnabled, flexibleModal.category, accountListeners]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleFilter = (filter, index) => {
          setFilterType(filter);
          setIndexActive(index);

          if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
               swiperRef.current.swiper.slideTo(index);
          }

          dispatch(
               setFlexibleModal({
                    category: '',
               })
          );

          let newListeners = [];

          if (filter === FILTER_OPTIONS.SHOW_ALL) {
               newListeners = accountListeners;

               setStatusShowOnlyEnabled(false);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS, false);
          } else if (filter === FILTER_OPTIONS.SHOW_ENABLED) {
               newListeners = accountListeners.filter((listener) => listener.configStatus === true);

               setStatusShowOnlyEnabled(true);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS, true);
          } else {
               newListeners = accountListeners.filter((listener) => listener.type === filter);

               setStatusShowOnlyEnabled(false);
               localStorage.setItem(LOCAL_SHOW_ONLY_ENABLED_LISTENERS, false);
          }
          setListeners(newListeners);
     };

     const slideWrapper = document.querySelector('.swiper .swiper-wrapper');
     let slideWidth = 0;
     let disableSlider = false;

     const observer = new ResizeObserver((entries) => {
          for (let entry of entries) {
               setSlideWrapperWidth(entry.contentRect.width);
          }
     });

     if (slideWrapper) {
          observer.observe(slideWrapper);

          for (let i = 0; i < slideWrapper.children.length; i++) {
               slideWidth += slideWrapper.children[i].offsetWidth;
          }

          if (slideWidth < slideWrapperWidth) {
               disableSlider = true;
          }
     }

     useEffect(() => {
          if (swiperRef && swiperRef.current && swiperRef.current.swiper && indexActive < 10) {
               swiperRef.current.swiper.slideTo(5);
          }
     }, [swiperRef, indexActive, disableSlider]);

     console.log(`listeners => `, listeners);

     return (
          <div className="listeners">
               {activeListeners.length === 0 && warningMessage && (
                    <div className="message-warning">
                         No Listeners have been turned on. Turn on Listeners below for the applications your website uses.
                    </div>
               )}
               <div className="listener-title headline-wrapper">
                    <h1>Listeners</h1>
                    <button
                         onClick={() => {
                              setShowListenerVideo(true);
                         }}
                         className="text-top-right popup-btn"
                    >
                         What is a Listener?
                    </button>
                    <div className="description">
                         Find and turn on Listeners for applications and functionality you use on your website. Once a Listener is enabled, it will
                         appear in the navigation and you can enable specific features for your data layer.
                    </div>
               </div>

               {indexActive !== null && (
                    <div className="filter-slider d-flex justify-content-center align-items-center">
                         <Swiper
                              ref={swiperRef}
                              navigation={true}
                              slidesPerView={'auto'}
                              slidesPerGroup={4}
                              modules={[Navigation]}
                              width={null}
                              simulateTouch={false}
                              centeredSlides={disableSlider ? false : true}
                              className={`${initClassName}${disableSlider ? ' disable-slider' : ''}`}
                              onSwiper={(swiper) => {
                                   if (indexActive <= 5) {
                                        swiper.slideTo(5, 300, true);
                                   } else if (indexActive >= 13) {
                                        swiper.slideTo(13, 300, true);
                                   } else {
                                        swiper.slideTo(indexActive, 300, true);
                                   }
                              }}
                              onSlideChange={(swiper) => {
                                   if (swiper.activeIndex <= 5) {
                                        setInitClassName('slider-left-i');
                                   } else if (swiper.activeIndex >= 13) {
                                        setInitClassName('slider-right-i');
                                   } else {
                                        setInitClassName('');
                                   }
                              }}
                         >
                              {listFilters.map((filter, index) => {
                                   return (
                                        <SwiperSlide key={index}>
                                             <CButton
                                                  className={`btn-filter ${filterType === filter ? 'active' : ''}`}
                                                  onClick={() => handleFilter(filter, index)}
                                             >
                                                  {filter}
                                             </CButton>
                                        </SwiperSlide>
                                   );
                              })}
                         </Swiper>
                    </div>
               )}

               {listeners.length > 0 ? (
                    <ListenersItems listeners={listeners} filterType={filterType} />
               ) : (
                    <div className='no-filtering-result'>
                         <h2 className='d-flex justify-content-center align-items-center'>
                              No Listeners <CIcon icon='cil-ban' width={30} className='icon-cil-ban' />
                         </h2>
                    </div>
               )}
               <LearnVideoPopup
                    show={showListenerVideo}
                    onCancel={() => {
                         setShowListenerVideo(false);
                    }}
                    videoLink={listenerVideo}
                    headline="What are Listeners?"
                    description="Learn about Listeners and how to use them on your website."
               />
          </div>
     );
};

const Listeners = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const listenersLoading = useSelector((state) => state.subscriber.loadings.listeners);

     const {
          id: activeAccountId,
          numberOfListeners: usedListeners,
          numberOfPremium: usedPremium,
          packageNumberOfListeners: maxListeners,
          packageNumberOfPremium: maxPremium,
     } = activeAccount;

     // Check if user've hit a limitation of listeners or not
     // If there're still available listeners to use, then we will check premium listeners & features
     const checkLimitation = () => {
          if (maxListeners !== -1 && usedListeners >= maxListeners) {
               dispatch(setLimitationType(LIMITATION_TYPE.LISTENER));
               dispatch(setShowHamburgerIcon(true));
          } else if (maxPremium !== -1 && usedPremium >= maxPremium) {
               dispatch(setLimitationType(LIMITATION_TYPE.PREMIUM));
               dispatch(setShowHamburgerIcon(true));
          }

          return () => dispatch(setShowHamburgerIcon(false));
     };

     useEffect(checkLimitation, [usedListeners, maxListeners, usedPremium, maxPremium]); // eslint-disable-line react-hooks/exhaustive-deps
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);

     return <>{!listenersLoading && activeAccountId ? <ListenersLayout /> : <CenterSpinner />}</>;
};

export default Listeners;
