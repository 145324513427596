import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner.js';
import { EXTERNAL_DATA_CONNECTION_STATUS } from '../../../../../../constants/index.js';

export const ExternalDetailDetailContext = React.createContext({});

const SelectAccount = React.lazy(() => import(`../google-ads/Step/SelectAccount`));
const NameAndDescription = React.lazy(() => import(`../google-ads/Step/NameAndDescription.js`));
const ConnectionSettings = React.lazy(() => import(`../google-ads/Step/ConnectionSettings.js`));

const DEFAULT_ACCOUNT_CONNECTED_DATA = {
     email: null,
     dataVail: '',
     name: null,
     description: '',
     status: EXTERNAL_DATA_CONNECTION_STATUS.active,
     numberCID: '',
};

const ManageConnectAccount = ({ id }) => {
     // const dispatch = useDispatch();
     // const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     // const accountId = activeAccount.id;

     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState(cloneDeep(DEFAULT_ACCOUNT_CONNECTED_DATA));
     const [initStepsData, setInitStepsData] = useState(cloneDeep(DEFAULT_ACCOUNT_CONNECTED_DATA));
     const customerIdsResponse = useSelector((state) =>
          state.subscriber.accountExternalData ? state.subscriber.accountExternalData.customerIdsResponse : null
     );
     const listAccountConnected = useSelector((state) =>
          state.subscriber.accountExternalData ? state.subscriber.accountExternalData.googleAdsAccounts : []
     );

     // const fetchAccountConnectedDetail = () => {
     //      if (!id) return;

     //      setIsLoading(true);
     //      callTokenApi(API_CLIENT_ACCOUNT_CONNECTED_DETAIL.replace(':accountId', accountId).replace(':id', id), 'GET')
     //           .then((response) => {
     //                if (response.status === 200) {
     //                     const { accountConnected } = response.data;
     //                     setStepsData(accountConnected);
     //                     setInitStepsData(accountConnected);
     //                } else {
     //                     toastError(response);
     //                }
     //           })
     //           .finally(() => {
     //                setIsLoading(false);
     //           });
     // };
     // useEffect(fetchAccountConnectedDetail, [])

     useEffect(() => {
          if (id) {
               const newData = listAccountConnected.find((item) => item.id === id);
               setStepsData(newData);
               setInitStepsData(newData);
               setIsLoading(false);
          } else {
               const newData = { ...DEFAULT_ACCOUNT_CONNECTED_DATA };
               if (customerIdsResponse) {
                    const { email, user_name: name } = customerIdsResponse;
                    newData.email = email;
                    newData.name = name;
               }
               setStepsData(newData);
               setInitStepsData(newData);
          }
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
     
     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail account-connected-detail">
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new">
                              <h1>{id ? `Edit: Google Ads Connection: ${stepsData.name}` : `Create: Google Ads Connection`}</h1>
                              <p>Configure a connection to Google Ads by completing the steps below. </p>
                              <ExternalDetailDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                   }}
                              >
                                   <SelectAccount />
                                   <NameAndDescription />
                                   <ConnectionSettings />
                              </ExternalDetailDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default ManageConnectAccount;
