import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { COMPONENT_NAME } from '../../../constants';
import DestinationsLayout from '../../../components/cms/subscriber/destinations/DestinationsLayout';
import Listeners from '../../../components/cms/subscriber/listeners/Listeners';
import DataActionRule from '../../../components/cms/subscriber/destination-settings/dataActions/DataActionRule';
import MonitoringRule from '../../../components/cms/subscriber/listener-settings-v2/monitoring/MonitoringRule';
import BrowserStorageRule from '../../../components/cms/subscriber/listener-settings-v2/custom-browser-storage-variables/BrowserStorageRule';
import CustomDataLayerRule from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/CustomDataLayerRule';
import ConsentRule from '../../../components/cms/subscriber/consent-privacy/consent-rule';
import PopulateField from '../../../components/cms/subscriber/listener-settings-v2/populate-fields/PopulateField';
import EditLookupRule from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/EditLookupSetting';
import EditLookupTable from '../../../components/cms/subscriber/listener-settings-v2/custom-data-layer-variables/EditLookupTable';
import EngagementRule from '../../../components/cms/subscriber/listener-settings-v2/engagement-settings/EngagementRule';
import TriggerRule from '../../../components/cms/subscriber/trigger-conversions/triggers/TriggerRule';
import TriggerLookupTable from '../../../components/cms/subscriber/trigger-conversions/triggers/EditLookupTable';
import TriggerLookupSetting from '../../../components/cms/subscriber/trigger-conversions/triggers/EditLookupSetting';
import ConversionRule from '../../../components/cms/subscriber/trigger-conversions/conversions/ConversionRule';
import ClickRule from '../../../components/cms/subscriber/listener-settings-v2/click-settings/ClickRule';
import EditClickLookupTable from '../../../components/cms/subscriber/listener-settings-v2/click-settings/lookup/EditLookupTable';
import UserSourceRule from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/UserSourceRule';
import EditUserSourceSetting from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/EditLookupCustom';
import EditUserSourceTable from '../../../components/cms/subscriber/listener-settings-v2/user-source-rules/EditLookupCustomTable';
import ConversionLookupTable from '../../../components/cms/subscriber/trigger-conversions/conversions/EditLookupTable';
import ConversionLookupSetting from '../../../components/cms/subscriber/trigger-conversions/conversions/EditLookupSetting';
import AllRowFormRequiringAction from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/ViewAllFormRequiringAction';
import AllRowFormInventory from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/ViewAllFormInventory';
import FormRuleDetail from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/FormRuleDetail';
import EditFormRuleSetting from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/form-rule-edit/EditFormRuleSetting';
import EditFormRuleTable from '../../../components/cms/subscriber/listener-settings-v2/custom-form-categories/form-categories-manage/form-rule-edit/EditFormRuleTable';
import CustomReports from '../../../components/cms/subscriber/analytics/custom-report/CustomReports';
import ManageGoal from '../../../components/cms/subscriber/goals/manage-goal/ManageGoal';
import DimensionDetail from '../../../components/cms/subscriber/analytics/data-settings/metrics-and-dimensions/dimensions/DimensionDetail';
import ManageExternalData from '../../../components/cms/subscriber/sources/external-data/ManageExternalData';
import ManageConnectAccount from '../../../components/cms/subscriber/sources/external-data/external-data-child/ManageConnectAccount';
import ObjectImportDetail from '../../../components/cms/subscriber/sources/external-data/salesforce/object-imports/ObjectImportDetail';
import ChannelDetail from '../../../components/cms/subscriber/analytics/data-settings/channels/channels-details/ChannelDetail';
import SalesforceConnectAccount from '../../../components/cms/subscriber/sources/external-data/salesforce/ConnectedAccount/Popup/SalesforceConnectAccount';
import SegmentDetail from '../../../components/cms/subscriber/analytics/data-settings/manager-segment/SegmentDetail';
import SeverSideTrigger from '../../../components/cms/subscriber/trigger-conversions/triggers/EditSeverSideTrigger';
import SaleConversionDetail from '../../../components/cms/subscriber/goals/sales-conversion/SaleConversionDetail';

const ContentChild = () => {
     const { component, ruleId, reportId, token, currentRule } = useSelector((state) => state.theme.flexibleModal);

     const getComponentFlexibleModal = useCallback(() => {
          switch (component) {
               case COMPONENT_NAME.LISTENER_LIBRARY:
                    return <Listeners />;
               case COMPONENT_NAME.DESTINATION_LIBRARY:
                    return <DestinationsLayout />;
               case COMPONENT_NAME.DATA_ACTION:
                    return <DataActionRule id={ruleId} currentRule={currentRule} />;
               case COMPONENT_NAME.MONITORING_RULE:
                    return <MonitoringRule id={ruleId} />;
               case COMPONENT_NAME.BROWSER_STORAGE_RULE:
                    return <BrowserStorageRule id={ruleId} />;
               case COMPONENT_NAME.CUSTOM_DATA_LAYER_RULE:
                    return <CustomDataLayerRule id={ruleId} />;
               case COMPONENT_NAME.CUSTOM_DATA_LOOKUP_SETTING:
                    return <EditLookupRule id={ruleId} />;
               case COMPONENT_NAME.CUSTOM_DATA_LOOKUP_TABLE:
                    return <EditLookupTable id={ruleId} />;
               case COMPONENT_NAME.CONSENT_RULES:
                    return <ConsentRule id={ruleId} />;
               case COMPONENT_NAME.POPULATE_FIELDS_RULE:
                    return <PopulateField id={ruleId} />;
               case COMPONENT_NAME.SESSION_ENGAGEMENT_RULE:
                    return <EngagementRule id={ruleId} />;
               case COMPONENT_NAME.TRIGGER_RULE:
                    return <TriggerRule id={ruleId} />;
               case COMPONENT_NAME.TRIGGER_LOOKUP_TABLE:
                    return <TriggerLookupTable id={ruleId} />;
               case COMPONENT_NAME.TRIGGER_LOOKUP_SETTING:
                    return <TriggerLookupSetting id={ruleId} />;
               case COMPONENT_NAME.CONVERSION_LOOKUP_TABLE:
                    return <ConversionLookupTable id={ruleId} />;
               case COMPONENT_NAME.CONVERSION_LOOKUP_SETTING:
                    return <ConversionLookupSetting id={ruleId} />;
               case COMPONENT_NAME.CONVERSION_RULE:
                    return <ConversionRule id={ruleId} />;
               case COMPONENT_NAME.CLICK_SETTING_RULE:
                    return <ClickRule id={ruleId} />;
               case COMPONENT_NAME.CLICK_LOOKUP_TABLE:
                    return <EditClickLookupTable id={ruleId} />;
               case COMPONENT_NAME.USER_SOURCE_RULE:
                    return <UserSourceRule id={ruleId} />;
               case COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_SETTING:
                    return <EditUserSourceSetting id={ruleId} />;
               case COMPONENT_NAME.USER_SOURCE_RULE_CUSTOM_TABLE:
                    return <EditUserSourceTable id={ruleId} />;
               case COMPONENT_NAME.FORM_INVENTORY:
                    return <AllRowFormInventory />;
               case COMPONENT_NAME.FORM_REQUIRING_ACTION:
                    return <AllRowFormRequiringAction />;
               case COMPONENT_NAME.FORM_RULE:
                    return <FormRuleDetail id={ruleId} />;
               case COMPONENT_NAME.FORM_RULE_SETTING:
                    return <EditFormRuleSetting id={ruleId} />;
               case COMPONENT_NAME.FORM_RULE_TABLE:
                    return <EditFormRuleTable id={ruleId} />;
               case COMPONENT_NAME.CUSTOM_REPORTS:
                    return <CustomReports id={reportId} />;
               case COMPONENT_NAME.GOAL_MANAGE_TYPES:
                    return <ManageGoal />;
               case COMPONENT_NAME.DIMENSION_DETAIL:
                    return <DimensionDetail id={reportId} />;
               case COMPONENT_NAME.EXTERNAL_DATA:
                    return <ManageExternalData />;
               case COMPONENT_NAME.EXTERNAL_DATA_CONNECTED_ACCOUNTS:
                    return <ManageConnectAccount id={ruleId} />;
               case COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS:
                    return <SalesforceConnectAccount id={ruleId} token={token} />;
               case COMPONENT_NAME.EXTERNAL_DATA_OBJECT_IMPORTS:
                    return <ObjectImportDetail id={ruleId} />;
               case COMPONENT_NAME.CHANNELS:
                    return <ChannelDetail id={ruleId} />;
               case COMPONENT_NAME.SEGMENT_DETAIL:
                    return <SegmentDetail id={reportId} />;
               case COMPONENT_NAME.SEVER_SIDE_TRIGGER:
                    return <SeverSideTrigger id={ruleId} />;
               case COMPONENT_NAME.SALES_CONVERSION:
                    return <SaleConversionDetail id={reportId} />;
               default:
                    return <></>;
          }
     }, [component, ruleId, reportId]); // eslint-disable-line react-hooks/exhaustive-deps

     return <>{getComponentFlexibleModal()}</>;
};
export default ContentChild;
