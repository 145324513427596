import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_TEMP_DOMAINS, TARGET_ACCOUNT_GROUP_TYPE } from '../../constants';
import { COMPONENT_NAME } from '../../../../../../../constants';
import BaseTags from '../../../../../../general/BaseTags';
import { setFlexibleMultiModal } from '../../../../../../../actions/common';
import { FullListTableContext } from '.';
import { setSubscriberState } from '../../../../../../../actions/admin';
import { CInputCheckbox } from '../../../../../../migration/CInput';
import CTooltip from '../../../../../../migration/CTooltip';

const TableBodyRows = () => {
     const dispatch = useDispatch();
     const {domainOptions, handleRemoveRow, groupId, isIncludeAllBO, setIsIncludeAllBO } = useContext(FullListTableContext);
     const targetAccountGroups = useSelector((state) => state.subscriber.targetAccountGroups) || [];
     const targetAccountsList = useSelector((state) => state.subscriber.targetAccountsList);

     const activeGroup = targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.id === groupId) : null;
     const isEditableGroups = !activeGroup || (!activeGroup.isDefault && activeGroup.type !== TARGET_ACCOUNT_GROUP_TYPE.DYNAMIC_LIST);
     const BOGroup = targetAccountGroups.length > 0 ? targetAccountGroups.find((group) => group.isDefault) : null;
     const isBOGroup = BOGroup && groupId === BOGroup.id;
     // const isDisabledBO = BOGroup && !BOGroup.status;

     const changeBOCheckbox = (row) => {
          const newTableRows = [...targetAccountsList];
          const rowIndex = newTableRows.findIndex((item) => item.id === row.id);

          const actionType = newTableRows[rowIndex].actionType;
          const newIsBO = !newTableRows[rowIndex].isBO;
          const originIsBO = newTableRows[rowIndex].originIsBO;

          const correctActionType = actionType === 'add' ? 'add' : newIsBO !== originIsBO ? 'edit' : null;

          if (!newIsBO && isIncludeAllBO && groupId) {
               setIsIncludeAllBO(newIsBO)
          }

          newTableRows[rowIndex] = {
               ...newTableRows[rowIndex],
               isBO: newIsBO,
               actionType: correctActionType,
          };

          const finalTargetAccounts = newTableRows.length ? newTableRows : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));
     };

     const onClickShowDetailCompany = (targetAccount) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: targetAccount.id, companyId: targetAccount.companyId, targetAccount },
                    component: COMPONENT_NAME.RECORD_PROFILE_TARGET_ACCOUNT,
               })
          );
     };

     if (!isEditableGroups && targetAccountsList.some((item) => item.isTemp)) {
          return (
               <tr className="data-row">
                    <td className="no-results">No domains found!</td>
               </tr>
          );
     }

     // const textNone = (row) => row.companyId && !row.targetAccountId && 'None';
     
     const textValue = (row) => !row.domain || (row.isProcessing ? <i>processing</i>: (!row.companyData || Object.keys(row.companyData).length === 0 ? '' : 'None'));
     return (
          <>
               {targetAccountsList.map((row, rowIndex) => {
                    const companyData = row.companyData || {};
                    const selectedValue = domainOptions.find((option) => option.domain === row.domain);
                    const inputAddValue = row.domain ? { label: row.domain, value: row.domain } : null;
                    const correctValue = selectedValue || inputAddValue;
                    const hasGroups = row.groupName && row.groupName.length > 0;
                    const hasServices = companyData.industries && companyData.industries.length > 0 && !companyData.industries.some((tag) => tag.toLowerCase() === 'none');
        
                    const isOnlyOneRow = targetAccountsList.length === 1 && !row.domain;
                    const isDisableCheckBO = !isEditableGroups;
                    const correctGroupNames = isBOGroup && row.groupName ? row.groupName.filter((name) => name !== BOGroup.name) : row.groupName;

                    return (
                         <tr key={row.id} className="data-row">
                              <td id={`row-${row.id}`} className={`value-domain ${row.isTemp ? 'is-temp' : ''}`}>
                                   {row.isTemp ? (
                                        <div className={`value-input-wrap ${!correctValue ? 'not-selected' : ''}`}>
                                             {/* <TableSelectSearch
                                                  id="domain"
                                                  placeholder="Select Domain"
                                                  isLoading={isLoadingDomains}
                                                  onChange={(selected) => {
                                                       handleChangeRowData(selected, row, rowIndex);
                                                  }}
                                                  handleInputChange={(value, event) => handleInputChange(value, event, rowIndex)}
                                                  options={correctDomainOptions}
                                                  noOptionsMessage={() => noOptionsMessage(rowIndex)}
                                                  value={correctValue}
                                                  customInputValue={searchValue}
                                                  className="select-react"
                                                  classNamePrefix="custom-select"
                                             /> */}
                                             {/* <TableSelectSearchV2
                                                  id="domain"
                                                  placeholder="Select Domain"
                                                  options={defaultDomainOptions}
                                                  value={correctValue}
                                                  onChange={(selected) => {
                                                       handleChangeRowData(selected, row, rowIndex);
                                                  }}
                                                  onInputChange={(event) => handleInputChange(event)}
                                                  noOptionsMessage={() => noOptionsMessage(rowIndex)}
                                                  dataVariable={false}
                                                  selectInputType={false}
                                                  isClearable={true}
                                                  currentDomain={currentDomain}
                                             /> */}
                                        </div>
                                   ) : (
                                        row.domain
                                   )}

                                   {isEditableGroups && !isOnlyOneRow && (
                                        <span className="btn-remove-row">
                                             <CTooltip content="Remove row" placement="bottom">
                                                  <i
                                                       className="fal fa-times text-danger"
                                                       onClick={() =>  handleRemoveRow(row, row.isTemp)}
                                                  ></i>
                                             </CTooltip>
                                        </span>
                                   )}
                              </td>

                              <td className="value-name">{companyData.companyName || (!row.domain ? '' : (!row.isProcessing ? (<i>No match found</i>) : (<i>processing</i>)))}</td>

                              {row.id && row.isSyncBigQuery ? (
                                   <td
                                        className={`value-profile`}
                                        tabIndex={0}
                                        data-keyboardnavid={row.id}
                                        onClick={() => onClickShowDetailCompany(row)}
                                   >
                                        <span>View</span>
                                   </td>
                              ) : (
                                   <td></td>
                              )}

                              <td className={`value-bo ${!!row.isBO ? 'checked' : ''} ${isDisableCheckBO ? 'disabled' : ''}`}>
                                   {row.domain && (
                                        <>
                                             <CInputCheckbox
                                                  id={`inline-checkbox${rowIndex}`}
                                                  value={row.id}
                                                  checked={!!row.isBO}
                                                  onChange={() => changeBOCheckbox(row)}
                                                  disabled={isDisableCheckBO}
                                             />

                                             <label htmlFor={`inline-checkbox${rowIndex}`}></label>
                                        </>
                                   )}
                              </td>

                              {hasGroups ? (
                                   <td className="value-groups">{<BaseTags items={correctGroupNames} />}</td>
                              ) : (
                                   <td className="value-groups none">{row.id && row.id.length > 5 && 'None'}</td>
                              )}

                              <td className="value-revenue">{companyData.revenue || textValue(row)}</td>
                              <td className="value-total-employee">{companyData.employees || textValue(row)}</td>
                              <td className="value-primary-industry">{companyData.primaryIndustry || textValue(row)}</td>

                              {hasServices ? (
                                   <td className="value-service-tag">{<BaseTags items={companyData.industries} />}</td>
                              ) : (
                                   <td className="value-service-tag none">{textValue(row)}</td>
                              )}

                              <td className="value-country">{((companyData.countryData && companyData.countryData.name) || companyData.country) || textValue(row)}</td>
                              <td className="value-country">{(companyData.region) || textValue(row)}</td>
                              <td className="value-business-type">{companyData.businessType || textValue(row)}</td>
                              <td className="value-monthly-visitors">{companyData.monthlyVisitors || textValue(row)}</td>
                              <td className="value-year-founded">{companyData.yearFounded || textValue(row)}</td>
                         </tr>
                    );
               })}
          </>
     );
};

export default TableBodyRows;
