import { TargetAccountAPI } from './apis';

export const fetchDomainsRequest = async (payload) => {
     const { accountId, searchText, setIsLoadingDomains, setDomainOptions, groupId, fetchDefault, setDefaultDomainOptions, setFilterDomainOptions } = payload;

     setIsLoadingDomains(true);
     const result = await TargetAccountAPI.findDomains({ accountId, domainSearch: searchText, groupId, fetchDefault });
     setIsLoadingDomains(false);

     if (!result || !Array.isArray(result) ) return;

     const resultRows = result || [];

     const resultDomains = resultRows.map((result) => {
          return {
               ...result,
               label: result.domain,
               value: result.domain,
          };
     });

     if (fetchDefault) {
          setDefaultDomainOptions(resultDomains);
          setFilterDomainOptions(resultDomains)
     }
     else setDomainOptions(resultDomains);

     return resultRows;
};

export const fetchTargetAccountsRequest = async (payload) => {
     const { accountId, pagination, setTargetAccountsCurrentPage, setIsTableFetching, groupId, setDataInPage, filterValue, isIncludeAllBO, searchObject } = payload;

     setIsTableFetching(true);
     const result = await TargetAccountAPI.getAll({ accountId, groupId, pagination, filterValue, searchObject });
     setIsTableFetching(false);

     if (!result) return;

     const resultRows = result || [];

     const newResultRows = resultRows.map((row) => {
          return {
               ...row,
               originIsBO: !!row.isBO,
               isBO: isIncludeAllBO ? isIncludeAllBO : row.isBO
          };
     });

     if (typeof setDataInPage === 'function') {
          setDataInPage(prev => {
               return {
                    ...prev,
                    [pagination.page]: newResultRows
               }
          })
     }

     setTargetAccountsCurrentPage(newResultRows);

     return resultRows;
};

export const fetchTargetAccountsExportRequest = async (payload) => {
     const { accountId, groupId, searchObject } = payload;

     const result = await TargetAccountAPI.getAllExport({ accountId, groupId, searchObject });

     if (!result) return;

     const resultRows = result || [];

     return resultRows;
};

export const updateTargetAccountsRequest = async (payload) => {
     const {
          accountId,
          targetAccountsList,
          setTargetAccountsCurrentPage,
          pagination,
          setPagination,
          setSaveTableModal,
          setSaveLoading,
          groupId,
          includeAllBO,
          dataInPage,
          setDataInPage,
          targetAccountGroups,
          setTargetAccountGroups,
          searchObject
     } = payload;

     const prevPage = []

     Object.entries(dataInPage).forEach(([key, value]) => {
          if (Number(key) !== Number(pagination.page)) {
               prevPage.push(...value)
          }
     })

     const targetAccountEdits = [...targetAccountsList, ...prevPage]
          .map((row) => {
               return {
                    id: row.actionType === 'edit' ? row.id : null,
                    companyId: row.companyId,
                    domain: row.domain || null,
                    isBO: !!row.isBO,
                    actionType: row.targetAccountId ? 'edit' : row.actionType || null,
                    companyData: row.companyData
               };
          })
          .filter((row) => row.domain && row.actionType);

     setSaveLoading(true);
     const result = await TargetAccountAPI.update({ accountId, targetAccountEdits, groupId, includeAllBO });
     if (!result) return;
     const newFetch = await TargetAccountAPI.getAll({ accountId, groupId, pagination: { ...pagination, page: 1 }, searchObject });
     setSaveLoading(false);

     const newFetchRows = newFetch || [];

     setTargetAccountsCurrentPage(newFetchRows);
     setPagination({ ...pagination, page: 1 });
     setSaveTableModal(false);
     setDataInPage({})

     if (groupId) {
          const newTargetAccountGroups = targetAccountGroups.map((item) => {
               if (item.id === groupId) {
                    return {
                         ...item,
                         includeAllBO
                    }
               }

               return item
          })

          setTargetAccountGroups(newTargetAccountGroups)
     }
     return result;
};

export const deleteTargetAccountRequest = async (payload) => {
     const { accountId, id, targetAccountsList, pagination, setTargetAccountsCurrentPage, setDeleteLoading, setRemoveRowModal, searchObject, setDataInPage } = payload;

     setDeleteLoading(true);
     const result = await TargetAccountAPI.delete({ id, accountId });

     if (!result) return;

     let newPagination = { ...pagination }

     if (targetAccountsList.length === 1 && pagination.page !== 1) {
          newPagination = { ...pagination, page: pagination.page - 1 }
     }

     const newFetch = await TargetAccountAPI.getAll({ accountId, pagination: newPagination, searchObject });
     const newFetchRows = newFetch || [];

     if (typeof setDataInPage === 'function') {
          setDataInPage(prev => {
               return {
                    ...prev,
                    [newPagination.page]: newFetchRows
               }
          })
     }

     const tempRows = targetAccountsList.filter((row) => row.isTemp);

     setTargetAccountsCurrentPage([...newFetchRows, ...tempRows]);
     setDeleteLoading(false);
     setRemoveRowModal({ show: false, id: null });

     return result;
};

export const deleteTargetAccountGroupRequest = async (payload) => {
     const { accountId, id, groupId, targetAccountsList, pagination, setTargetAccountsCurrentPage, setDeleteLoading, setRemoveRowModal, searchObject, setDataInPage } = payload;

     setDeleteLoading(true);
     const result = await TargetAccountAPI.deleteTargetAccountGroups({ targetAccountId: id, accountId, groupId });

     if (!result) return;

     let newPagination = { ...pagination }

     if (targetAccountsList.length === 1 && pagination.page !== 1) {
          newPagination = { ...pagination, page: pagination.page - 1 }
     }

     const newFetch = await TargetAccountAPI.getAll({ accountId, pagination: newPagination, groupId, searchObject });
     const newFetchRows = newFetch || [];

     if (typeof setDataInPage === 'function') {
          setDataInPage(prev => {
               return {
                    ...prev,
                    [newPagination.page]: newFetchRows
               }
          })
     }

     const tempRows = targetAccountsList.filter((row) => row.isTemp);

     setTargetAccountsCurrentPage([...newFetchRows, ...tempRows]);
     setDeleteLoading(false);
     setRemoveRowModal({ show: false, id: null });

     return result;
};
