import React, { useState, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { CSpinner } from '@coreui/react';
import XLSX from 'xlsx';

import { callTokenApi } from '../../../../../../apiCaller';
import { toastError, useOutsideHandling } from '../../../../../../utils';
import { API_CLIENT_GET_LIST_EXPORT_ALL_FORM, API_CLIENT_GET_LIST_EXPORT_FORM_NEEDING_CATEGORIZATION, API_CLIENT_GET_LIST_EXPORT_ALREADY_CATEGORIZED } from '../../../../../../constants';

import ImportTableModalForm from './ImportTableModalForm';

const ImportExportTableForm = ({ className, formsInventory }) => {
     const [showImportModal, setShowImportModal] = useState(false);
     const [showImportExport, setShowImportExport] = useState(false);
     const [isLoadingExports, setIsLoadingExports] = useState({
          exportAllForm: false,
          exportFormsNeedingCategorization: false,
          exportFormsAlreadyCategorized: false,
     });
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountId = activeAccount.id;

     const fetchListExportAllForm = useCallback(async () => {
          setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportAllForm: true }));
          return await callTokenApi(API_CLIENT_GET_LIST_EXPORT_ALL_FORM.replace(':accountId', accountId), 'GET')
               .then((response) => {
                    if (response.status !== 200) {
                         toastError(response);
                         return;
                    }

                    const { exportAllForm } = response.data;
                    if (exportAllForm?.length > 0) {
                         return exportAllForm;
                    }
               })
               .finally(() => {
                    setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportAllForm: false }));
               });
     }, [accountId]);

     const fetchListExportFormsNeedingCategorization = useCallback(async () => {
          setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportFormsNeedingCategorization: true }));
          return await callTokenApi(API_CLIENT_GET_LIST_EXPORT_FORM_NEEDING_CATEGORIZATION.replace(':accountId', accountId), 'GET')
               .then((response) => {
                    if (response.status !== 200) {
                         toastError(response);
                         return;
                    }

                    const { exportFormsNeedingCategorization } = response.data;
                    if (exportFormsNeedingCategorization?.length > 0) {
                         return exportFormsNeedingCategorization;
                    }
               })
               .finally(() => {
                    setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportFormsNeedingCategorization: false }));
               });
     }, [accountId]);

     const fetchListExportFormsAlreadyCategorized = useCallback(async () => {
          setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportFormsAlreadyCategorized: true }));
          return await callTokenApi(API_CLIENT_GET_LIST_EXPORT_ALREADY_CATEGORIZED.replace(':accountId', accountId), 'GET')
               .then((response) => {
                    if (response.status !== 200) {
                         toastError(response);
                         return;
                    }

                    const { exportFormsAlreadyCategorized } = response.data;
                    if (exportFormsAlreadyCategorized?.length > 0) {
                         return exportFormsAlreadyCategorized;
                    }
               })
               .finally(() => {
                    setIsLoadingExports((prevIsLoadingExports) => ({ ...prevIsLoadingExports, exportFormsAlreadyCategorized: false }));
               });
     }, [accountId]);

     const exportToFile = (type = 'csv', fileName = 'table-export-form', dataExport) => {
          const convertTo2DArray = (listData) => {
               const result = [['ID', 'Location', 'Category', 'Name']];

               listData.forEach((item) => {
                    result.push([item.formValue || '', item.formLocation || '', item.categoryName || '', item.name || '']);
               });

               return result;
          };

          const dataToExport = convertTo2DArray(dataExport);

          if (type === 'csv') {
               // https://reactjs.org/docs/code-splitting.html#import
               import('../../../../../../utils').then((utils) => {
                    // csv type will use this function to export correctly
                    utils.exportToCSV(`${fileName}.${type}`, dataToExport);
               });
          } else {
               var workSheet = XLSX.utils.aoa_to_sheet(dataToExport);

               // Add to workbook
               let workBook = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');

               // If uses XLSX.writeFile to export csv file, open that file then Ctrl + S to save,
               // then close file without saving changes, open that file again all values
               // of each rows will merge into the first column of that row, so use another function to export csv file
               XLSX.writeFile(workBook, `${fileName}.${type}`);
          }
     };

     const handClickImportExport = useCallback(() => {
          setTimeout(() => {
               setShowImportExport(!showImportExport);
          }, 100);
     }, [showImportExport]);

     const handleClickOutside = useCallback(() => {
          if (!showImportModal) {
               setShowImportExport(false);
          }
     }, [showImportModal]);

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, handleClickOutside);

     const handleExportAllForm = async () => {
          const _listDataExportAll = await fetchListExportAllForm();
          const dataExportForms = (_listDataExportAll || []).filter((item) => !item.hasChild);

          if (dataExportForms) {
               exportToFile('csv', 'table-export-all-form', dataExportForms);
          }
     };

     const handleExportFormsNeedingCategorization = async () => {
          const _listDataExportFormsNeedingCategorization = await fetchListExportFormsNeedingCategorization();
          const dataExportFormsNeedingCategorization = (_listDataExportFormsNeedingCategorization || []).filter((item) => !item.hasChild);

          if (dataExportFormsNeedingCategorization.length) {
               exportToFile('csv', 'table-export-forms-needing-categorization', dataExportFormsNeedingCategorization);
          }
     };

     const handleExportFormsAlreadyCategorized = async () => {
          const _listDataExportFormsAlreadyCategorized = await fetchListExportFormsAlreadyCategorized();
          const dataExportFormsAlreadyCategorized = (_listDataExportFormsAlreadyCategorized || []).filter((item) => !item.hasChild);

          if (dataExportFormsAlreadyCategorized.length) {
               exportToFile('csv', 'table-export-forms-already-categorized', dataExportFormsAlreadyCategorized);
          }
     };

     const handleImportFormCategory = async () => {
          toggleImportModal();
     };

     const toggleImportModal = useCallback(() => {
          setShowImportModal((prevShowImportModal) => !prevShowImportModal);
     }, []);

     return (
          <>
               <div className="import-export-wrapper">
                    <div className="import-export">
                         <CIcon icon="cil-chevron-double-down" height={14} />
                         <button onClick={handClickImportExport}>Import/Export</button>
                    </div>
                    <ul ref={wrapperRef} className={showImportExport ? '' : 'hidden'}>
                         <li>
                              <button color="primary" disabled={isLoadingExports.exportAllForm} onClick={handleExportAllForm}>
                                   {isLoadingExports.exportAllForm ? <CSpinner size="sm" /> : <CIcon icon="cil-cloud-download" height={14} />}
                                   <span>Export all forms</span>
                              </button>
                         </li>
                         <li>
                              <button color="primary" disabled={isLoadingExports.exportFormsNeedingCategorization} onClick={handleExportFormsNeedingCategorization}>
                                   {isLoadingExports.exportFormsNeedingCategorization ? <CSpinner size="sm" /> : <CIcon icon="cil-cloud-download" height={14} />}
                                   <span>Export forms needing categorization</span>
                              </button>
                         </li>
                         <li>
                              <button color="primary" disabled={isLoadingExports.exportFormsAlreadyCategorized} onClick={handleExportFormsAlreadyCategorized}>
                                   {isLoadingExports.exportFormsAlreadyCategorized ? (
                                        <CSpinner size="sm" />
                                   ) : (
                                        <CIcon icon="cil-cloud-download" height={14} />
                                   )}
                                   <span>Export forms already categorized</span>
                              </button>
                         </li>
                         <li>
                              <button color="primary" onClick={handleImportFormCategory}>
                                   <CIcon icon="cil-cloud-upload" height={14} />
                                   <span>Import new table values</span>
                              </button>
                         </li>
                    </ul>
               </div>
               {showImportModal && (
                    <ImportTableModalForm
                         className={className}
                         formsInventory={formsInventory}
                         toggleModal={toggleImportModal}
                         exportToFile={exportToFile}
                    />
               )}
          </>
     );
};

export default ImportExportTableForm;
