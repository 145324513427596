import Pusher from 'pusher-js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
     addNewTicketComment,
     addRule,
     approvePartner,
     changeAccountEvents,
     changeAccountName,
     changeAccountTimestamps,
     changeAccountVariables,
     changeChildFeatureStatus,
     changeEventChart,
     changeFeatureStatus,
     changeListenerStatus,
     changeNumberOfSessionsChart,
     changeNumberOfVisitorChart,
     changeVisitorsAndSessionsPerMonth,
     consentRuleChanged,
     crawlFormsAction,
     createInvoices,
     createNewUserManager,
     updateSegment,
     increaseNumberOfEvents,
     increaseNumberOfFakeVisitorsBy1,
     increaseNumberOfHistories,
     inviteManagerUser,
     invoiceFinalization,
     nativeBrowserStorageChange,
     reloadCustomFormRules,
     reloadElementClickRules,
     reloadOfficeHourCalendar,
     removeManagerUser,
     removeRule,
     reorderRule,
     resetCmtNumberOfClient,
     resetFeatureSupport,
     resetNumberOfEvents,
     saveCardToChange,
     saveCreditCard,
     setAccountCompletedSteps,
     setListRuleConditions,
     setNumberOfHistories,
     setPublishedVersion,
     updateAccount,
     updateAvailableHours,
     updateDaysLength,
     updateEventTable,
     updateFeatureSupportChildChange,
     updateFeatureSupportVideoProgresses,
     updateInvoice,
     updateInvoiceInformation,
     updateListDatalayerVariables,
     updateListEvents,
     updateListenerForRoutes,
     updateNotification,
     updatePersonalData,
     updatePreciseLocationConfig,
     updateRule,
     updateRuleStatus,
     updateSelectorValues,
     // updateVariableValues,
     updateSelectorVariables,
     updateUserAccount,
     updateUserManagerInfo,
     upgradeOrDowngradeAccount,
} from '../../actions/pusher';
import {
     fetchAccountUsers,
     fetchVersion,
     handleSetCurrentStepConversion,
     revertPublishData,
     setActiveAccount,
     setBrowserVariableOptions,
     setBrowserVariables,
     setCustomVariableOptions,
     setCustomVariables,
     setDomains,
     setShowPopupConfigConversion,
     setUsedItems,
     setVersionPublish,
     setWeatherTemperature,
     setReloadReportData,
     setSavedReport,
     resetFormCategoryPusher,
     handleUpdateTargetPusher,
     setLoadings,
     processStatusWizard,
     setSubscriberState
} from '../../actions/subscriber';
import { BQ_JOB_ACTION, STEPS_CONFIG_CONVERSION, STEPS_WIZARD_CATEGORIES, SUBSCRIBER_PATH } from '../../constants';
import { signout } from '../../helpers/auth';
import { getSupportRedirectChannelId } from '../../helpers/cms/support';

const ThePusher = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const { currentStepFormCategories } = activeAccount;
     const user = useSelector((state) => state.theme.user);

     const handlePusher = () => {
          if (activeAccount.id) {
               // process.env.REACT_APP_PUSHER_KEY
               const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
               });
               const channel = pusher.subscribe(`channel-${activeAccount.id}`);

               channel.bind('updateTargetCustom', function (data) {
                    if (data) {
                         dispatch(handleUpdateTargetPusher(data));
                    }
                    console.log('update old data success');
               });

               channel.bind('crawlFormsDone', function (data) {
                    if (!currentStepFormCategories || currentStepFormCategories === STEPS_WIZARD_CATEGORIES.defineYourFormCategories) {
                         dispatch(resetFormCategoryPusher());
                    }
                    dispatch(crawlFormsAction(data));
               });

               // Called when inviting an existed user
               channel.bind('userAdd', function (data) {
                    data.currentUserId = user.id;
                    dispatch(inviteManagerUser(data));
               });

               channel.bind('reportRequestStatus', function (data) {
                    if (data.action === BQ_JOB_ACTION.CREATE_SEGMENT) {
                         dispatch(updateSegment(data));
                    } else if (data.isChart) {
                         dispatch(setReloadReportData({ ...data, refreshRequestChart: Date.now() }));
                    } else {
                         dispatch(setReloadReportData({ ...data, refreshRequest: Date.now() }));
                    }
               });

               // Called when inviting an unexisted user and then create a new manager user
               channel.bind('inviteUnexistedUserManager', function (data) {
                    if (user.id !== data.currentUserId) {
                         dispatch(inviteManagerUser(data));
                    }
               });

               // Called when removing a manager user
               channel.bind('userRemove', function (data) {
                    if (user.id !== data.userId) {
                         if (user.email === data.email) {
                              signout();
                         } else {
                              dispatch(removeManagerUser(data));
                         }
                    }
               });

               // Called when unexisted user who was invited to manage an account has accepted the invitation and register
               channel.bind('createNewUserManager', function (data) {
                    if (data.user) {
                         dispatch(createNewUserManager(data.user));
                    }
               });

               // Called when changing domains
               channel.bind('domainChange', function (data) {
                    if (user.id !== data.userId) {
                         const { domains, isComplete, completedSteps } = data;
                         let initialDomains = domains.map((item, index) => {
                              return {
                                   id: `savedDomain${index + 1}`,
                                   name: item.name,
                                   scriptInstalled: item.scriptInstalled,
                                   tempScriptInstalled: item.scriptInstalled,
                              };
                         });
                         let listDisabled = domains.map(() => true);
                         const usedItems = {
                              itemName: 'numberOfDomains',
                              quantity: domains.length,
                         };

                         if (domains.length > 0) {
                              if(initialDomains.some(i => i.scriptInstalled === true)) {
                                   const payload = {
                                        accoundId: activeAccount.id,
                                        steps: 'installAndLearn',
                                        data: {
                                             installListenlayer: true
                                         }
                                   }
                                   dispatch(processStatusWizard(payload))
                              }
                              dispatch(
                                   setDomains({
                                        initialDomains,
                                        editedDomains: [],
                                        addedDomains: [],
                                        listDisabled: [...listDisabled],
                                        numOfDomains: domains.length,
                                   })
                              );
                         } else {
                              dispatch(
                                   setDomains({
                                        initialDomains: [{ id: 'newDomain1', name: '' }],
                                        editedDomains: [],
                                        addedDomains: [{ id: 'newDomain1', name: '' }],
                                        listDisabled: [false],
                                        numOfDomains: 1,
                                   })
                              );
                         }
                         dispatch(setUsedItems(usedItems));

                         // isComplete => account complete step signup flow
                         if (isComplete) {
                              dispatch(setAccountCompletedSteps(completedSteps));
                         }
                    }
               });

               channel.bind('incrementNotification', function () {
                    dispatch(increaseNumberOfHistories());
               });

               channel.bind('addVersionNotification', function () {
                    dispatch(fetchVersion(activeAccount.id, false));
               });

               // Called when enabling/disabling a listener
               channel.bind('listenerChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(changeListenerStatus(data));
                    }
               });

               // Called when enabling/disabling a feature
               channel.bind('featureChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(changeFeatureStatus(data));
                    }
               });
               channel.bind('childFeatureChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(changeChildFeatureStatus(data));
                    }
               });

               channel.bind('changeNumberOfEvent', function (_) {
                    dispatch(increaseNumberOfEvents());
               });

               channel.bind('changeEvent', function (data) {
                    dispatch(changeEventChart(data));
               });

               channel.bind('changeNumberOfVisitorsChart', function (data) {
                    dispatch(changeNumberOfVisitorChart(data));
               });

               channel.bind('changeNumberOfSessionsChart', function (data) {
                    dispatch(changeNumberOfSessionsChart(data));
               });

               channel.bind('resetNumberOfEvent', function (_) {
                    dispatch(resetNumberOfEvents());
               });

               channel.bind('customVariableChange', function (data) {
                    let usedItems = {};
                    let initialVariables = [];
                    let addedVariables = [];
                    switch (data.type) {
                         case 'rule':
                              if (data.cusDatalayerVariables.length > 0) {
                                   initialVariables = data.cusDatalayerVariables.map((item) => {
                                        return { id: item.id, name: item.name };
                                   });
                              } else {
                                   initialVariables = [{ id: 'variable1', name: '' }];
                                   addedVariables = [{ id: 'variable1', name: '' }];
                              }
                              usedItems = {
                                   itemName: 'numberOfCusDatalayerVariables',
                                   quantity: data.count,
                              };
                              dispatch(
                                   setCustomVariables({
                                        initialVariables,
                                        editedVariables: [],
                                        addedVariables,
                                        listDisabled: data.cusDatalayerVariables.map(() => true),
                                        numOfVariables: initialVariables.length,
                                   })
                              );
                              dispatch(setUsedItems(usedItems));
                              dispatch(setCustomVariableOptions(data.cusDatalayerVariables));

                              break;
                         case 'browser':
                              if (data.cusDatalayerVariables.length > 0) {
                                   initialVariables = data.cusDatalayerVariables.map((item) => {
                                        return { id: item.id, name: item.name, type: item.type };
                                   });
                              } else {
                                   initialVariables = [{ id: 'variable1', name: '', type: '' }];
                                   addedVariables = [{ id: 'variable1', name: '', type: '' }];
                              }
                              usedItems = {
                                   itemName: 'numberOfCusBrowserVariables',
                                   quantity: data.count,
                              };
                              dispatch(
                                   setBrowserVariables({
                                        initialVariables,
                                        editedVariables: [],
                                        addedVariables,
                                        listDisabled: data.cusDatalayerVariables.map(() => true),
                                        numOfVariables: initialVariables.length,
                                   })
                              );
                              dispatch(setUsedItems(usedItems));
                              dispatch(setBrowserVariableOptions(data.cusDatalayerVariables));

                              break;
                         default:
                    }
               });

               // Called when creating a new rule
               channel.bind('ruleAdd', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(addRule(data));
                    }
               });

               // Called when updating a rule
               channel.bind('ruleUpdate', function (data) {
                    // If many users are editing the same rule, a user saves rule first and other users page will refresh
                    if (user.id !== data.userId) {
                         const { rule } = data;
                         let rulePathName = `custom-variable-rules/${rule.id}`;

                         if (rule.group === 'userSource') {
                              rulePathName = `user-source-rules/${rule.id}`;
                         }

                         if (rule.group === 'click') {
                              rulePathName = `${SUBSCRIBER_PATH.CLICK_SETTINGS}/${rule.id}`;
                         }

                         if (window.location.pathname.includes(rulePathName)) {
                              dispatch(updateRule(data));
                         }
                    }
               });

               // Called when updating a rule
               channel.bind('ruleUpdateStatus', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateRuleStatus(data));
                    }
               });

               // Called when removing a rule
               channel.bind('ruleRemove', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(removeRule(data));
                    }
               });

               // Called when reorder rules
               channel.bind('ruleReorder', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(reorderRule(data));
                    }
               });

               // Called when publishing a version
               channel.bind('versionPublished', function (version) {
                    if (!window._noPublishToast) {
                         toast.success('Your Version is published!');
                    } else {
                         window._noPublishToast = undefined;
                    }

                    dispatch(setNumberOfHistories(0));
                    dispatch(
                         setVersionPublish({
                              isPublish: 'disable',
                         })
                    );
                    dispatch(setPublishedVersion(version));
               });

               channel.bind('versionPublishFailed', function () {
                    toast.error('Failed to publish Your Version!');
                    dispatch(revertPublishData());
               });

               // Called when upgrading/downgrading account
               channel.bind('accountUpOrDowngrade', function (data) {
                    if (window._blockUpgradeDowngradePusher) {
                         window._blockUpgradeDowngradePusher = undefined;
                         return;
                    }

                    if (user.id !== data.userId && activeAccount.id === data.account.id && activeAccount.packageId !== data.account.packageIdx) {
                         dispatch(upgradeOrDowngradeAccount(data));
                    }
               });

               // Called when changing account name
               channel.bind('accountNameChange', function (data) {
                    if (user.id !== data.userId && data.account.id === activeAccount.id) {
                         dispatch(changeAccountName(data.account));
                    }
               });

               // Called when changing account Timestamps
               channel.bind('accountTimestampsChange', function (data) {
                    if (user.id !== data.userId && data.account.id === activeAccount.id) {
                         dispatch(changeAccountTimestamps(data.account));
                    }
               });

               // Called when changing events of a listener
               channel.bind('eventAccountChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(changeAccountEvents(data));
                    }
               });

               // Called when changing automatic data layer variables of a listener
               channel.bind('autoDataLayerChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(changeAccountVariables(data));
                    }
               });

               // Called when colleting automatic datalayer variables
               channel.bind('autoDataLayerCollect', function (data) {
                    dispatch(changeAccountVariables(data));
               });

               // Called when saving invoice information
               channel.bind('saveInvoiceInfomation', function (data) {
                    if (user.id !== data.userId && typeof data.account === 'object') {
                         dispatch(updateInvoiceInformation(data.account));
                    }
               });

               // Called when saving a credit card
               channel.bind('saveCreditCard', function (data) {
                    if (user.id !== data.userId && data.saveCardStatus && typeof data.saveCardStatus.account === 'object') {
                         dispatch(saveCreditCard(data.saveCardStatus.account));
                    }
               });

               channel.bind('createSavedReport', function (data) {
                    dispatch(setSavedReport(data));
               });

               channel.bind('updateSavedReport', function (data) {
                    dispatch(setSavedReport(data));
               });

               channel.bind('deleteSavedReport', function (data) {
                    dispatch(setSavedReport(data));
               });

               // Called when upgrading/downgrading + saving card
               channel.bind('saveCardToChange', function (data) {
                    if (user.id !== data.userId && data.response && data.response.account.id === activeAccount.id) {
                         const {
                              response: { account },
                              package: packageData,
                         } = data;
                         dispatch(saveCardToChange({ account, packageData }));
                    }
               });

               channel.bind('invoiceFinalization', function () {
                    dispatch(invoiceFinalization());
               });

               channel.bind('createInvoices', function (data) {
                    const { invoices } = data;
                    dispatch(createInvoices({ invoices }));
               });

               channel.bind('updateInvoice', function (data) {
                    const { invoice } = data;
                    dispatch(updateInvoice({ invoice }));
               });

               channel.bind('updateAccount', function (data) {
                    const { account } = data;
                    dispatch(updateAccount({ account }));
               });

               channel.bind('featureSupportChildChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateFeatureSupportChildChange(data));
                    }
               });

               channel.bind('featureSupportVideoProgressesChanging', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateFeatureSupportVideoProgresses(data));
                    }
               });

               channel.bind('resetFeatureSupport', function (data) {
                    dispatch(resetFeatureSupport(data));
               });

               channel.bind('updateRuleConditions', function (data) {
                    dispatch(setListRuleConditions(data));
               });

               channel.bind('updateListEvents', function (data) {
                    dispatch(updateListEvents(data));
               });

               channel.bind('updateListDatalayerVariables', function (data) {
                    dispatch(updateListDatalayerVariables(data));
               });

               channel.bind('updateUserManagerInfo', function (data) {
                    dispatch(updateUserManagerInfo(data));
               });

               channel.bind('updateEventTable', function (data) {
                    dispatch(updateEventTable(data));
               });

               // channel.bind('updateVariableValues', function (data) {
               //      dispatch(updateVariableValues(data));
               // })

               channel.bind('selectorVariableCollect', function (data) {
                    dispatch(updateSelectorVariables(data));
               });

               channel.bind('selectorVariablesChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateSelectorVariables(data));
                    }
               });

               channel.bind('selectorValuesChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateSelectorValues(data));
                    }
               });

               channel.bind('nativeBrowserStorageChange', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(nativeBrowserStorageChange(data));
                    }
               });

               channel.bind('updateAccountConsoleStatus', function (data) {
                    if (user.id !== data.userId && data.accountId === activeAccount.id) {
                         dispatch(setActiveAccount({ ...activeAccount, consoleStatus: data.status }));
                    }
               });

               channel.bind('sendNotificationNewComment', function (data) {
                    toast.success('Your have 1 comment from Customer Support!');
                    data.currentUserId = user.id;
                    dispatch(addNewTicketComment(data));
               });

               channel.bind('resetNumberCommentTicketClient', function (data) {
                    dispatch(resetCmtNumberOfClient(data));
               });

               channel.bind('userWeatherChangesp', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(setWeatherTemperature(data.temperature));
                    }
               });

               channel.bind('updatePreciseLocationConfig', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updatePreciseLocationConfig(data));
                    }
               });

               channel.bind('updateListenerForRoutes', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateListenerForRoutes(data));
                    }
               });

               channel.bind('updatePersonalData', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updatePersonalData(data));
                    }
               });

               channel.bind('updateDaysLength', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(updateDaysLength(data));
                    }
               });

               channel.bind('updateNotification', function (data) {
                    const typeCrawl = (typeof data.type !== 'undefined' && data.type !== 'crawlWarning') || !data.type;
                    if (typeCrawl && (!data.userId || (data.userId && user.id !== data.userId)) && data.accountId === activeAccount.id) {
                         dispatch(updateNotification(data));
                    }
               });

               channel.bind('updateCustomFormRules', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(reloadCustomFormRules());
                    }
               });

               channel.bind('updateElementClickRules', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(reloadElementClickRules());
                    }
               });

               channel.bind('changeVisitorsAndSessionsPerMonth', function (data) {
                    dispatch(changeVisitorsAndSessionsPerMonth(data));
               });

               channel.bind('consentRuleChanged', function (data) {
                    if (user.id !== data.userId) {
                         dispatch(consentRuleChanged(data));
                    }
               });

               channel.bind('consentListenerChanged', function (data) {
                    if (user.id !== data.userId && data.accountId === activeAccount.id) {
                         dispatch(setActiveAccount({ ...activeAccount, consentListener: data.consentListener }));
                    }
               });

               channel.bind('increaseNumberOfFakeVisitorsBy1', function (data) {
                    dispatch(increaseNumberOfFakeVisitorsBy1());
               });

               channel.bind('completeOwnershipTranfer', function (data) {
                    const { userId, oldOwnerId, newOwnerId } = data;

                    if (userId !== user.id) {
                         if (oldOwnerId === user.id) {
                              const newActiveAccount = { ...activeAccount };
                              newActiveAccount.isOwner = false;
                              dispatch(setActiveAccount(newActiveAccount));
                         }
                         if (newOwnerId === user.id) {
                              const newActiveAccount = { ...activeAccount };
                              newActiveAccount.isOwner = true;
                              dispatch(setActiveAccount(newActiveAccount));
                         }
                         dispatch(fetchAccountUsers(activeAccount.id));
                    }
               });

               // pusher conversions created done
               channel.bind('handleConversionsCreatedPusher', function (convesion) {
                    if (convesion) {
                         setTimeout(() => {
                              const data = {
                                   currentStepConversion: STEPS_CONFIG_CONVERSION.completeCreation,
                                   accountId: activeAccount.id,
                              };
                              dispatch(handleSetCurrentStepConversion(data));
                              dispatch(setShowPopupConfigConversion(STEPS_CONFIG_CONVERSION.completeCreation));
                         }, 1000);
                    }
               });

               channel.bind('updateUserAccount', function (data) {
                    const { account } = data;
                    dispatch(updateUserAccount({ account }));
               });

               channel.bind('updateFormRuleStatus', function (data) {
                    const { status } = data;

                    if (status && status === 'waiting') {
                         dispatch(setLoadings({ ruleDefault: true }));
                    }

                    if (status && status === 'done') {
                         dispatch(setLoadings({ ruleDefault: false }));
                    }
               });

               channel.bind('updateViewProfileTargetAccount', function (data) {
                    if (data && Array.isArray(data)) {
                         dispatch(setSubscriberState({ targetAccountsPusher: data }));
                    }
               });

               const channel2 = pusher.subscribe(`channel-${getSupportRedirectChannelId()}`);

               channel2.bind('redirectSupportExit', function () {
                    setTimeout(() => {
                         window.location.replace('/');
                    }, 500);
               });

               return () => {
                    channel.unbind();
                    channel2.unbind();
               };
          }
     };

     useEffect(handlePusher, [activeAccount.id]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleUserPusher = () => {
          if (user.id) {
               const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
               });
               const channel = pusher.subscribe(`channel-${user.id}`);
               const allUserChannel = pusher.subscribe('ProdDataLayer-AllUsers');

               // Called when a user modified google calendar
               channel.bind('updateAvailableHours', function (data) {
                    dispatch(updateAvailableHours(data));
               });

               // Called when a user accepting/rejecting a partner application
               channel.bind('approvePartner', function (data) {
                    dispatch(approvePartner(data));
               });

               allUserChannel.bind('reloadOfficeHourCalendar', function (data) {
                    dispatch(reloadOfficeHourCalendar(data));
               });

               return () => {
                    channel.unbind();
                    allUserChannel.unbind();
               };
          }
     };

     useEffect(handleUserPusher, [user.id]); // eslint-disable-line react-hooks/exhaustive-deps

     return <></>;
};

export default React.memo(ThePusher);
