import React from 'react';
import { CButton, CModal, CModalBody } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlexibleModal, setFlexibleMultiModal, setRuleHaveEditting, setRuleMultiHaveEditing, setUnsavedChanges } from '../../../actions/common';
import { API_SALESFORCE_CONNECTION, COMPONENT_NAME, TYPE_SHOW_UNSAVE_CHANGE } from '../../../constants';
import { callSalesforceApi } from '../../../apiCaller';

const UnsavedChanges = () => {
     const dispatch = useDispatch();
     const edittingRule = useSelector((state) => state.theme.edittingRule);
     const flexibleModal = useSelector((state) => state.theme.flexibleModal);
     const editMultiRule  = useSelector((state) => state.theme.editMultiRule );
     const stayHere = () => {
          dispatch(setUnsavedChanges(false));
     };

     const discardChange = () => {
          if (edittingRule.typeLv2 !== TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP) {
               dispatch(
                    setFlexibleModal({
                         show: false,
                         ruleId: '',
                         component: '',
                         ruleIdLv2: '',
                         componentLv2: '',
                    })
               );
               dispatch(setRuleHaveEditting({ ...edittingRule, show: false, type: '' }));
          }
          if (edittingRule.typeLv2 === TYPE_SHOW_UNSAVE_CHANGE.EDIT_LOOKUP) {
               dispatch(
                    setFlexibleModal({
                         ...flexibleModal,
                         showLv2: false,
                         ruleIdLv2: '',
                         componentLv2: '',
                    })
               );
               dispatch(setRuleHaveEditting({ ...edittingRule, showLv2: false, typeLv2: '' }));
          }

          if (edittingRule.type === COMPONENT_NAME.SALESFORCE_CONNECTED_ACCOUNTS) {
               if (edittingRule.idConnection) {
                    callSalesforceApi(API_SALESFORCE_CONNECTION, 'DELETE', { id: edittingRule.idConnection }).then((response) => {
                         if (response && response.status === 200) {
                              console.log(`Delete Connection ${edittingRule.idConnection} successfully`);
                         }
                    });
               }
          }

          if (editMultiRule.show && editMultiRule.component) {
               dispatch(
                    setFlexibleMultiModal({
                         show: false,
                         component: editMultiRule.component,
                         attributes: {},
                    })
               );
               dispatch(setRuleMultiHaveEditing({ ...edittingRule, show: false, type: '', component: '' }))
          }

          dispatch(setRuleMultiHaveEditing({}));
          dispatch(setUnsavedChanges(false));
     };

     return (
          <div className="unsaved-changes-popup confirm-save-popup leave-component">
               <CModal visible={true} color="primary" alignment="center" portal={false}>
                    <CModalBody className="text-center confirm-save">
                         <div className="confirm-save-icon"><CIcon icon="icon-save" height={46} width={46} /></div>
                         <h2>You Have Unsaved Changes</h2>
                         <div className="confirm-save-content">
                              <p>Are you sure you want to leave? There are unsaved changes. If you leave, your changes will be lost.</p>
                         </div>
                         <div className="position-relative d-inline-block">
                              <CButton onClick={stayHere} className="btn-primary">
                                   Keep Editing the rule
                              </CButton>
                              <CButton onClick={discardChange} className="btn-light">
                                   Abandon My Changes & Leave
                              </CButton>
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default UnsavedChanges;
