import { combineReducers } from 'redux';
import theme from './theme';
import subscriber from './subscriber';
import admin from './admin';
import support from './support';

export default combineReducers({
     theme,
     subscriber,
     admin,
     support,
});