import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCardBody } from '@coreui/react'; //eslint-disable-line
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFlexibleModal } from '../../../../../actions/common';
import { API_CLIENT_GOALS, SUBSCRIBER_PATH } from '../../../../../constants';
import { ClickInfoIconTooltip } from '../../../../general/custom-tooltips';
import { SwitchPopup } from '../../../../general/popup';
import CSwitch from '../../../../migration/CSwitch';
import { setSubscriberState } from '../../../../../actions/admin';
import { callTokenApi } from '../../../../../apiCaller';
import { toast } from 'react-toastify';
import { getAccountGoalsRoute } from '../../../../../helpers/cms/subscriber';
import CTooltip from '../../../../migration/CTooltip';

function ManageGoalItems() {
     const dispatch = useDispatch();
     const history = useHistory();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const accountGoals = useSelector((state) => state.subscriber.accountGoals) || [];
     const accountRoutes = useSelector((state) => state.subscriber.routes);

     const [currentGoals, setCurrentGoals] = useState(accountGoals)

     const [isLoading, setIsLoading] = useState(false);
     const [tempGoal, setTempGoal] = useState();
     const [turningOnPopup, setTurningOnPopup] = useState(false);
     const [turningOffPopup, setTurningOffPopup] = useState(false);

     const handleLinkToPageDetail = (alias) => {
          history.push(`${SUBSCRIBER_PATH.GOALS.replace(':secondId', activeAccount.secondId)}/${alias}`);
          dispatch(setFlexibleModal({ show: false, component: '' }));
     };

     const toggleItem = (item, status) => {
          setTempGoal(item);

          if (status === 'on') {
               setTurningOffPopup(true);
          } else {
               setTurningOnPopup(true);
          }
     };

     const acceptSwitching = () => {
          const status = !tempGoal.status ? true : false;

          const data = {
               accountId: activeAccount.id,
               id: tempGoal.id,
               status,
          };

          setIsLoading(true);
          callTokenApi(`${API_CLIENT_GOALS}`, 'PUT', data).then((response) => {
               if (response.status !== 200) return;
               setIsLoading(false);

               const resAccountGoal = response.data.data;
               const newAccountGoals = [...accountGoals].map((accGoal) => {
                    if (accGoal.id === resAccountGoal.id) {
                         return { ...accGoal, status: resAccountGoal.status };
                    }
                    return accGoal;
               });

               const accountGoalsRoutes = getAccountGoalsRoute(accountGoals, activeAccount.secondId);
               const newGoalsRoutes = getAccountGoalsRoute(newAccountGoals, activeAccount.secondId);
               const newAccountRoutes = [...accountRoutes].filter(
                    (route) => !accountGoalsRoutes.some((accountGoal) => accountGoal.path === route.path)
               );
               const newRoutes = [...newAccountRoutes, ...newGoalsRoutes];

               dispatch(
                    setSubscriberState({
                         routes: newRoutes,
                         accountGoals: newAccountGoals,
                    })
               );
               setCurrentGoals(newAccountGoals)


               if (resAccountGoal.status) toast.success(`${tempGoal.name} Active!`);
               else toast.success(`${tempGoal.name} is Disabled!`);

               if (turningOnPopup) setTurningOnPopup(false);
               if (turningOffPopup) setTurningOffPopup(false);
          });
     };

     return (
          <div className="goals-wrapper">
               <div className="goals-items row-items">
                    {currentGoals.map((goalType) => {
                         const { name, description, status } = goalType;
                         const isDisabledToggle = !goalType.isEnableEdit;
                         const isDisabledTargetAccount = name === 'Target Accounts' && !activeAccount.companyStatus && !status;

                         // let iconSrc = getListenerColorIcon(listener);

                         return (
                              <div className="goals-item" key={name}>
                                   <CCard>
                                        <CCardBody>
                                             <div className="card-title d-flex justify-content-between w-100">
                                                  <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                                                       <CIcon name={goalType.icon} height="20" />
                                                       <h4 className="m-0">{name}</h4>
                                                  </div>
                                             </div>

                                             <div className="card-text h-100">{description && <p className="f-14">{description}</p>}</div>
                                             <div className={`card-icon d-flex justify-content-end`}>
                                                  <div className="card-header-actions">
                                                       {!goalType.comingSoon && (
                                                            <>
                                                                 {goalType.status && (
                                                                      <CButton
                                                                           className="action-open-menu"
                                                                           onClick={() => handleLinkToPageDetail(goalType.alias)}
                                                                           style={{ padding: 0 }}
                                                                      >
                                                                           <CIcon name="cil-settings" />
                                                                      </CButton>
                                                                 )}

                                                                 {goalType.information && (
                                                                      <div style={{ position: 'relative' }}>
                                                                           <ClickInfoIconTooltip placement="bottom">
                                                                                <p>{goalType.information}</p>
                                                                           </ClickInfoIconTooltip>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}
                                                       {/* {isLoading && ['eCommerce'].includes(goalType.name) && <CSpinner color='primary' size='sm' />} */}
                                                       {goalType.comingSoon && <span className="coming-soon-text">Coming soon</span>}



                                                       {isDisabledTargetAccount && (
                                                            <div style={{ position: 'relative' }}>
                                                                 <CTooltip
                                                                      content={'Turn on the revealed company to use this feature!'}
                                                                      placement="bottom"
                                                                      advancedOptions={{
                                                                           onShow(instance) {
                                                                                instance.popper.className = 'tooltip-goals';
                                                                           }
                                                                      }}
                                                                 >
                                                                      <span>
                                                                           <CIcon icon="iconInfo" />
                                                                      </span>
                                                                 </CTooltip>
                                                            </div>
                                                       )}

                                                       <CSwitch
                                                            className="float-right mb-0"
                                                            color={status ? 'success' : 'light'}
                                                            checked={!!status} // !!false = false, !!undefined = false, !!true = true
                                                            value={status ? 'on' : 'off'}
                                                            size="lg"
                                                            tabIndex="0"
                                                            shape="pill"
                                                            onChange={(e) => toggleItem(goalType, e.target.value)}
                                                            disabled={isDisabledToggle || isDisabledTargetAccount}
                                                       />
                                                  </div>
                                             </div>
                                        </CCardBody>
                                   </CCard>
                              </div>
                         );
                    })}

                    <SwitchPopup show={turningOnPopup} isLoading={isLoading} onAccept={acceptSwitching} onCancel={() => setTurningOnPopup(false)}>
                         <h2>Enable Goal</h2>
                         <div className="confirm-save-content">
                              You're about to enable this goal. You will still need to publish your changes before this goes live.
                         </div>
                    </SwitchPopup>
                    <SwitchPopup show={turningOffPopup} onAccept={acceptSwitching} onCancel={() => setTurningOffPopup(false)}>
                         <h2>Disable Goal</h2>
                         <div className="confirm-save-content">
                              Once a goal is disabled, all settings and rules for it will be lost and cannot be restored. Are you sure you want to
                              make this change?
                         </div>
                    </SwitchPopup>
               </div>
          </div>
     );
}

export default ManageGoalItems;
