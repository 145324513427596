import React, { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LOCAL_SHOW_SETUP_ACCOUNTS, LOCAL_DIRECT_PATH, REPORT_NAMES } from '../constants';
import { subscriberNavKey } from '../containers/_nav/subscriber';

// Clear everything except items called "theme" and LOCAL_SHOW_SETUP_ACCOUNTS
export const clearLocal = () => {
     const theme = JSON.parse(localStorage.getItem('theme'));
     const localShowSetupAccounts = JSON.parse(localStorage.getItem(LOCAL_SHOW_SETUP_ACCOUNTS));
     const localShowInstallation = JSON.parse(localStorage.getItem('showInstallationScreen'));
     localStorage.clear();
     localStorage.setItem('theme', JSON.stringify(theme));
     localStorage.setItem(LOCAL_SHOW_SETUP_ACCOUNTS, JSON.stringify(localShowSetupAccounts));
     localStorage.setItem('showInstallationScreen', JSON.stringify(localShowInstallation));
     if (!window.location.href.includes('/login') && !window.location.href.includes('/logout') && !window.location.href.includes('/invite-user')) {
          const directPath = window.location.href.replace(window.location.origin, '');
          const splitDirect = directPath.split('/');
          splitDirect.splice(1, 1);

          localStorage.setItem(LOCAL_DIRECT_PATH, splitDirect.join('/'));
     }
};

export const getTokenCookie = () => {
     const token = cookie.get('token');

     if (token) {
          return token;
     }

     clearLocal();
     window.location.replace('/login');
};

export const removeTokenCookie = () => {
     let tokenOptions = { expires: 1 };

     if (window.location.hostname !== 'localhost') {
          tokenOptions.domain = 'listenlayer.com';
     }

     cookie.remove('token', tokenOptions);
};

export const isoDateToString = (isoDate) => {
     return new Date(isoDate).toLocaleDateString();
};

export const formatDate = (date, format) => {
     return dayjs(date).format(format);
};

// Used to validate between fields of formik validationSchema
export const validateFormik = (getValidationSchema, next = (f) => f, data = {}) => {
     const getErrorsFromValidationError = (validationError) => {
          const FIRST_ERROR = 0;
          return validationError.inner.reduce((errors, error) => {
               return {
                    ...errors,
                    [error.path]: error.errors[FIRST_ERROR],
               };
          }, {});
     };

     return (values) => {
          next();
          const validationSchema = getValidationSchema(values, data);

          try {
               validationSchema.validateSync(values, { abortEarly: false });
               return {};
          } catch (error) {
               return getErrorsFromValidationError(error);
          }
     };
};

export const toastError = (response, options) => {
     if (!response.data.accountBlocked) {
          const message = response.data.message || response.data.error || response.statusText;
          toast.error(message, options);
     }
};

// Get from the character index after first dash to the end of string.
// Example: activity_created_at => created_at or user_name => name
export const getAfterFirstDash = (str) => {
     let fieldName = str;
     const dashIndex = fieldName.indexOf('_');

     if (dashIndex !== -1) {
          return fieldName.substr(dashIndex + 1);
     }

     return fieldName;
};

// export const getLocalUser = () => {
//      const user = JSON.parse(localStorage.getItem('user'));
//      return user || { id: '', role: '' };
// }

export const deepCopyArray = (arr) => {
     return JSON.parse(JSON.stringify(arr));
};

// Turn thing 1 and 2 into json string and compare
export const jsonAndCompare = (thing1, thing2) => {
     return JSON.stringify(thing1) === JSON.stringify(thing2);
};

export const isEmpty = (array) => {
     var checkEmpty = false;
     array.some((element) => {
          if (element === '') {
               checkEmpty = true;
               return true;
          }
          checkEmpty = false;
          return false;
     });
     return checkEmpty;
};

export const isDuplicate = (array) => {
     for (var i = 0; i < array.length; i++) {
          for (var j = i + 1; j < array.length; j++) {
               if (array[i] === array[j]) {
                    return true;
               }
          }
     }
     return false;
};

export const checkDomain = (data) => {
     const domainFormat = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
     var checkDomain = false;
     data.some((domain) => {
          if (!domainFormat.test(domain)) {
               checkDomain = true;
               return true;
          }
          checkDomain = false;
          return false;
     });
     return checkDomain;
};

export const useOutsideHandling = (ref, clickOutside, eventListener = 'click', showPopupCreateCategories = false) => {
     React.useEffect(() => {
          const handleClickOutside = (event) => {
               if (showPopupCreateCategories) {
                    return;
               } else {
                    if (ref.current && !ref.current.contains(event.target)) {
                         clickOutside();
                    }
               }
          };

          const handlePressEsc = (event) => {
               if (ref.current && event.key === 'Escape') {
                    clickOutside();
               }
          };

          // Bind the event listener
          document.addEventListener(eventListener, handleClickOutside);
          document.addEventListener('keydown', handlePressEsc);
          return () => {
               // Unbind the event listener on clean up
               document.removeEventListener(eventListener, handleClickOutside);
               document.removeEventListener('keydown', handlePressEsc);
          };
     }, [ref, clickOutside]); // eslint-disable-line
};

export const getAnUrlParam = (href) => {
     var vars = {};
     const getParam = (m, key, value) => {
          vars[key.toLowerCase()] = value;
     };
     href.replace(/[?&]+([^=&]+)=([^&]*)/gi, getParam);

     return vars;
};

export const getLocalItem = (itemName) => {
     return JSON.parse(localStorage.getItem(itemName));
};

export const roundTo2DecimalPlaces = (number) => {
     return Math.round(number * 100) / 100;
};

export const numberWithCommas = (number) => {
     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const tryParseJSON = (jsonString) => {
     try {
          if (jsonString && typeof jsonString === 'object') {
               return jsonString;
          }

          let o = JSON.parse(jsonString);
          if (o && typeof o === 'object') {
               return o;
          }
     } catch (e) {
          return false;
     }
     return false;
};

export const parseJson = (jsonString) => {
     if (typeof jsonString !== 'undefined' && jsonString !== '') {
          if (typeof jsonString === 'string') {
               try {
                    let object = JSON.parse(jsonString);

                    if (object && typeof object === 'object') {
                         return object;
                    }
               } catch (e) {
                    return null;
               }
          } else {
               return jsonString;
          }
     }
     return null;
};

export const tagPropType = PropTypes.oneOfType([
     PropTypes.func,
     PropTypes.string,
     PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
     PropTypes.arrayOf(
          PropTypes.oneOfType([
               PropTypes.func,
               PropTypes.string,
               PropTypes.shape({
                    $$typeof: PropTypes.symbol,
                    render: PropTypes.func,
               }),
          ])
     ),
]);

export const useScrollTop = () => {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
};

export const validateEmail = (email) => {
     let emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

     return emailRegex.test(email);
};

export const getURLParams = (searchString = window.location.search.slice(1)) => {
     var params = {};
     var regex = /([^&=]+)=([^&]*)/g,
          m;
     m = regex.exec(searchString);
     while (m) {
          params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
          m = regex.exec(searchString);
     }

     return params;
};

export const getTimeZoneOffset = (timeZone) => {
     let offsetInMinutes = dayjs().tz(timeZone).$offset; // The time difference between UTC time and "timeZone", in minutes
     let sign = offsetInMinutes > 0 ? '+' : '-';
     let offsetInHours = Math.abs(offsetInMinutes / 60);
     let minutes = (offsetInHours % 1) * 60;
     offsetInHours = Math.trunc(offsetInHours);
     let offsetInHoursStr = offsetInHours.toString();
     let theLast2Digits = minutes === 0 ? '00' : minutes.toString();

     if (offsetInHoursStr.length === 1) {
          offsetInHoursStr = `${sign}0${offsetInHoursStr}:${theLast2Digits}`;
     } else {
          offsetInHoursStr = `${sign}${offsetInHoursStr}:${theLast2Digits}`;
     }

     return `GMT${offsetInHoursStr}`;
};

export const base64Decode = (dataEncode) => {
     return JSON.parse(Buffer.from(dataEncode, 'base64').toString('ascii'));
};

export const guidGenerator = () => {
     const S4 = function () {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
     };
     return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
};

export const exportToCSV = (filename, rows) => {
     var processRow = function (row) {
          var finalVal = '';
          for (var j = 0; j < row.length; j++) {
               if (row[j]) {
                    var innerValue = row[j] === null ? '' : row[j].toString();
                    if (row[j] instanceof Date) {
                         innerValue = row[j].toLocaleString();
                    }
                    var result = innerValue.replace(/"/g, '""');
                    if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
                    if (j > 0) finalVal += ',';
                    finalVal += result;
               }
          }
          return finalVal + '\n';
     };

     var csvFile = '';

     for (var i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i]);
     }

     // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
     // Prepend \ufeff to the csvFile to prevent special characters from being updated to other characters
     var blob = new Blob(['\ufeff' + csvFile], {
          type: 'text/csv;charset=utf-8;',
     });

     if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
     } else {
          var link = document.createElement('a');
          if (link.download !== undefined) {
               // feature detection
               // Browsers that support HTML5 download attribute
               var url = URL.createObjectURL(blob);
               link.setAttribute('href', url);
               link.setAttribute('download', filename);
               link.style.visibility = 'hidden';
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
          }
     }
};
export const exportToCSV2 = (csvFile, filename) => {
     // https://stackoverflow.com/questions/17879198/adding-utf-8-bom-to-string-blob
     // Prepend \ufeff to the csvFile to prevent special characters from being updated to other characters
     var blob = new Blob(['\ufeff' + csvFile], {
          type: 'text/csv;charset=utf-8;',
     });

     if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, filename);
     } else {
          var link = document.createElement('a');
          if (link.download !== undefined) {
               // feature detection
               // Browsers that support HTML5 download attribute
               var url = URL.createObjectURL(blob);
               link.setAttribute('href', url);
               link.setAttribute('download', filename);
               link.style.visibility = 'hidden';
               document.body.appendChild(link);
               link.click();
               document.body.removeChild(link);
          }
     }
};

export const dateDiffIndays = (date1, date2) => {
     let dt1 = new Date(date1);
     let dt2 = new Date(date2);
     return Math.floor(
          (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate(), dt2.getHours()) -
               Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate(), dt1.getHours())) /
          (1000 * 60 * 60 * 24)
     );
};

export const dateDiffInseconds = (date1, date2) => {
     let dt1 = new Date(date1);
     let dt2 = new Date(date2);
     return Math.floor((dt2.getTime() - dt1.getTime()) / 1000);
};

export const strToClass = (str) => {
     return str.toLowerCase().replace(/ /g, '-');
};

export const removeURLParams = () => {
     window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
};

export const getHostName = () => {
     let _urlApi = process.env.REACT_APP_DATALAYER_APP_DOMAIN;
     return _urlApi;
};

export const getLinkCertificated = () => {
     let _urlApi = process.env.REACT_APP_CERTIFICATED_URL;
     return _urlApi;
};

export const getLinkBaseActiveCampaign = () => {
     let baseUrl = process.env.REACT_APP_ACTIVE_HOSTED;
     return baseUrl;
};

export const secondsToTime = (seconds) => {
     let time_left_hours = Math.floor(seconds / (60 * 60));
     time_left_hours = time_left_hours < 10 ? `0${time_left_hours}` : time_left_hours;

     let divisor_for_minutes = seconds % (60 * 60);
     let time_left_minutes = Math.floor(divisor_for_minutes / 60);
     time_left_minutes = time_left_minutes < 10 ? `0${time_left_minutes}` : time_left_minutes;

     let divisor_for_seconds = divisor_for_minutes % 60;
     let time_left_seconds = Math.ceil(divisor_for_seconds);
     time_left_seconds = time_left_seconds < 10 ? `0${time_left_seconds}` : time_left_seconds;

     return {
          h: time_left_hours,
          m: time_left_minutes,
          s: time_left_seconds,
          time: seconds,
     };
};

export const formatUserDate = (date) => {
     const dateObject = new Date(date);
     const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
     const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
     let dayOfWeek = dayNames[dateObject.getDay()];
     let nameOfMonth = monthNames[dateObject.getMonth()];
     let daySuffix = 'th';
     let dayOfMonth = dateObject.getDate();
     switch (dayOfMonth) {
          case 1:
          case 21:
          case 31:
               daySuffix = 'st';
               break;
          case 2:
          case 22:
               daySuffix = 'nd';
               break;
          case 3:
          case 23:
               daySuffix = 'rd';
               break;
          default:
               daySuffix = 'th';
               break;
     }

     return `${dayOfWeek}, ${nameOfMonth} ${dayOfMonth}${daySuffix}`;
};

export const formatTimeOfficeHours = (start, end) => {
     const startDate = new Date(start);
     const endDate = new Date(end);
     const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
     const dayOfMonth = (startDate.getDate() < 10 ? '0' : '') + startDate.getDate();
     const month = monthNames[startDate.getMonth()];
     const year = startDate.getFullYear();
     const hoursStart = startDate.getHours();
     const hoursEnd = endDate.getHours();

     return {
          date: `${dayOfMonth} ${month} ${year}`,
          hours: `${hoursStart}h - ${hoursEnd}h`,
     };
};

export const initializeGTM = () => {
     if (!document.querySelector('script[src*="https://www.googletagmanager.com/gtm.js"]')) {
          (function (w, d, s, l, i) {
               w[l] = w[l] || [];
               w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js',
               });
               var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l !== 'dataLayer' ? '&l=' + l : '';
               j.async = true;
               j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
               f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', process.env.REACT_APP_GTM_CONTAINER_ID);
     }
};

export const gtmTrackingIdentifyLLUser = (userDetails, accountDetails) => {
     let data = {
          event: 'identifyLLUser',
     };

     if (userDetails) {
          data.userDetails = userDetails;
     }

     if (accountDetails) {
          data.accountDetails = accountDetails;
     }

     window.dataLayer = window.dataLayer || [];
     window.dataLayer.push(data);
     initializeGTM();
};

export const gtmTrackingChangeLLAccount = (userDetails, accountDetails) => {
     let data = {
          event: 'changeLLAccount',
     };

     if (userDetails) {
          data.userDetails = userDetails;
     }

     if (accountDetails) {
          data.accountDetails = accountDetails;
     }

     console.log(data);
     window.dataLayer = window.dataLayer || [];
     window.dataLayer.push(data);
     initializeGTM();
};

export const removeHash = () => {
     window.history.replaceState('', document.title, window.location.pathname + window.location.search);
};

export const cleanURL = () => {
     window.history.replaceState('', document.title, window.location.pathname);
};

export const removeHTMLFromString = (str) => {
     return str.replace(/<[^>]*>?/gm, '');
};

export const swap2ElementsOfArr = (arr, position1, position2) => {
     return ([arr[position1], arr[position2]] = [arr[position2], arr[position1]]);
};

export const includeLowercase = (str1, str2) => {
     return str1.toLowerCase().includes(str2.toLowerCase());
};

export const moveElementToFrontArr = (arr, elementIndex) => {
     return arr.unshift(arr.splice(elementIndex, 1)[0]);
};

export const getUnlimitedNumber = (number) => {
     return number === -1 ? 'unlimited' : `${number}`;
};
export const getDataChild = (dataChild, key, keyName) => {
     if (typeof dataChild[key] === 'object' && dataChild[key] !== null) {
          let _path = keyName !== '' ? `${keyName}.${key}` : key;
          const flag = Object.keys(dataChild[key]).some((child) => {
               if (!dataChild[key][child]) return false;
               if (typeof dataChild[key][child] === 'object') {
                    return true;
               }
               return false;
          });

          if (flag) {
               return {
                    title: key,
                    path: _path,
                    fullCode: dataChild[key],
                    hasChild: true,
                    childs: [],
               };
          }

          if (Object.keys(dataChild[key]).length > 0) {
               return {
                    title: key,
                    path: _path,
                    fullCode: dataChild[key],
                    hasChild: false,
               };
          }
          return null;
     }

     return {
          title: key,
          path: keyName !== '' ? `${keyName}.${key}` : key,
          fullCode: { [key]: dataChild[key] },
          hasChild: false,
     };
};

export const parseEventDataLv = (data, keyName) => {
     const _arrayLv = [];

     for (let key in data) {
          const _dataResult = getDataChild(data, key, keyName);

          if (_dataResult && _dataResult.hasChild) {
               const _childArrayLv = parseEventDataLv(data[key], keyName !== '' ? `${keyName}.${key}` : key);

               _dataResult.childs = _childArrayLv;
               _arrayLv.push(_dataResult);
          } else if (_dataResult) {
               _arrayLv.push(_dataResult);
          }
     }
     return _arrayLv;
};

export const getDLScript = (accountId) => {
     let suffix = '',
          isAmd = '',
          amdVariable = '';

     // https://rubberb.com/
     const ids = ['663a5206-c41e-42fd-8cae-1a21cd59e270'];

     switch (process.env.REACT_APP_ENV) {
          case 'production':
               suffix = '';
               break;
          default:
               suffix = '-' + process.env.REACT_APP_ENV + '-datalayer';
               break;
     }

     if (ids.some((id) => id === accountId)) {
          isAmd = '.amd';
          amdVariable = 'var isAmd=true;';
     }

     return `<!-- ListenLayer -->
<script type="text/javascript">${amdVariable}(function(c,a,d,b,e) {c[b] = c[b] || [];
c=a.getElementsByTagName(d)[0];a=a.createElement(d);b="dataLayer"!= b ?"\x26l\x3d"+b:"";a.async = !0;
a.src = "https://assets${suffix}.listenlayer.com/datalayer${isAmd}.min.js?id\x3d"+e+b;
c.parentNode.insertBefore(a,c)})(window,document,"script","dataLayer",
"${accountId}");</script>
<!-- End ListenLayer -->`;
};

export const getSessionKeySignupListeners = (accountId) => {
     return `llSignupListeners_${accountId}`;
};

export const getSessionKeySignupDestinations = (accountId) => {
     return `llSignupDestination_${accountId}`;
};

export const getSessionKeySignupCurrentStep = (accountId) => {
     return `llSignupCurrentStep_${accountId}`;
};

export const removeDuplicateValueInArray = (array) => {
     if (!array) return [];

     const newArray = array.filter((item, index) => {
          return array.findIndex((_item) => _item === item) === index;
     });

     return newArray;
};

export const getStaticJSFile = (accountId) => {
     let suffix = '';

     switch (process.env.REACT_APP_ENV) {
          case 'production':
               suffix = '';
               break;
          default:
               suffix = '-' + process.env.REACT_APP_ENV;
               break;
     }
     return `https://static${suffix}.listenlayer.com/${accountId}.json`;
};

export const handleToggleSidebar = ({
     activeMainNavItem = null,
     toggleMinimizeNav = false,
     forceMinimizeNav = null,
     toggleShowNavChild = false,
     forceShowNavChild = null,
}) => {
     const sidebar = document.querySelector('.sidebar-v2');
     const wrapper = document.querySelector('.c-wrapper.v2');
     const sidebarLogos = document.querySelectorAll('.sidebar-v2 .sidebar-top .sidebar-brand-full');
     const headerToggle = document.querySelector('.c-wrapper.v2 .toggle-sidebar');
     const sidebarToggle = document.querySelector('.sidebar-v2 .toggle-sidebar');

     if (sidebar) {
          const sidebarClassList = sidebar.classList;
          const checkSidebarMinimize = sidebarClassList.contains('minimize-nav');
          const checkSidebarShowNavChild = sidebarClassList.contains('show-nav-child');
          const currentSidebarMinimize =
               forceMinimizeNav !== null ? forceMinimizeNav : toggleMinimizeNav ? !checkSidebarMinimize : checkSidebarMinimize;
          const currentSidebarShowNavChild =
               forceShowNavChild !== null ? forceShowNavChild : toggleShowNavChild ? !checkSidebarShowNavChild : checkSidebarShowNavChild;
          const hasNavChild = !!activeMainNavItem && activeMainNavItem !== subscriberNavKey.INSIGHTS;
          if (toggleMinimizeNav) {
               if (currentSidebarMinimize) {
                    sidebarClassList.add('minimize-nav');
               } else {
                    sidebarClassList.remove('minimize-nav');
               }

               if (wrapper) {
                    const wrapperClasList = wrapper.classList;
                    if (currentSidebarMinimize) {
                         wrapperClasList.add('minimize-nav');
                    } else {
                         wrapperClasList.remove('minimize-nav');
                    }
               }
          }

          if (toggleShowNavChild) {
               if (currentSidebarShowNavChild) {
                    sidebarClassList.add('show-nav-child');
               } else {
                    sidebarClassList.remove('show-nav-child');
               }

               if (wrapper) {
                    const wrapperClasList = wrapper.classList;
                    if (currentSidebarShowNavChild) {
                         wrapperClasList.add('show-nav-child');
                    } else {
                         wrapperClasList.remove('show-nav-child');
                    }
               }
          }

          if (headerToggle) {
               const headerToggleClasList = headerToggle.classList;

               if (currentSidebarMinimize && !currentSidebarShowNavChild) {
                    headerToggleClasList.remove('hide');
                    headerToggleClasList.add('toggler-right');
               } else {
                    headerToggleClasList.add('hide');
               }
          }

          if (sidebarToggle) {
               const sidebarToggleClasList = sidebarToggle.classList;

               if (currentSidebarMinimize && !currentSidebarShowNavChild && !hasNavChild) {
                    sidebarToggleClasList.add('hide');
               } else {
                    sidebarToggleClasList.remove('hide');
               }

               if (!currentSidebarShowNavChild) {
                    sidebarToggleClasList.add('toggler-right');
               } else {
                    sidebarToggleClasList.remove('toggler-right');
               }
          }

          if (sidebarLogos) {
               sidebarLogos.forEach((sidebarLogo) => {
                    const sidebarLogoClass = sidebarLogo.classList;
                    sidebarLogoClass.add('hide');

                    if (currentSidebarMinimize) {
                         if (sidebarLogoClass.contains('minimize-nav')) {
                              sidebarLogoClass.remove('hide');
                         }
                    } else {
                         if (sidebarLogoClass.contains('full-logo')) {
                              sidebarLogoClass.remove('hide');
                         }
                    }
               });
          }
     }
};

export const capitalizeFirstLetter = (string) => {
     return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterEachWords = (string) => {
     return string
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
};

export const resizeSidebarModal = (defaultSize) => {
     const sidebarModal = document.querySelector('.sidebar-modal');
     const sidebarModalDragHandles = document.querySelectorAll('.sidebar-modal .flexible-resize');
     sidebarModalDragHandles.forEach((sidebarModalDragHandle) => {
          const sidebarFlexibleModal = sidebarModalDragHandle.closest('.ll-flexible-modal');

          if (defaultSize) {
               sidebarFlexibleModal.style.maxWidth = `${defaultSize}px`;
          }

          const startDrag = (e) => {
               sidebarModal.classList.remove('ll-flexible-modal--animated');
               sidebarModal.classList.add('ll-flexible-modal--pulling');
               document.documentElement.addEventListener('mousemove', doDrag, false);
               document.documentElement.addEventListener('mouseup', stopDrag, false);
          };

          const doDrag = (e) => {
               const dragWidth = window.innerWidth - e.clientX;
               if (dragWidth > window.innerWidth - 80) {
                    sidebarFlexibleModal.style.maxWidth = `${window.innerWidth - 80}px`;
               } else if (dragWidth < window.innerWidth * 0.6) {
                    sidebarFlexibleModal.style.maxWidth = `${window.innerWidth * 0.6}px`;
               } else {
                    sidebarFlexibleModal.style.maxWidth = `${dragWidth}px`;
               }
          };

          const stopDrag = (e) => {
               document.documentElement.removeEventListener('mousemove', doDrag, false);
               document.documentElement.removeEventListener('mouseup', stopDrag, false);
               sidebarModal.classList.add('ll-flexible-modal--animated');
               sidebarModal.classList.remove('ll-flexible-modal--pulling');
          };

          sidebarModalDragHandle.addEventListener('mousedown', startDrag, false);
     });
};

export const convertReportDuration = (totalSeconds) => {
     var sec_num = parseInt(totalSeconds, 10);
     var hours = Math.floor(sec_num / 3600);
     var minutes = Math.floor(sec_num / 60) % 60;
     var seconds = sec_num % 60;

     return hours > 0 ? `${hours}h ${minutes}m ${seconds}s` : `${minutes}m ${seconds}s`;
};
export const convertReportSeconds = (time) => {
     let newTime = 0;
     const timeArr = time.split(' ');
     if (timeArr.length > 0) {
          timeArr.forEach((item) => {
               if (item.indexOf('h') !== -1) {
                    const hours = parseInt(item.slice(0, item.indexOf('h')));
                    if (Number.isFinite(hours)) {
                         newTime = newTime + hours * 3600;
                    }
               }
               if (item.indexOf('m') !== -1) {
                    const minutes = parseInt(item.slice(0, item.indexOf('m')));
                    if (Number.isFinite(minutes)) {
                         newTime = newTime + minutes * 60;
                    }
               }
               if (item.indexOf('s') !== -1) {
                    const seconds = parseInt(item.slice(0, item.indexOf('s')));
                    if (Number.isFinite(seconds)) {
                         newTime = newTime + seconds;
                    }
               }
          });
     }
     if (newTime === 0) {
          newTime = time;
     }
     return newTime;
};

export const encodeObj = (obj) => {
     return btoa(encodeURIComponent(JSON.stringify(obj)));
};

export const decodeObj = (obj) => {
     return tryParseJSON(decodeURIComponent(atob(obj)));
};

if (window.location.search.includes('show-server-side=1')) {
     localStorage.setItem('show-server-side', '1');
}

if (window.location.search.includes('revealed-company=1')) {
     localStorage.setItem('llRevealedCompany', '1');
}

export const checkEnableRevealCompany = () => {
     if (window.location.search.includes('revealed-company=1') || localStorage.getItem('llRevealedCompany')) {
          return true;
     }
     return false;
};

export const generateId = (string) => {
     const random = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
     if (!string) {
          return random;
     }
     return string + random;
};

export const getIssueFromCategories = (issue) => {
     switch (issue) {
          case 'isUncategorized':
               return 'Form is uncategorized';
          case 'isHasNoListener':
               return 'Form has no Listener';
          case 'isAttention':
               return 'Needs attention';
          case 'isDrafted':
               return 'Changes drafted';
          case 'isIgnored':
               return 'Ignored';
          case 'isNoAction':
               return 'No action required';
          case 'isCategorized':
               return 'Already categorized';
          default:
               return '';
     }
};

export const getFromValueNameKey = (data) => {
     if (data.formId) return 'id';
     if (data.formClass) return 'class';
     if (data.formName) return 'name';
     if (data.formAction) return 'action';
     if (data.formMethod) return 'method';
};

export function checkConditionOperator(formValue, value, operator) {
     let status = false;
     switch (operator) {
          case 'em': // Exactly matches
               status = formValue === value;
               break;
          case 'ct': // Contains
               status = formValue.includes(value);
               break;
          case 'nct': // does not contain
               status = !formValue.includes(value);
               break;
          case 'bw': // Begins with
               status = formValue.startsWith(value);
               break;
          case 'ew': // Ends with
               status = formValue.endsWith(value);
               break;
          default:
               status = false;
               break;
     }
     return status;
}

export function isMatchCustomForm(form, targetRules) {
     let matchRules = false;

     const checkRuleCondition = ({ condition, j }) => {
          const length = Object.keys(condition).length;
          let status = false;

          if (j < length) {
               const _object = condition['g' + j] || condition;
               let valuesToCompares = [];

               if (_object.attribute === 'CSS Class' && typeof form.formClass === 'string') {
                    valuesToCompares = form.formClass.split(' ');
               }

               if (_object.attribute === 'CSS ID' && typeof form.formId === 'string') {
                    valuesToCompares = form.formId.split(' ');
               }

               if (_object.attribute === 'Name') {
                    const formName = form.formName;

                    if (typeof formName === 'string') {
                         valuesToCompares = formName.split(' ');
                    }
               }

               if (_object.operator === 'nct') {
                    // Not contain
                    if (valuesToCompares.length === 0) {
                         status = true;
                    } else {
                         valuesToCompares.every((valueToCompare) => {
                              if (valueToCompare) {
                                   status = !valueToCompare.toLowerCase().includes(_object.value.toLowerCase());
                              }

                              return status;
                         });
                    }
               } else {
                    valuesToCompares.some((valueToCompare) => {
                         if (valueToCompare) {
                              switch (_object.operator) {
                                   case 'em': // Exactly matches
                                        status = valueToCompare && valueToCompare === _object.value;
                                        break;
                                   case 'ct': // Contains
                                        status = valueToCompare.toLowerCase().includes(_object.value.toLowerCase());
                                        break;
                                   case 'bw': // Begins with
                                        status = valueToCompare.toLowerCase().startsWith(_object.value.toLowerCase());
                                        break;
                                   case 'ew': // Ends with
                                        status = valueToCompare.toLowerCase().endsWith(_object.value.toLowerCase());
                                        break;
                                   default:
                                        status = false;
                                        break;
                              }
                         }
                         return status;
                    });
               }

               if ((!status && _object.operator !== 'nct') || (status && _object.operator === 'nct')) {
                    if (_object.attribute === 'CSS Class' && form.formClass) {
                         status = checkConditionOperator(form.formClass, _object.value, _object.operator);
                    }

                    if (_object.attribute === 'CSS ID' && form.formId) {
                         status = checkConditionOperator(form.formId, _object.value, _object.operator);
                    }
               }

               if (typeof _object.conjunction !== 'undefined' && _object.conjunction === 'and') {
                    j = j + 1;
                    return status && checkRuleCondition({ condition, j });
               } else if (typeof _object.conjunction !== 'undefined' && _object.conjunction === 'or') {
                    j = j + 1;
                    return checkRuleCondition({ condition, j }) || status;
               }
          }
          return status;
     };

     const checkRuleConditions = ({ conditions, i }) => {
          const status = checkRuleCondition({ condition: conditions[i], j: 0 });

          if (i < conditions.length) {
               if (typeof conditions[i + 1] !== 'undefined' && conditions[i].conjunction && conditions[i].conjunction === 'and') {
                    i = i + 1;

                    return checkRuleConditions({ conditions, i }) && status;
               } else if (typeof conditions[i + 1] !== 'undefined' && conditions[i].conjunction && conditions[i].conjunction === 'or') {
                    i = i + 1;
                    return checkRuleConditions({ conditions, i }) || status;
               }
               return status;
          }
          return status;
     };

     matchRules = checkRuleConditions({ conditions: targetRules, i: 0 });
     return matchRules;
}

export const useMediaQuery = (minWidth) => {
     const [state, setState] = useState({
          windowWidth: window.innerWidth,
          isDesiredWidth: window.innerWidth < minWidth,
     });

     useEffect(() => {
          const resizeHandler = () => {
               const currentWindowWidth = window.innerWidth;
               const isDesiredWidth = currentWindowWidth < minWidth;
               setState({ windowWidth: currentWindowWidth, isDesiredWidth });
          };
          window.addEventListener('resize', resizeHandler);
          return () => window.removeEventListener('resize', resizeHandler);
     }, [state.windowWidth]); //eslint-disable-line

     return state.isDesiredWidth;
};

export const camelCase = (name) => {
     return `${_.camelCase(name)}`;
};
export const filterObjectValuesNotNull = (obj) => {
     const result = {};

     for (const key in obj) {
          const value = obj[key];

          if (value !== null) {
               result[key] = value;
          }
     }

     return result;
};

export const camelCaseCustomProperties = (value) => {
     if (!value) return value;
     return `${camelCase(
          value
               .replace(/\b\w/g, (match) => match.toUpperCase())
               .replace(/^\w/, (match) => match.toLowerCase())
               .replace(/\s+/g, '')
     )}Custom`;
};

export const formatStringtoNumber = (string) => {
     const arr = string.split(',');
     const newArr = [];

     arr.forEach((ar) => {
          let item = ar.replace(/[^\d.,]/g, ''); // Remove the dollar sign
          item = item.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
          newArr.push(item);
     });

     const result = newArr.join(',');
     return result;
};
export const clearConversionDraft = (id) => {
     for (let key in sessionStorage) {
          if (key.startsWith(`conversionDraft_${id}`)) {
               sessionStorage.removeItem(key);
          }
     }
};

export const generateFilterFromNewFilter = ({ filter }) => {
     const newFilterv2 = [];
     for (let i = 0; i < filter.length; i++) {
          const item = filter[i];
          let newItem = {};
          for (const key in item) {
               if (key !== 'conjunction') {
                    newItem = { ...newItem, [key]: item[key] };
                    newFilterv2.push({
                         ...item[key],
                    });
               }
          }
     }
     return newFilterv2;
};

export const generateNewFilterFromOldFilter = ({ filter }) => {
     let newItem = {
          conjunction: '',
     };
     for (let i = 0; i < filter.length; i++) {
          newItem = { ...newItem, [`g${i}`]: { ...filter[i], conjunction: 'and' } };
     }
     return [{ ...newItem }];
};

// export const compareObjectFilter = (arr1, arr2) => {
//      // Kiểm tra xem cả hai mảng có cùng độ dài không
//      if (arr1.length !== arr2.length) return false;

//      // Duyệt qua từng phần tử của mảng và so sánh chúng
//      for (let i = 0; i < arr1.length; i++) {
//           const obj1 = arr1[i];
//           const obj2 = arr2[i];

//           // So sánh các thuộc tính của đối tượng
//           const keys1 = Object.keys(obj1);
//           const keys2 = Object.keys(obj2);

//           // Kiểm tra xem cả hai đối tượng có cùng số lượng thuộc tính không
//           if (keys1.length !== keys2.length) return false;

//           // Duyệt qua từng thuộc tính và so sánh giá trị
//           for (let key of keys1) {
//                if (obj1[key] !== obj2[key]) return false;
//           }
//      }

//      // Nếu không có sự khác biệt nào, trả về true
//      return true;
// };

function formatNumberWithCommas(number) {
     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') !== 'NaN' ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'None';
}

export const formatNumber = (value) => {
     if (typeof value !== 'number') {
          return String(value);
     } else {
          return formatNumberWithCommas(Number(value));
     }
};

export const formattedDate = (date) => {
     return dayjs(date).format('YYYY-MM-DD');
};

export const getShortDomain = (domain) => {
     return domain.replace('https://', '').replace('http://', '').replace(/\/$/, '').replace('www.', '');
};

export const BROWSER_VARIABLES_KEY = {
     localStorage: 'Local Storage',
     sessionStorage: 'Session Storage',
     cookie: 'Cookie Storage',
};

export const RoundFirstSignificantDigit = (num) => {
     function decimalToDigitsArray(decimalNumber) {
          const digitString = decimalNumber.toString().replace('.', '');
          const digitArray = digitString.split('').map(Number);
          return digitArray;
     }
     function getMultiplierNumber(number) {
          const binaryString = parseInt('1' + '0'.repeat(number));
          return binaryString;
     }
     let result = 0;
     const arrNumber = decimalToDigitsArray(num);
     let numberZero = 0;
     if (Math.floor(num) === 0) {
          for (let i = 0; i < arrNumber.length; i++) {
               if (arrNumber[i] !== 0) {
                    numberZero = i;
                    break;
               }
          }
     }
     if (numberZero > 3) {
          result = Math.floor(num * getMultiplierNumber(numberZero)) / getMultiplierNumber(numberZero);
     } else {
          result = Math.round(num * 10000) / 10000;
     }
     return result;
};

export const convertJsonToCSV = (rows) => {
     var processRow = function (row) {
          var finalVal = '';
          for (var j = 0; j < row.length; j++) {
               var innerValue = row[j] === null ? '' : row[j].toString();
               if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString();
               }
               var result = innerValue.replace(/"/g, '""');
               if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
               if (j > 0) finalVal += ',';
               finalVal += result;
          }
          return finalVal + '\n';
     };

     var csvFile = '';

     for (var i = 0; i < rows.length; i++) {
          csvFile += processRow(rows[i]);
     }
     return csvFile;
};

export const getDataExportCompare = ({ compareData, dimensions, metrics }) => {
     const returnRows = [];
     compareData.forEach((row) => {
          Object.entries(row).forEach((value) => {
               let fieldValues = {};
               switch (value[0]) {
                    case 'dimensionCompoundValues':
                         fieldValues = value[1].reduce((prevVal, currentVal, i) => {
                              if (i <= dimensions.length - 1) {
                                   return { ...prevVal, [dimensions[i]]: { value: currentVal.value } };
                              } else {
                                   return {};
                              }
                         }, {});
                         returnRows.push(fieldValues);
                         break;
                    case 'metricCompoundValues':
                         Object.entries(value[1]).forEach((item) => {
                              let dateValue = {};
                              dimensions.forEach((dimension, index) => {
                                   if (index === 0) {
                                        dateValue = { ...dateValue, ...{ [dimension]: { value: JSON.stringify(item[0]) } } };
                                   } else {
                                        dateValue = { ...dateValue, ...{ [dimension]: { value: '' } } };
                                   }
                              });
                              fieldValues = item[1].reduce((prevVal, currentVal, i) => {
                                   if (i <= metrics.length - 1) {
                                        if (metrics[i].includes('Rate')) {
                                             return {
                                                  ...prevVal,
                                                  [metrics[i]]: { value: divideBy100(currentVal.value) },
                                             };
                                        } else {
                                             return { ...prevVal, [metrics[i]]: { value: currentVal.value } };
                                        }
                                   } else {
                                        return {};
                                   }
                              }, {});
                              if (Object.entries(fieldValues).length === 0) {
                                   metrics.forEach((metric) => {
                                        fieldValues = { ...fieldValues, ...{ [metric]: { value: 0 } } };
                                   });
                              }
                              returnRows.push({ ...dateValue, ...fieldValues });
                         });
                         break;
                    default:
                         break;
               }
          });
     });

     return returnRows;
};
const divideBy100 = (value) => {
     // Chuyển đổi giá trị thành chuỗi để xử lý
     const strValue = value.toString();

     // Tìm vị trí của dấu thập phân
     const decimalIndex = strValue.indexOf('.');

     // Nếu không có dấu thập phân, chỉ cần chia trực tiếp và trả về kết quả
     if (decimalIndex === -1) {
          return (value / 100).toString();
     }
     const splitString = (inputString) => {
          let mainPart = inputString.slice(0, -2); // all except the last two characters
          let endPart = inputString.slice(-2); // the last two characters
          return { mainPart, endPart };
     };
     // Tính toán phần nguyên và phần thập phân
     const integerPart = strValue.slice(0, decimalIndex);
     const { mainPart, endPart } = splitString(integerPart);
     const decimalPart = strValue.slice(decimalIndex + 1);

     // Kết hợp phần nguyên và phần thập phân, rồi chia cho 100
     const result = parseFloat(mainPart + '.' + endPart + decimalPart);

     // Trả về kết quả dưới dạng chuỗi với độ chính xác cố định
     return Number(result.toFixed(20)); // Điều chỉnh số chữ số thập phân theo nhu cầu của bạn
};

export const getMetrics = ({ segments, metrics }) => {
     let newMetrics = [];

     if (segments && segments.length > 0) {
          metrics.forEach((metric) => {
               segments.forEach((segment) => {
                    newMetrics.push(`${getSegmentField(segment.id, metric)}`);
               });
          });
     } else {
          newMetrics = [...metrics];
     }

     return newMetrics;
};
export const getHeaderReport = ({ headerColumn, segments, filterFields, isCompare, newDateRange }) => {
     const headerSegment = [];
     const nameHeader = [];
     const annotationRow = [];
     let metrics = isCompare ? filterFields.metrics.flatMap((item) => [item, `${item}_1`]) : filterFields.metrics;
     let newHeader = [...headerColumn];
     let compareDay = [];
     const compareDayRow = [];
     if (isCompare) {
          compareDay.push(
               `${dayjs(newDateRange.selection.startDate).format('MMM D, YYYY')} - ${dayjs(newDateRange.selection.endDate).format('MMM D, YYYY')}`,
               `${dayjs(newDateRange.compare.startDate).format('MMM D, YYYY')} - ${dayjs(newDateRange.compare.endDate).format('MMM D, YYYY')}`
          );
     }
     headerColumn.forEach((element, index) => {
          if (segments && segments.length > 0) {
               if (filterFields.dimensions.find((el) => el === element.value)) {
                    nameHeader.push(element.label);
                    headerSegment.push('');
                    annotationRow.push('');
                    compareDayRow.push('');
               }
               if (filterFields.properties.find((el) => el === element.value && element.value !== 'viewDetail')) {
                    nameHeader.push(element.label);
                    headerSegment.push('');
                    annotationRow.push('');
                    compareDayRow.push('');
               }
          } else if (element.value !== 'viewDetail') {
               nameHeader.push(element.label);
               if (isCompare && filterFields.metrics.includes(element.value)) {
                    const indexCompare = newHeader.findIndex((item) => item.value === element.value);

                    nameHeader.push(element.label);

                    compareDay.forEach((day, index) => {
                         if (!annotationRow.some((row) => row.includes(day))) {
                              annotationRow.push(`D${index + 1}: ${day}`);
                         }
                         compareDayRow.push(`D${index + 1}`);
                    });

                    if (indexCompare !== -1) {
                         newHeader.splice(indexCompare + 1, 0, { label: element.label, value: `${element.value}_1` });
                    }
               } else {
                    annotationRow.push('');
                    compareDayRow.push('');
               }
          }
     });

     if (segments && segments.length > 0) {
          newHeader.forEach((header) => {
               segments.forEach((segment, index) => {
                    if (metrics.find((el) => el === header.value)) {
                         nameHeader.push(header.label);
                         newHeader.push({ label: header.label, value: `${getSegmentField(segment.id, header.value)}` });

                         if (annotationRow.filter((element) => element === 'Metrics').length !== index + 1) {
                              compareDay.forEach((day, index) => {
                                   if (!annotationRow.some((row) => row.includes(day))) {
                                        annotationRow.push(`D${index + 1}: ${day}`);
                                   }
                                   compareDayRow.push(`D${index + 1}`);
                              });
                              if (!annotationRow.some((el) => el === `S${index + 1}:${segment.name}`)) {
                                   annotationRow.push(`S${index + 1}:${segment.name}`);
                              }
                              headerSegment.push(`S${index + 1}`);
                         } else {
                              annotationRow.push('');
                              headerSegment.push('');
                         }
                         if (isCompare) {
                              newHeader.push({ label: header.label, value: `${getSegmentField(segment.id, header.value)}_1` });
                              nameHeader.push(header.label);
                              headerSegment.push('');
                         }
                    }
               });
          });
          newHeader = newHeader.filter((header) => header.value !== metrics.find((el) => el === header.value));
     }

     return { headerSegment, nameHeader, annotationRow, newHeader, compareDayRow };
};
export const exportVisibleReport = ({
     data,
     filterFields,
     newProperties,
     newMetric,
     headerColumn,
     reportName,
     reportCompare,
     isCompare,
     reportType,
     isCustom = false,
     segments,
     newDateRange,
}) => {
     let dataReport = [...data.rows];
     const indexFilter = {
          indexMetric: [],
          indexProperties: [],
     };
     const fieldReport = { ...filterFields };
     const propertyType = isCustom ? `propertiesCompoundValues` : `propertyCompoundValues`;
     // remove metrics and properties with old data
     let metrics = segments && segments.length > 0 ? getMetrics({ segments, metrics: newMetric }) : newMetric;
     let properties = newProperties;
     if (dataReport && dataReport.length > 0 && dataReport[0].metricCompoundValues.length < metrics.length) {
          // reload api call temp report
          metrics = segments && segments.length > 0 ? getMetrics({ segments, metrics: filterFields.metrics }) : filterFields.metrics;
     }
     if (dataReport && dataReport.length > 0 && dataReport[0][propertyType].length < properties.length) {
          // reload api call temp report
          properties = filterFields.properties;
     }
     if (segments && segments.length > 0) {
          fieldReport.metrics = getMetrics({ segments, metrics: filterFields.metrics });
     }
     if (isCompare) {
          metrics = metrics.flatMap((item) => [item, `${item}_1`]);
          fieldReport.metrics = fieldReport.metrics.flatMap((item) => [item, `${item}_1`]);
     }
     fieldReport.metrics.forEach((item) => {
          const index = metrics.findIndex((el) => el === item);
          indexFilter.indexMetric.push(index);
     });
     fieldReport.properties.forEach((item) => {
          const index = properties.findIndex((el) => el === item);
          indexFilter.indexProperties.push(index);
     });
     console.log('fieldReport', fieldReport);
     let csvString = '';
     const stringLimit = 32000;
     const handleConvertDataExport = (returnRows) => {
          let { headerSegment, nameHeader, annotationRow, newHeader, compareDayRow } = getHeaderReport({
               headerColumn,
               segments,
               filterFields,
               isCompare,
               newDateRange,
          });
          console.log('nameHeader', nameHeader);
          let newHeaderColumn = newHeader.filter((item) => item.value !== 'viewDetail');
          if (returnRows && returnRows.length > 0 && segments && segments.length > 0) {
               segments.forEach((segment) => {
                    const newArraySegment = [];

                    returnRows.forEach((data) => {
                         for (const key in data) {
                              if (data.hasOwnProperty(key) && key.includes(camelCase(segment.id))) {
                                   newArraySegment.push({ [key]: data[key] });
                              }
                         }
                    });
                    // const arrayNoData =
                    //      newArraySegment.length > 0 &&
                    //      newArraySegment.every(
                    //           (obj) =>
                    //                Object.values(obj)[0].value === 0 ||
                    //                Object.values(obj)[0].value === '0' ||
                    //                Object.values(obj)[0].value === 'None' ||
                    //                Object.values(obj)[0].value === '0m 0s'
                    //      );
                    // if (arrayNoData) {
                    //      newHeaderColumn = newHeaderColumn.filter((item) => !item.value.includes(camelCase(segment.name)));
                    // }
               });
               if (newHeaderColumn.length < headerSegment.length) {
                    headerSegment = headerSegment.filter((item, index) => index < newHeaderColumn.length);
                    annotationRow = annotationRow.filter((item, index) => index < newHeaderColumn.length);
                    nameHeader = nameHeader.filter((item, index) => index < newHeaderColumn.length);
                    compareDayRow = compareDayRow.filter((item, index) => index < newHeaderColumn.length);
               }
          }
          const newData = [];
          if (segments && segments.length > 0) {
               newData.push(annotationRow, headerSegment, nameHeader);
               if (isCompare) {
                    newData.splice(2, 0, compareDayRow);
               }
          } else {
               if (isCompare) {
                    newData.push(annotationRow, compareDayRow);
               }
               newData.push(nameHeader);
          }

          returnRows.forEach((element) => {
               // const arr = Object.values(element);
               const newItem = [];
               // arr.forEach((item) => {
               //      newItem.push(item.value);
               // });
               newHeaderColumn.forEach((head) => {
                    const exitedHeader = Object.keys(element).find((key) => head.value === key);
                    if (exitedHeader && element[exitedHeader]) {
                         newItem.push(element[exitedHeader].value);
                    } else if (!reportType) {
                         newItem.push('None');
                    } else {
                         newItem.push('');
                    }
               });

               newData.push(newItem);
          });

          return newData;
     };
     const newResponse = [];
     const newProperty = filterFields.properties.filter((item) => item !== 'viewDetail');
     const findViewProfile = filterFields.properties.some((item) => item === 'viewDetail');
     if (reportName === REPORT_NAMES.FORM_CATEGORY_REPORT) {
          dataReport = dataReport.filter(
               (row) =>
                    (row.metricCompoundValues && row.metricCompoundValues[0] && row.metricCompoundValues[0].value !== 'undefine') ||
                    (row[propertyType] && findViewProfile
                         ? row[propertyType][1] && row[propertyType][1].value !== 'undefine'
                         : row[propertyType][0] && row[propertyType][0].value !== 'undefine')
          );
     }
     dataReport.forEach((row) => {
          if (
               !isCompare &&
               ((row && row.metricCompoundValues && row.metricCompoundValues[0] && row.metricCompoundValues[0].value !== 'undefine') ||
                    indexFilter.indexMetric.length === 0)
          ) {
               const newRow = { ...row };
               newRow.metricCompoundValues = newRow.metricCompoundValues.filter((el, index) => indexFilter.indexMetric.includes(index));
               newRow[`${propertyType}`] = newRow[`${propertyType}`].filter((el, index) => indexFilter.indexProperties.includes(index));
               return newResponse.push(newRow);
          } else if (isCompare) {
               const newRow = { ...row };
               newRow.metricCompoundValues = newRow.metricCompoundValues.filter((el, index) => indexFilter.indexMetric.includes(index));
               newRow[`${propertyType}`] = newRow[`${propertyType}`].filter((el, index) => indexFilter.indexProperties.includes(index));
               return newResponse.push(newRow);
          }
     });

     let returnRows = [];
     // if (isCompare) {
     //      returnRows = getDataExportCompare({
     //           compareData: newResponse,
     //           dimensions: fieldReport.dimensions,
     //           metrics: metrics.filter((item) => fieldReport.metrics.includes(item)),
     //      });
     // } else {
     const metricCompoundValues = metrics.filter((item) => fieldReport.metrics.includes(item));
     returnRows = newResponse.map((row) =>
          Object.entries(row).reduce((newRow, [compoundKey, compoundValue]) => {
               let fieldValues = [];
               let keyCompound;

               switch (compoundKey) {
                    case 'dimensionCompoundValues':
                         keyCompound = fieldReport.dimensions;
                         break;
                    case 'metricCompoundValues':
                         keyCompound = metricCompoundValues;
                         break;
                    case `${propertyType}`:
                         keyCompound = newProperty;
                         break;
                    default:
               }
               // remove profile
               if (
                    compoundKey === `${propertyType}` &&
                    findViewProfile &&
                    (compoundValue[0].value === 'View' ||
                         (compoundValue[0].value === '' && reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION))
               ) {
                    compoundValue.shift();
               }
               // Merge all compoundValues into 1 object

               fieldValues = compoundValue.reduce((prevVal, currentVal, i) => {
                    if (i <= keyCompound.length - 1) {
                         // let personId = {};
                         let newCurrentVal = currentVal;
                         // keyCompound[i] === 'personCreatedCount' && (personId = { personId: newCurrentVal });
                         if (
                              getMetrics({
                                   segments,
                                   metrics: isCompare
                                        ? ['sessionDuration', 'avgSessionDuration', 'avgVisibleTimePerPage', 'avgVisibleTimeOnPage'].flatMap(
                                             (item) => [item, `${item}_1`]
                                        )
                                        : ['sessionDuration', 'avgSessionDuration', 'avgVisibleTimePerPage', 'avgVisibleTimeOnPage'],
                              }).includes(keyCompound[i]) &&
                              typeof currentVal.value === 'number'
                         ) {
                              newCurrentVal = { value: convertReportDuration(currentVal.value) };
                         }
                         if (keyCompound[i].includes('Rate')) {
                              newCurrentVal = { value: divideBy100(currentVal.value) };
                         }

                         if (
                              (keyCompound[i] === 'userSourceFirst' ||
                                   keyCompound[i] === 'userSourceLast' ||
                                   keyCompound[i] === 'userSource' ||
                                   keyCompound[i] === 'userSourceFirstPersonCreated' ||
                                   keyCompound[i] === 'userSourceLastPersonCreated') &&
                              reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT
                         ) {
                              if (currentVal.value) {
                                   if (typeof currentVal.value.name !== 'undefined') {
                                        newCurrentVal = { value: currentVal.value.name };
                                   } else {
                                        newCurrentVal = { value: currentVal.value };
                                   }
                              } else {
                                   newCurrentVal = { value: 'none' };
                              }
                         }
                         if (
                              (keyCompound[i] === 'userSourcePath' ||
                                   keyCompound[i] === 'noneDirectSourcePath' ||
                                   keyCompound[i] === 'userSourcePathPersonCreated') &&
                              compoundKey === `${propertyType}`
                         ) {
                              const listSourceName = [];
                              if (Array.isArray(currentVal.value)) {
                                   currentVal.value.map((source) => {
                                        listSourceName.push(source.name);
                                        return source.name;
                                   });
                              } else {
                                   listSourceName.push(currentVal.value.name);
                              }
                              newCurrentVal = { value: listSourceName.toString() };
                         }

                         if (keyCompound[i] === 'employees' && currentVal.value !== 'none') {
                              newCurrentVal = { value: currentVal.value + ' employees' };
                         }

                         if (newCurrentVal.value && newCurrentVal.value.length > 32000) {
                              let newkeyCompound = {};
                              const lastIndex = Math.ceil(newCurrentVal.value.length / stringLimit);
                              let indexof;
                              const head = headerColumn.find((item, index) => {
                                   indexof = index;
                                   return item.value === `${keyCompound[i]}`;
                              });
                              for (let j = 0; j < lastIndex; j++) {
                                   if (head && j !== lastIndex - 1) {
                                        const newColumn = { label: `${head.label}_${j}`, value: `${head.value}_${j}` };
                                        if (!headerColumn.find((head) => head.value === newColumn.value)) {
                                             headerColumn.splice(indexof + (j + 1), 0, newColumn);
                                        }
                                   }
                                   let newCurrentValSplit = {
                                        value: `${newCurrentVal.value.toString().substr(j * stringLimit, stringLimit)}`,
                                   };
                                   newkeyCompound = {
                                        ...newkeyCompound,
                                        [`${keyCompound[i]}${j === 0 ? `` : `_${j - 1}`}`]: newCurrentValSplit,
                                   };
                              }
                              return { ...prevVal, ...newkeyCompound };
                         }
                         return { ...prevVal, [keyCompound[i]]: newCurrentVal };
                    } else {
                         return { ...prevVal };
                    }
               }, {});
               if (compoundKey === 'metricCompoundValues') {
                    let newFieldValues = {};
                    metricCompoundValues.forEach((item) => {
                         newFieldValues = { ...newFieldValues, [item]: fieldValues[item] };
                    });
                    fieldValues = { ...newFieldValues };
               }
               return { ...newRow, ...fieldValues };
          }, {})
     );
     // }
     const valueFormatCSV = handleConvertDataExport(returnRows);
     csvString = convertJsonToCSV(valueFormatCSV);
     return csvString;
};
export const exportVisibleCustomReport = ({
     data,
     filterFields,
     headerColumn,
     reportName,
     reportCompare,
     isCompare,
     reportType,
     isCustom = false,
     report,
     properties,
}) => {
     const dataReport = isCompare ? [...reportCompare.rows] : [...data.rows];
     const newDimensions = [];
     const newProperties = [];
     const newMetrics = [];
     report.dimensions.forEach((item) => {
          if (filterFields.dimensions.find((el) => el === camelCase(item.name))) {
               newDimensions.push(camelCase(item.name));
          }
     });
     report.properties.forEach((item) => {
          if (filterFields.properties.find((el) => el === item.value)) {
               newProperties.push(item.value);
          }
     });
     properties.forEach((item) => {
          if (filterFields.properties.find((el) => el === item.key) && !newProperties.find((el) => el === item.key)) {
               newProperties.push(item.key);
          }
     });

     report.metrics.forEach((item) => {
          if (filterFields.metrics.find((el) => el === camelCase(item.name))) {
               newMetrics.push(camelCase(item.name));
          }
     });
     const indexFilter = {
          indexMetric: [],
          indexProperties: [],
     };
     filterFields.metrics.forEach((item) => {
          const index = newMetrics.findIndex((el) => el === item);
          indexFilter.indexMetric.push(index);
     });
     filterFields.properties.forEach((item) => {
          const index = newProperties.findIndex((el) => el === item);
          indexFilter.indexProperties.push(index);
     });
     let csvString = '';
     const stringLimit = 32000;
     let headerCustom = [];
     if (filterFields.dimensions && filterFields.dimensions.length > 0) {
          filterFields.dimensions.forEach((d) => headerCustom.push(d));
     }
     if (filterFields.properties && filterFields.properties.length > 0) {
          filterFields.properties.forEach((p) => headerCustom.push(p));
     }
     if (filterFields.metrics && filterFields.metrics.length > 0) {
          filterFields.metrics.forEach((m) => headerCustom.push(m));
     }
     const handleConvertDataExport = (returnRows) => {
          const newHeader = [];
          headerColumn.forEach((element) => {
               newHeader.push(element.label);
          });
          const newData = [];
          newData.push(newHeader);
          returnRows.forEach((element) => {
               // const arr = Object.values(element);
               const newItem = [];
               // arr.forEach((item) => {
               //      newItem.push(item.value);
               // });
               headerCustom.forEach((head) => {
                    const exitedHeader = Object.keys(element).find((key) => head === key);
                    if (exitedHeader) {
                         if (typeof element[exitedHeader].value === 'undefined') {
                              newItem.push('');
                         } else {
                              newItem.push(element[exitedHeader].value);
                         }
                    } else {
                         newItem.push('None');
                    }
               });
               newData.push(newItem);
          });
          return newData;
     };

     let returnRows = dataReport.map((row) =>
          Object.entries(row).reduce((newRow, [compoundKey, compoundValue]) => {
               let fieldValues = [];
               let keyCompound;

               switch (compoundKey) {
                    case 'dimensionCompoundValues':
                         keyCompound = newDimensions;
                         break;
                    case 'metricCompoundValues':
                         keyCompound = newMetrics;
                         break;
                    case 'propertiesCompoundValues':
                         keyCompound = newProperties;
                         break;
                    default:
               }
               // Merge all compoundValues into 1 object
               fieldValues = compoundValue.reduce((prevVal, currentVal, i) => {
                    if (i <= keyCompound.length - 1) {
                         let personId = {};
                         let newCurrentVal = currentVal;

                         if (
                              keyCompound[i] === 'userSourceFirst' ||
                              keyCompound[i] === 'userSourceLast' ||
                              keyCompound[i] === 'userSource' ||
                              keyCompound[i] === 'userSourceFirstPersonCreated' ||
                              keyCompound[i] === 'userSourceLastPersonCreated'
                         ) {
                              if (currentVal.value) {
                                   if (typeof currentVal.value === 'string') {
                                        newCurrentVal = { value: currentVal.value };
                                   } else {
                                        newCurrentVal = { value: currentVal.value.name };
                                   }
                              } else {
                                   newCurrentVal = { value: 'none' };
                              }
                         }
                         if (
                              keyCompound[i] === 'userSourcePath' ||
                              keyCompound[i] === 'noneDirectSourcePath' ||
                              keyCompound[i] === 'userSourcePathPersonCreated'
                         ) {
                              const listSourceName = [];
                              if (typeof currentVal.value === 'string') {
                                   listSourceName.push(currentVal.value);
                              } else {
                                   currentVal.value.map((source) => {
                                        listSourceName.push(source.name);
                                        return source.name;
                                   });
                              }
                              newCurrentVal = { value: listSourceName.toString() };
                         }

                         if (keyCompound[i] === 'employees' && currentVal.value !== 'none') {
                              newCurrentVal = { value: currentVal.value + ' employees' };
                         }
                         if (newCurrentVal.value && newCurrentVal.value.length > 32000) {
                              let newkeyCompound = {};
                              const lastIndex = Math.ceil(newCurrentVal.value.length / stringLimit);
                              let indexof;
                              const head = headerCustom.find((item, index) => {
                                   indexof = index;
                                   return item === `${keyCompound[i]}`;
                              });
                              for (let j = 0; j < lastIndex; j++) {
                                   if (head && j !== lastIndex - 1) {
                                        const newColumn = {
                                             label: headerColumn[indexof].label + '_' + j,
                                             value: headerColumn[indexof].value + '_' + j,
                                        };
                                        const newColCus = head + '_' + j;
                                        if (!headerCustom.find((head) => head === newColCus)) {
                                             headerColumn.splice(indexof + (j + 1), 0, newColumn);
                                             headerCustom.splice(indexof + (j + 1), 0, newColCus);
                                        }
                                   }
                                   let newCurrentValSplit = { value: `${newCurrentVal.value.toString().substr(j * stringLimit, stringLimit)}` };
                                   newkeyCompound = { ...newkeyCompound, [`${keyCompound[i]}${j === 0 ? `` : `_${j - 1}`}`]: newCurrentValSplit };
                              }
                              return { ...prevVal, ...newkeyCompound, ...personId };
                         }
                         return { ...prevVal, [keyCompound[i]]: newCurrentVal, ...personId };
                    } else {
                         return {};
                    }
               }, {});
               return { ...newRow, ...fieldValues };
          }, {})
     );
     const valueFormatCSV = handleConvertDataExport(returnRows);
     csvString = convertJsonToCSV(valueFormatCSV);
     return csvString;
};

export const getSegmentField = (segmentId, field) => {
     return `${camelCase(segmentId)}_${field}`;
};

export const getOriginalField = (field) => {
     return field.split('_').length > 1 ? field.split('_')[1] : field;
};

export const getURLImg = (alias) => {
     switch (alias) {
          case 'microsoft-ads':
               return `/assets/icons/ms-advertising-ads/icon-color.svg`;
          case 'linkedin-ads':
               return `/assets/icons/linkedIn-ads/icon-color.svg`;
          default:
               return `/assets/icons/${alias}/icon-color.svg`;
     }
};

export const escapeRegExp = (string) => {
     return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
};

export const extractDomain = (input) => {
     const url = input.includes("://") ? input : `http://${input}`;
     const domain = new URL(url).hostname;
     return domain.startsWith("www.") ? domain.slice(4) : domain;
}
