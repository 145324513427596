import { CModal, CModalBody } from '@coreui/react';
import PropTypes from 'prop-types';
import React from 'react';

const StillWorkingPopup = ({ show, children }) => {
     return (
          <div className='still-working-popup'>
               <CModal visible={show} color='primary' alignment='center' portal={false} >
                    <CModalBody>
                         <div className='blue-notice'>
                              {children ? (
                                   children
                              ) : (
                                   <>
                                        <h4>We're still working...</h4>
                                        <p>
                                             We’re still checking your domain to make sure it’s accessible. Please be patient! This screen will change as soon as we are
                                             done, please don’t navigate away!
                                        </p>
                                   </>
                              )}
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

StillWorkingPopup.propTypes = {
     show: PropTypes.bool,
};

export default StillWorkingPopup;
