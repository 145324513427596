import React, { useEffect, useState } from 'react';
import CenterSpinner from '../../../../../../../general/Loadings/CenterSpinner';
import SelectAccountSalesforce from '../Step/SelectAccountSalesforce';
import NameAndDescriptionSalesforce from '../Step/NameAndDescriptionSalesforce';
import ConnectionSettingsSalesforce from '../Step/ConnectionSettingsSalesforce';
import ObjectsToImportSalesforce from '../Step/ObjectsToImportSalesforce';
import { API_SALESFORCE_CONNECTION } from '../../../../../../../../constants';
import { callSalesforceApi } from '../../../../../../../../apiCaller';
import { toast } from 'react-toastify';

export const SalesforceDetailDetailContext = React.createContext({});

const SalesforceConnectAccount = ({ id }) => {
     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState({});
     const [initStepsData, setInitStepsData] = useState({});

     useEffect(() => {
          if (id) {
               callSalesforceApi(`${API_SALESFORCE_CONNECTION}/${id}`, 'GET')
                    .then((response) => {
                         if (response && response.status === 200) {
                              setStepsData(response.data.data);
                              setInitStepsData(response.data.data);
                         } else {
                              toast.error('Get List Connection Salesforce Fail!');
                         }
                    })
                    .finally(() => {
                         setIsLoading(false);
                    });
          }
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail account-connected-detail">
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new">
                              <h1>{id ? `Edit: Salesforce Connection: ${stepsData.name ? stepsData.name : ""}` : `Create: Salesforce Connection`}</h1>
                              <p>Configure a connection to Salesforce CRM by completing the steps below.</p>
                              <SalesforceDetailDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                   }}
                              >
                                   <SelectAccountSalesforce />
                                   <NameAndDescriptionSalesforce idEdit={id} />
                                   <ConnectionSettingsSalesforce idEdit={id} />
                                   <ObjectsToImportSalesforce idEdit={id} />
                              </SalesforceDetailDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default SalesforceConnectAccount;
