import React, { useContext, useEffect, useRef, useState } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { DEFAULT_TEMP_DOMAINS, FIND_ACCOUNT_COMPANY_FIELD } from '../constants';
import { TableCellDefault, TableCellValueUndefined, Tag } from '../../../analytics/report-v2/FieldCustom';
import { findLongestValue, formatNumber } from '../../../../../../utils/UtilReport';
import { setFlexibleMultiModal } from '../../../../../../actions/common';
import { useDispatch, useSelector } from 'react-redux';
import { COMPONENT_NAME } from '../../../../../../constants';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { FindAccountContext } from '.';
import { LoadingPanel } from '../../../analytics/report-v2/LoadingTemplate';
import PaginationReport from '../../../analytics/report-v2/grid/PaginationReport';
import useFetchData from './useFetchData';
import { ConfirmSaveChange } from '../../../../../general/popup';
import { TargetAccountAPI } from '../actions/apis';
import { setSubscriberState } from '../../../../../../actions/admin';
import { CInputCheckbox } from '../../../../../migration/CInput';
import { generateId } from '../../../../../../utils';


const CompanyTableInfo = () => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const targetAccountsList = useSelector((state) => state.subscriber.targetAccountsList);
     const { isLoading, setIsLoading, filterEvent, setConditionValue, activePage, setActivePage, offSet, setOffSet, add, setAdd, addAll, setAddAll } = useContext(FindAccountContext);

     const [companyData, setCompanyData] = useState([]);
     const [saveLoading, setSaveLoading] = useState(false);
     const [overallRowCount, setOverallRowCount] = useState(0);
     const [itemsPerPage, setItemsPerPage] = useState(20);

     const [pagination, setPagination] = useState(true);
     const totalPages = Math.ceil(overallRowCount / itemsPerPage) || 1;
     const [toggleStatusModal, setToggleStatusModal] = useState(false);
     const [isFetchData, setIsFetchData] = useState(false)

     useFetchData({
          filterEvent,
          setIsLoading,
          itemsPerPage,
          activePage,
          setActivePage,
          offSet,
          setCompanyData,
          setOverallRowCount,
          isFetchData
     });


     function CustomHeaderCell({ props }) {
          const { field, title } = props;

          if (field === 'add') {
               const rowId = generateId()
               return (
                    <span className={`btn-add check-box ${!!addAll ? 'checked' : ''}`}>
                         <CInputCheckbox
                              id={`inline-checkbox-${rowId}`}
                              value={rowId}
                              checked={!!addAll}
                              onChange={(e) => {
                                   handleAddAll(e.target.checked)
                              }}
                         />
                         <label htmlFor={`inline-checkbox-${rowId}`}></label>
                    </span>
               )
          }

          return (
               <span className="k-cell-inner table-head">
                    <span className="k-link">
                         <span>{title}</span>
                    </span>
               </span>
          );
     }

     const onClickShowDetailCompany = (linkedData) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: linkedData.companyId },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const changeAddCheckbox = (checked, value) => {
          let newAdd
          if (checked) {
               newAdd = [...add, {
                    companyId: value.companyId,
                    domain: value.domain,
                    isBO: false,
                    actionType: 'add',
                    companyData: value.companyData
               }]
          } else {
               newAdd = add.filter((item) => item.companyId !== value.companyId)
          }

          setAdd(newAdd)
          setAddAll(newAdd && newAdd.length === companyData.length)
     }

     useEffect(() => {
          const currentDataAdd = add.filter((item) => companyData.find((currentPage) => currentPage.companyId === item.companyId))
          setAddAll(currentDataAdd.length === 0 && companyData.length === 0 ? false : currentDataAdd.length === companyData.length)

     }, [companyData]) // eslint-disable-line

     const handleAddAll = (checked) => {
          setAddAll(checked);
          if (checked) {
               const data = companyData.map((item) => {
                    return {
                         companyId: item.companyId,
                         domain: item.domain,
                         isBO: false,
                         actionType: 'add',
                         companyData: item.companyData
                    }
               })
               setAdd(prev => [...prev, ...data])
          }
          else {
               setAdd((prev) => {
                    return prev.filter((item) => !companyData.find((currentPage) => currentPage.companyId === item.companyId))
               })
          }

     }

     function CustomCell(props) {
          const refCell = useRef(null);
          let value = props.dataItem[props.field];
          const { style, className, id } = props;

          const addChecked = add.find((item) => item.companyId === props.dataItem.companyId)

          if (value === undefined && !['viewDetail', 'add'].includes(props.field)) {
               return TableCellValueUndefined(isLoading);
          }

          if (value === null || value === "") {
               value = 'None'
          }

          switch (props.field) {
               case 'productServiceTag':
                    const productServiceTags = Array.isArray(value) ? value : [];
                    if (!Array.isArray(value) || (value && value.length === 0) || (value && value.length === 1 && value[0] === 'None')) {
                         return (
                              <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                                   <span>None</span>
                              </td>
                         );
                    }

                    return (
                         <td {...{ style, className }} className={`view-detail list-tags ${className}`} tabIndex={0} data-keyboardnavid={id}>
                              <div className="tags-wrapper">
                                   {productServiceTags.map((item, index) => (
                                        <Tag key={index} item={item} />
                                   ))}
                              </div>
                         </td>
                    );
               case 'viewDetail':
                    return (
                         <td
                              style={style}
                              className={`${className} view-detail`}
                              tabIndex={0}
                              data-keyboardnavid={id}
                              onClick={() => onClickShowDetailCompany({ ...props.dataItem })}
                         >
                              <span>
                                   view
                              </span>
                         </td>
                    );
               case 'add':
                    const rowId = generateId()
                    return (
                         <td
                              style={style}
                              className={`${className} btn-add check-box ${!!addChecked ? 'checked' : ''}`}
                              tabIndex={0}
                              data-keyboardnavid={id}
                         >
                              <CInputCheckbox
                                   id={`inline-checkbox${rowId}`}
                                   value={rowId}
                                   checked={!!addChecked}
                                   onChange={(e) => {
                                        changeAddCheckbox(e.target.checked, props.dataItem)
                                   }}
                              />
                              <label htmlFor={`inline-checkbox${rowId}`}></label>
                         </td>
                    );
               case 'yearFounded':
                    return (
                         <td {...{ style, className }} tabIndex={0} data-keyboardnavid={id}>
                              <span>{String(value)}</span>
                         </td>
                    );

               default:
                    return TableCellDefault(value, props, refCell);
          }
     }

     function getWidthByKey({ label, key }) {
          const longestValue = companyData ? findLongestValue(companyData) : undefined;

          let widthDefault = label.length * 7.2 + 65;

          if (longestValue && longestValue[key] && longestValue[key].length * 6 + 50 > widthDefault) {
               return Math.min(575, longestValue[key].length * 7 + 50);
          }

          return widthDefault;
     }

     const onPaginationChange = (i) => {
          setOffSet((i - 1) * itemsPerPage);
          setActivePage(i);
     };

     const onChangeItemsPerPage = (e) => {
          setActivePage(1);
          setOffSet(0);
          setItemsPerPage(+e.target.value);
     };

     const handleChangePagination = () => {
          setPagination(!pagination);
          setItemsPerPage(20);
          if (pagination) setActivePage(1);
          setOffSet(0);
     };

     const onAccept = async () => {
          const payload = {
               accountId: activeAccount.id,
               targetAccountEdits: add
          }
          setSaveLoading(true);
          const result = await TargetAccountAPI.update(payload);

          //Set new target account list
          const newTargetAccounts = [...targetAccountsList];
          if (targetAccountsList.label === 20) {
               newTargetAccounts.pop();
          }
          const newResult = result.map((item) => {
               return {
                    ...item,
                    totalCount: (targetAccountsList[0] && targetAccountsList[0].targetAccountsList) || 1
               }
          })
          newTargetAccounts.unshift(...newResult);

          const finalTargetAccounts = newTargetAccounts.length ? newTargetAccounts : DEFAULT_TEMP_DOMAINS;
          dispatch(setSubscriberState({ targetAccountsList: finalTargetAccounts }));


          setSaveLoading(false);

          if (!result) return;

          setToggleStatusModal(false);


          if (add.length === companyData.length) {
               const newPage = activePage - 1;
               setActivePage(newPage < 1 ? 1 : newPage)
               setOffSet(newPage < 1 ? 0 : (newPage - 1) * itemsPerPage);
               setIsFetchData(pre => !pre)
          } else {
               setIsFetchData(pre => !pre)
          }

          setConditionValue(prev => {
               return {
                    ...prev,
                    domain: prev.domain.filter((domain) => !add.find((addItem) => addItem.domain === domain))
               }
          })

          setAddAll(false)
          setAdd([])
     }

     return (
          <>
               <div className='d-flex justify-content-between align-items-center my-4'>
                    <h6>
                         <CIcon name="iconBuildingsSolid" height={15} width={15} />
                         <span style={{ fontSize: '15px', marginLeft: '5px' }}> {formatNumber(overallRowCount)} Companies Found</span>
                    </h6>
                    <div>
                         <CButton
                              color="success"
                              onClick={() => add.length && setToggleStatusModal(true)}
                              disabled={add.length === 0}
                         >
                              Save Change
                         </CButton>
                    </div>
               </div>
               <div>
                    {isLoading && <LoadingPanel />}
                    <Grid
                         // ref={tableGrid}
                         style={{ width: 'fit-content', maxWidth: '100%', maxHeight: '800px' }}
                         data={companyData.length === 0 && isLoading ? Array(5).fill({}) : companyData}
                         scrollable="scrollable"
                    // rowRender={isLoading && SkeletonLoader}
                    >
                         <GridNoRecords>No company available!</GridNoRecords>
                         {FIND_ACCOUNT_COMPANY_FIELD.map((item, index) => (
                              <GridColumn
                                   key={index}
                                   title={item.label}
                                   field={item.key}
                                   width={getWidthByKey({
                                        label: item.label,
                                        key: item.key
                                   })}
                                   headerCell={(props) => (
                                        <CustomHeaderCell
                                             props={props}
                                        />
                                   )}
                                   cell={(props) => CustomCell(props)}
                              />
                         ))}
                    </Grid>

                    {overallRowCount > 0 && (
                         <PaginationReport
                              pagination={pagination}
                              activePage={activePage}
                              totalPages={totalPages}
                              onPaginationChange={onPaginationChange}
                              onChangeItemsPerPage={onChangeItemsPerPage}
                              itemsPerPage={itemsPerPage}
                              handleChangePagination={handleChangePagination}
                         />
                    )}

               </div>
               <ConfirmSaveChange
                    show={toggleStatusModal}
                    onAccept={onAccept}
                    onClose={() => {
                         setToggleStatusModal(false);
                    }}
                    isLoading={saveLoading}
                    title="Are you sure?"
               >
                    You are about to add a domain to full list target account
               </ConfirmSaveChange>
          </>
     )
}

export default CompanyTableInfo