import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import CenterSpinner from '../../../../../../general/Loadings/CenterSpinner.js';
import { API_SALESFORCE_RULE, API_SALESFORCE_SELECT_FIELDS, TYPE_SHOW_UNSAVE_CHANGE } from '../../../../../../../constants/index.js';
import { setRuleHaveEditting } from '../../../../../../../actions/common.js';
import { callSalesforceApi } from '../../../../../../../apiCaller.js';
import { SFSetProgressHistoricalSync } from '../../../../../../../actions/external.js';

export const ObjectDetailContext = React.createContext({});

const DataSourceConnection = React.lazy(() => import(`./steps/DataSourceConnection.js`));
const SystemFields = React.lazy(() => import(`./steps/SystemFields.js`));
const SetUpFields = React.lazy(() => import(`./steps/SetUpFields.js`))
const PipelineFields = React.lazy(() => import(`./steps/PipelineFields.js`));
const ValueFields = React.lazy(() => import(`./steps/ValueFields.js`));
const RelationshipFields = React.lazy(() => import(`./steps/RelationshipFields.js`));
const TargetFields = React.lazy(() => import(`./steps/TargetFields.js`));
const OtherFields = React.lazy(() => import(`./steps/OtherFields.js`));
const CustomFields = React.lazy(() => import(`./steps/CustomFields.js`));
const Relationships = React.lazy(() => import(`./steps/Relationships.js`));
const NameDescription = React.lazy(() => import(`./steps/NameDescription.js`));
const HistoricalSync = React.lazy(() => import(`./steps/HistoricalSync.js`));

const ObjectImportDetail = ({ id }) => {
     const dispatch = useDispatch();
     const [isLoading, setIsLoading] = useState(false);
     const [activeStep, setActiveStep] = useState(1);
     const [stepsData, setStepsData] = useState({});
     const [initStepsData, setInitStepsData] = useState({});
     const [listObjectsField, setListObjectsField] = useState([]);
     const [listFields, setListFields] = useState([]);

     const fetchDataSelectFields = (object, getNew = false) => {
          let endPoint = `${API_SALESFORCE_SELECT_FIELDS}?ruleId=${id}`;
          if (getNew) {
               endPoint = `${endPoint}&getNew=true`;
          }

          return callSalesforceApi(endPoint, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         const selectFieldsOptions = data.map((field) => ({
                              value: field.name,
                              label: field.name,
                              type: field.type,
                              custom: field.custom,
                         }));

                         data.unshift({ value: '', label: 'Select a field', type: 'default' });
                         selectFieldsOptions.unshift({ value: '', label: 'Select a field', type: 'default' });

                         setListFields(data);
                         setListObjectsField(selectFieldsOptions);
                    }
               } else {
                    toast.error('Get object info failed!');
               }
          });
     };
     const fetchData = () => {
          if (!id) return;

          setIsLoading(true);
          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;
                    const rule = data;

                    if (data) {
                         dispatch(SFSetProgressHistoricalSync(data.jobActive));
                         setStepsData(data);
                         setInitStepsData(data);
                         const object = rule.object;
                         fetchDataSelectFields(object).finally(() => setIsLoading(false));
                    }
               } else {
                    toast.error('Get data failed!');
               }
          });
     };
     useEffect(fetchData, []); // eslint-disable-line react-hooks/exhaustive-deps

     const fetchDataGetNew = () => {
          if (!id) return;

          callSalesforceApi(`${API_SALESFORCE_RULE}/${id}`, 'GET').then((response) => {
               if (response && response.status === 200) {
                    const { data } = response.data;

                    if (data) {
                         setStepsData(data);
                         setInitStepsData(data);
                    }
               }
          });
     };

     const handleHasChange = () => {
          dispatch(setRuleHaveEditting({ show: true, type: TYPE_SHOW_UNSAVE_CHANGE.EDIT_SIMPLE }));
     };

     const mappingComponents = [
          { key: 'systemFieldsMapping', component: SystemFields },
          { key: 'setUpFieldsMapping', component: SetUpFields },
          { key: 'pipelineFieldsMapping', component: PipelineFields },
          { key: 'valueFieldsMapping', component: ValueFields },
          { key: 'relationshipFieldsMapping', component: RelationshipFields },
          { key: 'targetFieldsMapping', component: TargetFields },
          { key: 'otherFieldsMapping', component: OtherFields },
          { key: 'customFieldsMapping', component: CustomFields },
          { key: 'relationships', component: Relationships },
     ];

     const hasData = (obj) => obj !== undefined && obj.length > 0;

     const componentsWithData = mappingComponents.reduce((components, { key, component }) => {
          if (hasData(initStepsData[key]) || (!initStepsData.isDefault &&  ['setUpFieldsMapping', 'relationshipFieldsMapping'].includes(key) )) {
               components.push({ component, index: components.length });
          }
          return components;
     }, []);

     let count = componentsWithData.length;
     componentsWithData.push({ component: NameDescription, index: count++ }, { component: HistoricalSync, index: count });

     return (
          <React.Suspense fallback={<CenterSpinner />}>
               <div className="rule-detail object-import-detail">
                    {isLoading ? (
                         <CenterSpinner />
                    ) : (
                         <div className="cvr-create-new">
                              <h1>{id ? `Edit: Salesforce Object Import - ${stepsData.name}` : `Create: Salesforce Object Import`}</h1>
                              <p>{id ? `Edit` : `Create a new`} external data source by configuring the settings below.</p>
                              <ObjectDetailContext.Provider
                                   value={{
                                        id,
                                        activeStep,
                                        setActiveStep,
                                        initStepsData,
                                        setInitStepsData,
                                        stepsData,
                                        setStepsData,
                                        handleHasChange,
                                        listObjectsField,
                                        listFields,
                                        fetchDataGetNew,
                                        fetchDataSelectFields,
                                   }}
                              >
                                   <DataSourceConnection />
                                   {componentsWithData.map((mapping) => {
                                        const { component: Component, index } = mapping;
                                        return <Component key={index} step={index + 2} />;
                                   })}
                              </ObjectDetailContext.Provider>
                         </div>
                    )}
               </div>
          </React.Suspense>
     );
};

export default ObjectImportDetail;
