import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { API_CLIENT_GET_LIST_SEGMENT_REPORT, API_CLIENT_REPORTING_CONVERSION_NAME, BQ_JOB_STATUS, REPORT_NAMES } from '../../../../constants';
import { callTokenApi } from '../../../../apiCaller';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRangeReports, setListSegmentReport } from '../../../../actions/subscriber';
import { handleGetSortSegment } from './EventExplorer';
import {  toastError } from '../../../../utils';

// import { handleGetOption } from './EventExplorer';

const useFirstFetchReportData = (props) => {
     const {
          isConversionNameRate,
          isConversionName,
          isConversionNameValue,
          setConversionName,
          setConversionNameMetric,
          reportName,
          setSegmentSticky,
          setSort,
          // metrics,
          filterMetric,
          dataParamValue,
          recordProfile,
          dateRangeConfig,
          setDateRangeConfig,
          dateScopes,
          setListSegmentReportApply,
          setListChartSegmentApply
     } = props;
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const [conversionNameLoading, setConversionNameLoading] = useState(true);
     const bodyData = {
          accountId: activeAccount.id,
          isConversionName,
          isConversionNameRate,
          isConversionNameValue,
     };
     const dispatch = useDispatch();

     useEffect(() => {
          const fetchData = async () => {
               if( !recordProfile ) {
                    await callTokenApi(
                         API_CLIENT_GET_LIST_SEGMENT_REPORT.replace(':accountId', activeAccount.id) + `?reportName=${reportName}`,
                         'GET',
                         null
                    ).then((response) => {
                         if (response.status === 200) {
                              const { segmentReport, segmentSticky, defaultConfig, segmentChart } = response.data;
     
                              if (Array.isArray(segmentReport)) {
                                   if (dataParamValue && dataParamValue.segment && Array.isArray(dataParamValue.segment)) {
                                        const newSegment = segmentReport.map((item)=>{
                                             if(dataParamValue.segment[0] && dataParamValue.segment[0].id === item.id){
                                                  return {...item, apply: dataParamValue.segment[0].apply}  
                                             } else {
                                                  return {...item, apply: false};
                                             }
                                        })

                                        dispatch(setListSegmentReport(newSegment));

                                   } else {
                                        dispatch(setListSegmentReport(segmentReport));
                                   }
                                   
                              }
                              
                              if (dataParamValue && dataParamValue.segment) {
                                   setSort(
                                        handleGetSortSegment({
                                             listSegmentReportApply: dataParamValue.segment.filter(
                                                  (item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE
                                             ),
                                             filterMetric,
                                        })
                                   );
                                   setListSegmentReportApply(dataParamValue.segment);
                              } else {
                                   setSort(
                                        handleGetSortSegment({
                                             listSegmentReportApply: segmentReport.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE),
                                             filterMetric,
                                        })
                                   );
                                   setListSegmentReportApply(segmentReport.filter((item) => item.apply && item.queryStatus === BQ_JOB_STATUS.DONE).sort((a, b) => {
                                        if (a.isCustom !== b.isCustom) {
                                             return a.isCustom ? 1 : -1;
                                        }
                                        return new Date(a.orderBy) - new Date(b.orderBy)
                                   }))
                              }
                              setListChartSegmentApply(segmentChart)
                              setSegmentSticky(segmentSticky);
                              if (!isEmpty(defaultConfig) && !dataParamValue){
                                   const { dateRangeConfig: dateRangeDefault } = defaultConfig;
                                   if (dateRangeDefault && dateRangeDefault.sticky && dateRangeDefault.selection) {
                                        const { compare, comparePeriod, datePreset, dateScope, periodType, selection } = dateRangeDefault;
                                        const newDefaultDateRange = {
                                             selection: {
                                                  startDate: dayjs(selection.startDate),
                                                  endDate: dayjs(selection.endDate),
                                             },
                                        };
                         
                                        if (comparePeriod && compare) {
                                             newDefaultDateRange.compare = {
                                                  startDate: dayjs(compare.startDate),
                                                  endDate: dayjs(compare.endDate),
                                             };
                                        }
                         
                                        let defaultDateRangeConfig = {
                                             comparePeriod,
                                             periodType,
                                             datePreset,
                                        }
                         
                                        if(dateScopes.includes(dateScope)){
                                             defaultDateRangeConfig.dateScope = dateScope
                                        }
                         
                                        setDateRangeConfig({
                                             ...dateRangeConfig, 
                                             ...defaultDateRangeConfig,
                                             sticky: defaultConfig.dateRangeConfig.sticky

                                        });
                                       
                                        dispatch(setDateRangeReports(newDefaultDateRange));
                                   } else {
                                        setDateRangeConfig({
                                             ...dateRangeConfig, 
                                             sticky: defaultConfig.dateRangeConfig.sticky

                                        });
                                   }

                                }

                         } else {
                              toastError(response);
                         }
                    });
               }
               

               if (isConversionName) {
                    let conversionNameMetric = [];
                    const conversionNameRateMetric = [];
                    const conversionName = [];
                    const conversionNameValue = [];
                    await callTokenApi(API_CLIENT_REPORTING_CONVERSION_NAME, 'POST', bodyData)
                         .then((response) => {
                              if (response && response.status === 200) {
                                   const conversionMetric = response.data.conversionName;
                                   const { eCommerceName } = response.data;
                                   conversionMetric.forEach((item) => {
                                        conversionNameMetric.push({ key: item.value, label: item.label, type: item.type });
                                        conversionName.push({ label: item.label, value: item.value, type: item.type });
                                   });

                                   const conversionValueMetric = response.data.conversionNameValue;
                                   conversionValueMetric.forEach((item) => {
                                        conversionNameMetric.push({ key: item.value, label: item.label, convId: item.convId, type: item.type })
                                        conversionName.push({ label: item.label, value: item.value, convId: item.convId, type: item.type })
                                   });

                                   if (reportName !== REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {

                                        eCommerceName.forEach((item) => {
                                             conversionNameMetric.push({ key: item.value, label: item.label, type: item.type });
                                             conversionName.push({ label: item.label, value: item.value, type: item.type });
                                        });
                                   }

                                   if (isConversionNameRate) {
                                        const conversionRateMetric = response.data.conversionNameRate;
                                        conversionRateMetric.forEach((item) => {
                                             conversionNameRateMetric.push({ key: item.value, label: item.label, type: item.type });
                                             // conversionName.push({ label: item.label, value: item.value, type: item.type });
                                        });
                                   }
                                   if (isConversionNameValue) {
                                        const conversionValueMetric = response.data.conversionNameValue;
                                        conversionValueMetric.forEach((item) => {
                                             conversionNameValue.push({ key: item.value, label: item.label, type: item.type });
                                        });
                                   }

                                   conversionNameMetric = [...conversionNameMetric, ...conversionNameRateMetric, ...conversionNameValue];
                              }
                         })
                         .finally(() => {
                              setConversionNameMetric(conversionNameMetric);
                              setConversionName(conversionName);
                              // setFilterMetric(handleGetOption({ option: [...metrics] }));
                              // setFilterConversionName(handleGetOption({ option: [...metrics] }));
                              setConversionNameLoading(false);
                         });
               } else {
                    setConversionNameMetric([]);
                    setConversionName([]);
                    // setFilterConversionName(handleGetOption({ option: [...metrics]}));
                    // setFilterMetric(handleGetOption({ option: [...metrics, ...[]] }));
                    setConversionNameLoading(false);
               }
          };
          fetchData();
     }, []); // eslint-disable-line react-hooks/exhaustive-deps

     return conversionNameLoading;
};

export default useFirstFetchReportData;
