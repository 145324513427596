import { toast } from "react-toastify";
import { callSalesforceApi } from "../apiCaller";
import { API_SALESFORCE_GET_JOB_LIST } from "../constants";
import { setSubscriberState } from "./subscriber";
import { PUSHER_UPDATE_JOBS, PUSHER_UPDATE_JOBS_CONNECTION, SF_GET_LIST_JOBS, SF_SET_PROGRESS_RULE, SF_UPDATE_PROGRESS_RULE } from "./types"

export const SFGetListJobs = (data) => {
     return {
          type: SF_GET_LIST_JOBS,
          payload: data
     }
}

export const fetchSalesforceJobsRequest =
     (accountId, activePage = 1, itemsPerPage = 10) =>
          (dispatch) => {
          const data = {
               accountId: accountId,
               offset: (activePage - 1) * itemsPerPage,
               limit: itemsPerPage,
          };
          const request = `${API_SALESFORCE_GET_JOB_LIST}?accountId=${accountId}&offset=${data.offset}&limit=${data.limit}`;

          setSubscriberState(true);
          callSalesforceApi(request, 'GET')
               .then((response) => {
                    if (response && response.status === 200) {
                         const data = response.data;
                         data.itemsPerPage = itemsPerPage;
                         data.activePage = activePage;
                         dispatch(SFGetListJobs(data));
                    } else {
                         toast.error('Get Log Salesforce Fail!');
                    }
               })
               .finally(() => {
                    setSubscriberState(false);
               });
     };


export const SFUpdateJobs = (data) => {
     return {
          type: PUSHER_UPDATE_JOBS,
          payload: data
     }
}

export const SFUpdateJobsConnection = (data) => {
     return {
          type: PUSHER_UPDATE_JOBS_CONNECTION,
          payload: data
     }
}

export const SFSetProgressHistoricalSync = (data) => {
     return {
          type: SF_SET_PROGRESS_RULE,
          payload: data,
     };
};

export const SFUpdateProgressHistoricalSync = (data) => {
     return {
          type: SF_UPDATE_PROGRESS_RULE,
          payload: data,
     };
};

