export const AUTH_PATH = {
     LOGIN: '/login',
     GOOGLE_LOGIN_REDIRECT: '/google-login-redirect',
     REGISTER: '/register',
     ACTIVATION: '/auth/activate/:token',
     INVITE_USER: '/invite-user/:token',
     PASSWORD_FORGOT: '/auth/password/forgot',
     PASSWORD_RESET: '/auth/password/reset/:token',
     UNSUBSCRIBE: '/unsubscribe/:dataEncode',
     INSTALLATION_SCREEN: '/installation',
     WELCOME_SCREEN: '/welcome',
     CERTIFICATE: '/certificate/:userCertId',
     INVOICE: '/invoice/:id',
     INVOICE_UPCOMING: '/invoice-upcoming/:id',
     CALENDAR_EVENT_WATCH: '/calendar-event/watch',
     SCHEDULE_DEMO: '/schedule-demo',
     CONNECT_SALESFORCE: '/salesforce',
};

export const ADMIN_PATH = {
     DASHBOARD: '/dashboard',
     PROFILE: '/profile',
     ACCOUNTS: '/accounts',
     ACCOUNTS_WITH_PARAMS_ID: '/accounts/:id',
     ACCOUNT_WITH_COMPANY_MATCH: '/accounts/company-matches/:accountId',
     ACCOUNTS_ADD_USER: '/accounts/add-user',
     PACKAGES: '/packages',
     PACKAGES_CREATE: '/packages/create',
     PACKAGES_WITH_PARAMS_ID: '/packages/:id',
     ACTIVITIES: '/activities',
     USERS: '/users',
     USERS_CREATE: '/users/create',
     USERS_WITH_PARAMS_ID: '/users/:id',
     LISTENERS: '/listeners',
     LISTENERS_CREATE: '/listeners/create',
     LISTENERS_WITH_PARAMS_ID: '/listeners/:id',
     LISTENERS_ACCOUNTS: '/listeners/accounts/:listenerId',
     FEATURES: '/features',
     FEATURES_CREATE: '/features/create',
     FEATURES_WITH_PARAMS_ID: '/features/:id',
     EVENTS: '/events',
     EVENTS_CREATE: '/events/create',
     EVENTS_WITH_PARAMS_ID: '/events/:id',
     ORDERS: '/orders',
     CONTROL_ADMIN: '/control-admin',
     MEDIA: '/media',
     REDIS: '/redis',
     VIEW_REDIS: '/redis/:key',
     TERMS: '/terms',
     TERMS_CREATE: '/terms/create',
     TERMS_WITH_PARAMS_ID: '/terms/:id',
     COURSES: '/courses',
     COURSES_CREATE: '/courses/create',
     COURSES_WITH_PARAMS_ID: '/courses/:id',
     SECTIONS: '/sections',
     SECTIONS_CREATE: '/sections/create',
     SECTIONS_WITH_PARAMS_ID: '/sections/:id',
     LESSONS: '/lessons',
     LESSONS_CREATE: '/lessons/create',
     LESSONS_WITH_PARAMS_ID: '/lessons/:id',
     CERTIFICATES: '/certificates',
     CERTIFICATES_CREATE: '/certificates/create',
     CERTIFICATES_WITH_PARAMS_ID: '/certificates/:id',
     TESTS: '/tests',
     TESTS_CREATE: '/tests/create',
     TESTS_CREATE_QUESTION: '/tests/question',
     EDIT_QUESTION_WITH_PARAMS_ID: '/tests/question/:id',
     EDIT_QUESTION_WITH_2_PARAMS_ID: '/tests/:testId/:id',
     TESTS_WITH_PARAMS_ID: '/tests/:id',
     COUPONS: '/coupons',
     COUPON_PARTNERS_WITH_PARAMS_ID: '/coupons/:id',
     USER_COUPON: '/user-coupon',
     PARTNERS: '/partners',
     PARTNER_WITH_PARAMS_ID: '/partners/:id',
     OFFICE_HOUR: '/office-hours',
     OFFICE_HOUR_CREATE: '/office-hours/create',
     OFFICE_HOUR_WITH_PARAMS_ID: '/office-hours/:id',
     USER_PARTNERS: '/user-partners',
     USER_COURSE: '/user-course',
     USER_COURSE_WITH_PARAMS_ID: '/user-course/:id',
     USER_PARTNERS_WITH_PARAMS_ID: '/user-partners/:id',
     GOOGLE_CALENDAR: '/google-calendar',
     VISITORS: '/visitors',
     SESSIONS: '/sessions',
     DESTINATIONS: '/destinations',
     DESTINATIONS_CREATE: '/destinations/create',
     DESTINATIONS_EDIT: '/destinations/:id',
     DESTINATIONS_ACCOUNTS: '/destinations/accounts/:destinationId',
     CUSTOM_REPORT: '/custom-report',
     DIMENSIONS: '/dimensions',
     DIMENSION_CREATE: '/dimensions/create',
     DIMENSION_WITH_PARAMS_ID: '/dimensions/:id',
     METRICS: '/metrics',
     METRIC_CREATE: '/metrics/create',
     METRIC_WITH_PARAMS_ID: '/metrics/:id',
     CONTRACT_PLANS: '/contract-plans',
     CONTRACT_PLANS_CREATE: '/contract-plans/create',
     CONTRACT_PLANS_EDIT: '/contract-plans/:id',
     CONTRACT_PLAN_ACCOUNTS: '/contract-plans/accounts/:packageId',
     COMPANY_MATCHES: '/company-matches',
     ACCOUNT_COMPANY_MATCH: '/contract-plans/accounts/company-matches/:accountId',
     CONTRACT_PLAN_COMPANY_MATCH: '/contract-plans/company-matches/:packageId',
     NOTIFICATIONS: '/notifications',
     EXTERNALS: '/externals',
     EXTERNALS_CREATE: '/externals/create',
     EXTERNALS_EDIT: '/externals/:id',
};

export const SUBSCRIBER_PATH = {
     DASHBOARD: '/:secondId/dashboard',
     COMPARE_ALL_PLAN: '/:secondId/compare-all-plan',
     UPGRADE_ACCOUNT: '/:secondId/upgrade-account',
     USERS: '/:secondId/users',
     VERSIONS: '/:secondId/versions',
     CREATE_ACCOUNT: '/:secondId/create-account',
     ACCOUNT_SETTINGS: '/:secondId/account-settings',
     WEBSITE: '/:secondId/website',
     LISTENERS: '/:secondId/listeners',
     TAG_MANAGERS: '/:secondId/tag-managers',
     BROWSER_ADDONS: '/:secondId/browser-addons',
     DATA_LAYER_VARIABLES: '/:secondId/data-layer-variables',
     CUSTOM_DATA_LAYER_VARIABLES: '/:secondId/custom-web-data/custom-data-layer-variables',
     CUSTOM_DATA_LAYER_VARIABLE_RULE_CREATE: '/:secondId/custom-web-data/custom-data-layer-variables/create-rule',
     CUSTOM_DATA_LAYER_VARIABLE_RULE_EDIT: '/:secondId/custom-web-data/custom-data-layer-variables/:id',
     ENGAGEMENT_RULE_CREATE: '/:secondId/session-engagement/create-rule',
     ENGAGEMENT_RULE_EDIT: '/:secondId/session-engagement/:id',
     CUSTOM_BROWSER_STORAGE_VARIABLES: '/:secondId/custom-browser-storage-variables',
     CUSTOM_FORM_CATEGORIES_VARIABLES: '/:secondId/form-categories',
     CUSTOM_BROWSER_STORAGE_VARIABLE_RULE_CREATE: '/:secondId/custom-browser-storage-variables/create-rule',
     CUSTOM_BROWSER_STORAGE_VARIABLE_RULE_EDIT: '/:secondId/custom-browser-storage-variables/:id',
     BROWSER_STORAGE_VARIABLES: '/:secondId/browser-storage-variables',
     CONSENT_LISTENER: '/:secondId/consent-listener',
     CUSTOM_VARIABLE_RULES: ':secondId/custom-variable-rules',
     USER_SOURCE_RULES: ':secondId/user-source-rules',
     BROWSER_STORAGE: ':secondId/browser-storage',
     NATIVE_DATA_LAYER: '/:secondId/native-data-layers',
     MONITORING: ':secondId/monitoring',
     REPORTING: ':secondId/reporting',
     FEATURES: ':secondId/features',
     EVENT_SETTINGS: ':secondId/event-settings',
     GLOBAL_REPORTING: '/:secondId/global-reporting',
     POPULATE_FIELD: ':secondId/populate-field',
     SELECTOR_RULES: ':secondId/selector-rules',
     CLICK_SETTINGS: ':secondId/click-settings',
     MONITORING_DASHBOARD: '/:secondId/monitoring',
     MONITORING_NOTIFICATIONS_V2: '/:secondId/monitoring/notifications',
     MONITORING_NOTIFICATION_DETAILS_V2: '/:secondId/monitoring/notifications/:id',
     MONITORING_NOTIFICATIONS: '/:secondId/monitoring-notifications',
     MONITORING_NOTIFICATION_DETAILS: '/:secondId/monitoring-notifications/:id',
     TRIGGER_AND_CONVERSIONS: '/:secondId/triggers-and-conversions',
     TRIGGER: '/:secondId/triggers-and-conversions/trigger',
     CONVERSION: '/:secondId/triggers-and-conversions/conversion',
     TRIGGER_CREATE: '/:secondId/triggers-and-conversions/trigger/create',
     TRIGGER_EDIT: '/:secondId/triggers-and-conversions/trigger/:id',
     CONVERSION_CREATE: '/:secondId/triggers-and-conversions/conversion/create',
     CONVERSION_EDIT: '/:secondId/triggers-and-conversions/conversion/:id',
     CUSTOM_MONITORING_RULES: '/:secondId/monitoring/custom-monitoring',
     CUSTOM_MONITORING_RULE_CREATE: '/:secondId/monitoring/custom-monitoring/create-rule',
     CUSTOM_MONITORING_RULE_EDIT: '/:secondId/monitoring/custom-monitoring/:id',
     PLAN_USAGE: '/:secondId/plan-usage',
     BILLING_INVOICES: '/:secondId/billing-invoices',
     AUTOMATIC_MONITORING_CONVERSIONS: '/:secondId/monitoring/monitor-conversions',
     AUTOMATIC_MONITORING_LISTENERS: '/:secondId/monitoring/listeners',
     AUTOMATIC_MONITORING_DESTINATIONS: '/:secondId/monitoring/destinations',
     AUTOMATIC_MONITORING_FORMCATEGORIES: '/:secondId/monitoring/formcategories',
     DESTINATIONS: '/:secondId/destinations',
     CONSENT_RULES: '/:secondId/consent-rules',
     CONSENT_RULES_CREATE: '/:secondId/consent-rules/create',
     CONSENT_RULES_EDIT: '/:secondId/consent-rules/:id',
     CONSENT_LOGS: '/:secondId/consent-logs',
     PERSONAL_DATA: '/:secondId/personal-data',
     DATA_REQUESTS: '/:secondId/data-requests',
     DATA_ACTIONS: 'data-action',
     INSIGHTS: '/:secondId/INSIGHTS',
     INSIGHTS_DATA_LAYER: '/:secondId/overview',
     INSIGHTS_USER_EXPLORER: '/:secondId/user-explorer',
     INSIGHTS_EVENT_EXPLORER: '/:secondId/event-explorer',
     INSIGHTS_PAGE_VIEW_EXPLORER: '/:secondId/explore-data/page-view-explorer',
     INSIGHTS_CONVERSION_EVENTS: '/:secondId/explore-data/conversion-events',
     INSIGHTS_SESSION_EXPLORER: '/:secondId/session-explorer',
     INSIGHTS_PERSON_EXPLORER: '/:secondId/person-explorer',
     INSIGHTS_CONVERSION_EXPLORER: '/:secondId/conversion-explorer',
     INSIGHTS_CONVERSION_NAME_EXPLORER: '/:secondId/conversion-name-explorer',
     INSIGHTS_ENTRANCE_AND_EXIT_EXPLORER: '/:secondId/entrance-and-exit-explorer',
     INSIGHTS_USER_SOURCE_SESSION_EXPLORER: '/:secondId/user-source-session-explorer',
     INSIGHTS_USER_SOURCE_USER_EXPLORER: '/:secondId/user-source-user-explorer',
     INSIGHTS_SEGMENT_BUILDER: '/:secondId/segment-builder/coming-soon',
     INSIGHTS_AUDIENCE: '/:secondId/audience',
     INSIGHTS_FORM_CATEGORY: '/:secondId/explore-data/form-details',
     INSIGHTS_FORM_SUBMISSION_EVENTS: '/:secondId/explore-data/form-submission-events',
     INSIGHTS_FORM_CONVERSION_SOURCE_ATTRIBUTION: '/:secondId/explore-data/conversion-source-attribution',

     INSIGHTS_ATTRIBUTES: '/:secondId/attributes',
     INSIGHTS_CONSENT: '/:secondId/attributes/consent',
     INSIGHTS_GEOGRAPHY: '/:secondId/attributes/geography',
     INSIGHTS_DEVICES: '/:secondId/attributes/devices',
     INSIGHTS_BROWSERS: '/:secondId/attributes/browsers',
     INSIGHTS_ACQUISITION: '/:secondId/acquisition',
     INSIGHTS_CHANNEL: '/:secondId/acquisition/channel',
     INSIGHTS_SOURCE: '/:secondId/acquisition/source',
     INSIGHTS_UTMS: '/:secondId/acquisition/utms',
     INSIGHTS_REFERRALS: '/:secondId/acquisition/referrals',
     INSIGHTS_ENGAGEMENT: '/:secondId/engagement',
     INSIGHTS_NEW_RETURNING: '/:secondId/engagement/new-returning',
     INSIGHTS_SESSION_COUNT: '/:secondId/engagement/session-count',
     INSIGHTS_SESSION_DURATION: '/:secondId/engagement/session-duration',
     INSIGHTS_CONVERSION_COUNT: '/:secondId/engagement/conversion-count',
     INSIGHTS_PEOPLE_COUNT: '/:secondId/engagement/people-user-count',
     INSIGHTS_CONTENT: '/:secondId/content',
     INSIGHTS_ALL_PAGES: '/:secondId/content/all-pages',
     INSIGHTS_LANDING_PAGES: '/:secondId/content/landing-pages',
     INSIGHTS_EXIT_PAGES: '/:secondId/content/exit-pages',
     INSIGHTS_ENTRANCE_EXIT_PAGES: '/:secondId/content/entrance-and-exit-pages',
     INSIGHTS_SCROLL_DEPTH: '/:secondId/content/scroll-depth',
     INSIGHTS_VISIBLE_TIME: '/:secondId/content/visible-time',
     INSIGHTS_PAGE_FLOW: '/:secondId/content/page-flow',
     INSIGHTS_CONVERSIONS: '/:secondId/conversions',
     INSIGHTS_ALL_CONVERSIONS: '/:secondId/conversions/all-conversions',
     INSIGHTS_BY_URL: '/:secondId/conversions/by-url',
     INSIGHTS_BY_SOURCE: '/:secondId/conversions/by-source',
     INSIGHTS_BY_UTM: '/:secondId/conversions/by-utm',
     INSIGHTS_BY_LANDING_PAGE: '/:secondId/conversions/by-landing-page',
     INSIGHTS_BY_PAGE_JOURNEY: '/:secondId/conversions/by-page-journey',
     INSIGHTS_ATTRIBUTION: '/:secondId/conversions/attribution',
     //SOURCES
     CUSTOM_HTML_FORMS: '/:secondId/listeners/custom-html-forms',
     DATA_CATEGORIES: '/:secondId/data-categories',

     //eCommerce
     INSIGHTS_ECOMMERCE: '/:secondId/ecommerce',
     INSIGHTS_ECOMMERCE_PURCHASE_FLOW: '/:secondId/ecommerce/purchase-flow',
     INSIGHTS_ECOMMERCE_ITEMS: '/:secondId/ecommerce/items',
     INSIGHTS_ECOMMERCE_PURCHASE_ACTIVITY: '/:secondId/ecommerce/purchase-activity',
     INSIGHTS_ECOMMERCE_PURCHASE_TRANSACTIONS: '/:secondId/ecommerce/purchase-transaction',
     INSIGHTS_ECOMMERCE_OVERVIEW: '/:secondId/ecommerce/overview',
     INSIGHTS_TRANSACTIONS: '/:secondId/ecommerce/transactions',
     INSIGHTS_ECOMMERCE_BY_SOURCE: '/:secondId/ecommerce/by-source',
     INSIGHTS_ECOMMERCE_BY_UTM: '/:secondId/ecommerce/by-utm',
     INSIGHTS_ECOMMERCE_PRODUCT_PERFORMANCE: '/:secondId/ecommerce/product-performance',
     INSIGHTS_PURCHASE_FUNNEL: '/:secondId/ecommerce/purchase-funnel',
     //EXPLORE_DATA
     INSIGHTS_EXPLORE_DATA: '/:secondId/explore-data',
     INSIGHTS_EVENTS: '/:secondId/explore-data/events',
     INSIGHTS_PAGES: '/:secondId/explore-data/pages',
     INSIGHTS_EXPLORE_CONVERSIONS: '/:secondId/explore-data/explore-conversions',
     INSIGHTS_SESSIONS: '/:secondId/explore-data/sessions',
     INSIGHTS_SESSION_REPLAYS: '/:secondId/explore-data/replays',
     INSIGHTS_USERS: '/:secondId/explore-data/users',
     INSIGHTS_PEOPLE: '/:secondId/explore-data/people',
     INSIGHTS_REVEALED_COMPANIES: '/:secondId/explore-data/revealed-companies',
     INSIGHTS_CUSTOM_REPORT: '/:secondId/custom-report',

     INSIGHTS_RETENTION: '/:secondId/retention/coming-soon',
     INSIGHTS_USER_SOURCE: '/:secondId/user-sources/coming-soon',
     INSIGHTS_PATH_TO_EVENT: '/:secondId/path-to-event/coming-soon',
     INSIGHTS_BEHAVIOR: '/:secondId/behavior',
     INSIGHTS_OVERVIEW: '/:secondId/overview/coming-soon',
     INSIGHTS_PATH_TO_CONVERSION: '/:secondId/path-to-conversion/coming-soon',
     INSIGHTS_CONVERSION_LOCATIONS: '/:secondId/conversion-locations/coming-soon',
     INSIGHTS_E_COMMERCE: '/:secondId/e-commerce',
     INSIGHTS_TRANSACTION: '/:secondId/transaction/coming-soon',
     INSIGHTS_PATH_TO_TRANSACTION: '/:secondId/path-to-transaction/coming-soon',
     INSIGHTS_PRODUCT_PERFORMANCE: '/:secondId/product-performance/coming-soon',
     INSIGHTS_SHOPPING_BEHAVIOR: '/:secondId/shopping-behavior/coming-soon',
     INSIGHTS_CHECKOUT_BEHAVIOR: '/:secondId/checkout-behavior/coming-soon',
     INSIGHTS_CART_ABADONS: '/:secondId/cart-abadons/coming-soon',
     INSIGHTS_DOWNFUNNEL: '/:secondId/downfunnel',
     INSIGHTS_CONFIGURATION: '/:secondId/configuration/coming-soon',
     INSIGHTS_LEADS: '/:secondId/leads/coming-soon',
     INSIGHTS_ACCOUNTS: '/:secondId/accounts/coming-soon',
     INSIGHTS_OPPORTUNITIES: '/:secondId/opportunities/coming-soon',
     INSIGHTS_CUSTOM_REPORTS: '/:secondId/custom-reports',
     INSIGHTS_CUSTOM_REPORT_DETAIL: '/:secondId/custom-report/:id',
     INSIGHTS_REPORT_TOOL: '/:secondId/report-tool',
     INSIGHTS_METRICS_AND_DIMENSIONS: '/:secondId/metrics-and-dimensions',
     INSIGHTS_CUSTOM_DATA_SETS: '/:secondId/custom-data-sets',
     INSIGHTS_CHANNELS: '/:secondId/channels',
     SEGMENT: '/:secondId/segments',

     //Goals
     GOALS: '/:secondId/goals',
     GOALS_WEB_CONVERSIONS: '/:secondId/goals/web-conversions',
     GOALS_ECOMMERCE: '/:secondId/goals/ecommerce',
     GOALS_WEB_TRIGGERS: '/:secondId/goals/triggers',
     GOALS_WEB_FUNNELS: '/:secondId/goals/web-funnels',
     GOALS_FORM_CATEGORIES: '/:secondId/goals/form-categories',
     GOALS_SALES_PIPELINE: '/:secondId/goals/sales-pipeline',
     GOALS_COHORTS: '/:secondId/goals/cohorts',
     GOALS_TARGET_ACCOUNTS: '/:secondId/goals/target-accounts',
     GOALS_COMPETITORS: '/:secondId/goals/competitors',

     INSIGHTS_REVEAL: '/:secondId/reveal',
     EXTERNAL_DATA: '/:secondId/external-data',
     EXTERNAL_DATA_GOOGLE_ADS: '/:secondId/external-data/google-ads',
     EXTERNAL_DATA_SALESFORCE: '/:secondId/external-data/salesforce',
     EXTERNAL_DATA_FACEBOOK_ADS: '/:secondId/external-data/facebook-ads',
     EXTERNAL_DATA_MICROSOFT_ADS: '/:secondId/external-data/microsoft-ads',
     EXTERNAL_DATA_TIK_TOK: '/:secondId/external-data/tik-tok',
     EXTERNAL_DATA_FROM_FILE: '/:secondId/external-data/from-file',
     EXTERNAL_DATA_LINKEDIN_ADS: '/:secondId/external-data/linkedin-ads',

     SAVED_REPORTS: '/:secondId/saved-reports',
};

export const GOALS_PROPS = {
     'Web Conversions': {
          title: 'Web Conversions',
          description:
               'Centralize how you define conversions on your website, such as views of critical pages, specific types of form submissions, chat activity and more. Conversions are based on rules that match inside your data layer activity and can be easily distributed to any destination you use.',
     },
     'eCommerce': {
          title: 'eCommerce Events',
          description:
               "ListenLayer automatically generates structured eCommerce data layer activity when you enable an eCommerce Listener. The events below are made available as pre-built data actions within your Destinations so you can easily distribute them. They also power eCommerce reporting insight ListenLayer's Insights Engine. ",
     },
     'Triggers': {
          title: 'Triggers',
          description:
               'Write rules to define triggers based on acitivity inside the data layer. Triggers can be used to send specific data to destinations. We automatically create commonly used triggers based on Listeners you have enabled.',
     },
     'Web Funnels': { title: 'Web Funnels' },
     'Form Categories': {
          title: 'Manage Form Categories',
          description:
               'ListenLayer automatically finds forms on your website and helps you name then and group them into categories that drive your conversion tracking.',
     },
     'Sales Pipeline': { title: 'Sales Pipeline' },
     'Cohorts': { title: 'Cohorts' },
     'Target Accounts': { title: 'Target Accounts' },
     'Competitors': { title: 'Competitors' },
     'Sales Conversion': { title: 'Sales Conversion' },
}

export const SUBSCRIBER_MY_ACCOUNT_PATH = {
     PROFILE: '/my-account/profile',
     EMAIL_NOTIFICATIONS: '/my-account/email-notifications',
     AFFILIATE: '/my-account/affiliate',
     SUPPORT_TICKETS: '/my-account/support-tickets',
     SUPPORT_TICKETS_WITH_PARAMS_ID: '/my-account/support-tickets/:id',
     MY_ACCOUNTS: '/my-account',
     MASTERCLASS: '/my-account/masterclass',
     LESSON: '/my-account/masterclass/:id',
     CERTIFICATION: '/my-account/certification',
     OFFICE_HOURS: '/my-account/office-hours',
     BECOME_A_PARTNER: '/my-account/become-a-partner',
     PAYMENT_METHODS: '/my-account/payment-methods',
};

export const SUPPORT_PATH = {
     PROFILE: '/profile',
     ACCOUNTS: '/accounts',
     ACCOUNTS_WITH_PARAMS_ID: '/accounts/:id',
     TICKETS: '/tickets',
     TICKETS_DETAIL: '/tickets/:id',
     ACCOUNT_TICKETS: '/tickets-account',
     ACCOUNT_TICKETS_WITH_ID: '/tickets-account/:id',
     USER_TICKETS: '/tickets-user/',
     USER_TICKETS_WITH_ID: '/tickets-user/:id',
     DATA_REQUEST: '/data-request',
     DATA_REQUEST_WITH_ID: '/data-request/:id',
};

export const STEP_GUIDE = {
     STEP0: 0,
     STEP1: 1,
     STEP2: 2,
     STEP3: 3,
     STEP4: 4,
     STEP5: 5,
     STEP6: 6,
     STEP7: 7,
};
