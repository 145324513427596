import React, { useContext, useMemo, useEffect, useState } from 'react';
import { EDIT_FULL_LIST_TABLE_COLUMNS } from '../../constants';
import CIcon from '@coreui/icons-react';
import { debounce } from 'lodash';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { CInput, CInputCheckbox } from '../../../../../../migration/CInput';
import { FullListTableContext } from '.';

const TableHeaderRow = () => {
     const { searchObject, setSearchObject, setPagination, setDataInPage, currentBO } = useContext(FullListTableContext);

     const [currentSearch, setCurrentSearch] = useState(searchObject)

     const searchItems = useMemo(() => {
          return debounce(() => {
               setSearchObject(currentSearch);
               setDataInPage({})
               setPagination(prev => {
                    return {
                         ...prev,
                         page: 1
                    }
               })
          }, 1000);
     }, [currentSearch]); // eslint-disable-line

     useEffect(() => {
          searchItems();

          return () => {
               searchItems.cancel();
          };

     }, [currentSearch]); // eslint-disable-line

     const onChangeInput = (value, key) => {
          setCurrentSearch(prev => {
               return {
                    ...prev,
                    [key]: value
               }
          })
     }

     return (
          <>
               <tr className="header-row table-wrapper">
                    {EDIT_FULL_LIST_TABLE_COLUMNS.map((col) => {
                         return (
                              <td key={col.id} style={{ minWidth: `${col.minWidth}`, width: col.width }}>
                                   {col.name}

                                   {col.name === 'Profile' && (
                                        <span className="tooltip-wrapper ml-2">
                                             <Tippy
                                                  className='target-account'
                                                  content={
                                                       <div className='tooltip-content'>
                                                            <span> Please publish and wait a minute to view the profile! </span>
                                                       </div>
                                                  }
                                                  placement='top'
                                                  maxWidth='340px'
                                                  theme="segment"
                                             >
                                                  <span>
                                                       <CIcon icon='icon-info' height={12} width={12} />
                                                  </span>
                                             </Tippy>
                                        </span>
                                   )}
                              </td>
                         );
                    })}
               </tr>
               <tr className="data-row table-wrapper">
                    {EDIT_FULL_LIST_TABLE_COLUMNS.map((col) => {
                         return (
                              <td key={col.id} style={{ minWidth: `${col.minWidth}`, width: col.width }}>
                                   {col.name !== 'Profile' && col.name !== 'BO' && (
                                        <CInput
                                             value={currentSearch[col.id] ? currentSearch[col.id] : ''}
                                             onChange={(e) => onChangeInput(e.target.value, col.id)}
                                        />
                                   )}

                                   {col.name === 'BO' && !currentBO && (
                                        <div className={`value-bo ${!!currentSearch[col.id] ? 'checked' : ''}`}>
                                             <CInputCheckbox
                                                  id={`inline-checkbox-search`}
                                                  checked={!!currentSearch[col.id]}
                                                  onChange={(e) => onChangeInput(e.target.checked, col.id)}
                                             // disabled={isDisableCheckBO}
                                             />

                                             <label htmlFor={`inline-checkbox-search`}></label>

                                        </div>
                                   )}

                              </td>
                         );
                    })}
               </tr>
          </>
     );
};

export default TableHeaderRow;
