import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react';
import { useOutsideHandling } from '../../../../../utils';
import { EventExplorerContext } from '../EventExplorer';
import { API_CLIENT_SEGMENT_CHART } from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { useSelector } from 'react-redux';
import CFormGroup from '../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../migration/CInput';
import CLabel from '../../../../migration/CLabel';

const SelectSegment = ({chartSegment, setChartSegment, segments}) => {
     const [showDropdown, setShowDropdown] = useState(false)
     const activeAccountId = useSelector((state) => state.subscriber.activeAccount.id);

     const { reportName, segmentSticky, setListChartSegmentApply, showData } = useContext(EventExplorerContext);

     const getCurrentSegment = (chartSegment) => {
          return segments.map((item) => {
               if(chartSegment.find((current) => current.id === item.id)) {
                    return {...item, applyChart: true}
               } else {
                    return {...item, applyChart: false}
               }
          })
     }

     const [currentSegment, setCurrentSegment] = useState(getCurrentSegment(chartSegment))

     useEffect(() => {
          const newSegment = getCurrentSegment(chartSegment)
          if(newSegment.filter((item) => item.applyChart).length === 0) {
               const newChartSegment = segments.slice(0, 1).map((item) => { return { ...item, applyChart: true }})
               setChartSegment(newChartSegment)
               setCurrentSegment(getCurrentSegment(newChartSegment))
          } else {
               setChartSegment(newSegment.filter((item) => item.applyChart))
               setCurrentSegment(newSegment)
          }
     }, [segments]) // eslint-disable-line

     const handleClickBubble = () => {
          if (!showData) {
               return;
          }
          setTimeout(() => {
               if (!showDropdown) {
                    setShowDropdown(true);
               }
          }, 100);
     };

     const clickOutside = useCallback(() => {
          setShowDropdown(false);
          setCurrentSegment(getCurrentSegment(chartSegment))
     }, [chartSegment]); // eslint-disable-line

     const wrapperRef = useRef(null);
     useOutsideHandling(wrapperRef, clickOutside);

     const handleChangeSelected = (id, status) => {
          setCurrentSegment((prev) =>
               prev.map((item) => {
                    if (item.id === id) {
                         item.applyChart = status;
                    }

                    return item;
               })
          );
     };

     const onSubmit = () => {
          setShowDropdown(false);
          const currentChartSegment = currentSegment.filter((item) => item.applyChart)
          setChartSegment(currentChartSegment);
          setListChartSegmentApply(currentChartSegment)

          const data = {
               accountId: activeAccountId,
               segmentChart: currentChartSegment,
               reportName,
               segmentSticky
          }

          callTokenApi(`${API_CLIENT_SEGMENT_CHART}`, 'PUT', data)
     };

     return (
          <div className="chart-segment-dropdown">
               <div className="chart-segment-action" onClick={handleClickBubble}>
                    <div className="chart-segment-text">
                         <div className="mr-2 segment-icon d-flex align-items-center">
                              <CIcon icon="iconChartPie" style={{ cursor: 'pointer', width: '16px', height: '16px', marginRight: '2px' }} />
                              <span className="segment-item-level">
                                   {chartSegment.length > 1 || chartSegment.length === 0
                                        ? '+'
                                        : `S${segments.findIndex((item) => chartSegment[0].id === item.id) + 1}`}
                              </span>
                         </div>
                         <span className="chart-segment-desc">
                              {chartSegment.length === 0 ? 'Select segments' : chartSegment.length > 1 ? 'Multiple selected' : chartSegment[0].name}
                         </span>
                    </div>
                    <CIcon icon="iconArrowDown" width={20} height={20} />
               </div>

               {showDropdown && (
                    <div className="chart-segment-content" ref={wrapperRef}>
                         <div className="chart-segment-list">
                              {currentSegment.map((segment, index) => (
                                   <div className="chart-segment-item" key={index} htmlFor={`${index}-segment`}>
                                        <CFormGroup variant="custom-checkbox" inline htmlFor={`${index}-segment`}>
                                             <CInputCheckbox
                                                  custom
                                                  id={`${index}-segment`}
                                                  name={`${index}-segment`}
                                                  checked={typeof segment.applyChart !== 'undefined' ? segment.applyChart : false}
                                                  onChange={(e) => handleChangeSelected(segment.id, e.target.checked)}
                                                  // disabled={
                                                  //      (maximumSegmentSelect.length === 4 && !item.apply) ||
                                                  //      isLoading ||
                                                  //      item.queryStatus !== BQ_JOB_STATUS.DONE
                                                  // }
                                             />
                                             <CLabel variant="custom-checkbox" htmlFor={`${index}-segment`}>
                                                  <div className="mr-2 segment-icon d-flex align-items-center">
                                                       <CIcon
                                                            icon="iconChartPie"
                                                            style={{ cursor: 'pointer', width: '16px', height: '16px', marginRight: '2px' }}
                                                       />
                                                       <span className="segment-item-level">S{index + 1}</span>
                                                  </div>
                                                  <span>{segment.name}</span>
                                             </CLabel>
                                        </CFormGroup>
                                   </div>
                              ))}
                         </div>
                         <div className="d-flex justify-content-end align-items-center my-2 mr-2">
                              <CButton
                                   className="btn-apply"
                                   color="primary"
                                   onClick={() => onSubmit()}
                                   disabled={currentSegment.filter((item) => item.applyChart).length === 0 && chartSegment.length === 0}
                              >
                                   Apply
                              </CButton>
                         </div>
                    </div>
               )}
          </div>
     );
};

export default SelectSegment;
