import React, { useCallback, useContext, useState } from 'react';
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { FILTER_OPTIONS, FIND_ACCOUNT_CONDITION } from '../constants';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import SelectSearchField from '../../../../../general/custom-search-select/SelectSearchField';
import NewGroup from '../../../analytics/custom-report/NewGroup';
import CIcon from '@coreui/icons-react';
import { FindAccountContext } from '.';
import CLabel from '../../../../../migration/CLabel';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';
import Cfilters from './Cfilters';

const initialGroup = '';
const initialGroupFilter = {
     type: '',
     id: '',
     key: '',
     operator: '',
     value: '',
     conjunction: '',
};

const FindAccountCondition = () => {
     const { conditionValue, filterEvent, setFilterEvent, setShowCondition, setOffSet, setActivePage, setAdd, setAddAll } = useContext(FindAccountContext);
     const [newFilterEvent, setNewFilterEvent] = useState(filterEvent);
     const [errors, setErrors] = useState([]);
     const filterOptions = FILTER_OPTIONS;

     const addLvl2Conjunction = ({ lvl1GroupIndex, lvl2GroupIndex, conjunction, groupType }) => {
          let newGroups = [...newFilterEvent];

          if (!newGroups[lvl1GroupIndex][`g0`].conjunction) {
               newGroups[lvl1GroupIndex][`g0`].conjunction = 'and';
          }
          if (!newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction) {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`].conjunction = conjunction;
          }
          newGroups[lvl1GroupIndex][`g${lvl2GroupIndex + 1}`] = { ...initialGroupFilter };

          setNewFilterEvent(newGroups);
          setErrors([]);
     };

     const updateLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...newFilterEvent];

          newGroups[lvl1GroupIndex - 1].conjunction = conjunction;
          setNewFilterEvent(newGroups);
     };

     const deleteLvl1Group = (lvl1GroupIndex) => {
          let newGroups = [...newFilterEvent];

          // if (!newGroups[lvl1GroupIndex].conjunction) {
          //      newGroups[lvl1GroupIndex - 1].conjunction = '';
          // } else
          if (newGroups[lvl1GroupIndex - 1]) {
               newGroups[lvl1GroupIndex - 1].conjunction = newGroups[lvl1GroupIndex].conjunction;
          }

          newGroups.splice(lvl1GroupIndex, 1);
          setNewFilterEvent(newGroups);
     };

     const deleteLvl2Group = ({ lvl1GroupIndex, lvl2GroupIndex, groupType }) => {
          let newGroups = [...newFilterEvent];
          let newGroup = { ...newGroups[lvl1GroupIndex] };
          let newGroupConjunction = newGroup.conjunction;
          delete newGroup.conjunction;
          const newGroupLength = Object.keys(newGroup).length;

          if (!newGroup[`g${lvl2GroupIndex}`].conjunction && lvl2GroupIndex !== 0) {
               newGroup[`g${lvl2GroupIndex - 1}`].conjunction = '';
          }

          for (let i = lvl2GroupIndex; i < newGroupLength; i++) {
               if (newGroupLength === 1) {
                    newGroup[`g${i}`] = {
                         type: '',
                         id: '',
                         key: '',
                         operator: '',
                         value: '',
                         conjunction: '',
                    };
                    setNewFilterEvent([newGroup]);
                    setErrors([]);
               } else {
                    newGroup[`g${i}`] = { ...newGroup[`g${i + 1}`] };
                    let newErrors = [...errors];

                    if (newErrors[lvl1GroupIndex] && newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`]) {
                         delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`];
                    }
          
                    setErrors(newErrors);
               }
          }

          if (newGroupLength > 1) {
               delete newGroup[`g${newGroupLength - 1}`];
               newGroup.conjunction = newGroupConjunction;
          }

          newGroups[lvl1GroupIndex] = { ...newGroup };
          setNewFilterEvent(newGroups);
     };

     const updateLvl2Conjunction = ({ lvl1GroupIndex, conjunction }) => {
          const newGroups = [...newFilterEvent];

          let newGroupsUpdate = { ...newGroups[lvl1GroupIndex] };

          delete newGroupsUpdate.conjunction;

          Object.keys(newGroupsUpdate).forEach((key, index) => {
               if (index < Object.keys(newGroupsUpdate).length - 1) {
                    newGroupsUpdate[key].conjunction = conjunction;
               }
          });

          newGroups[lvl1GroupIndex] = { ...newFilterEvent[lvl1GroupIndex], ...newGroupsUpdate };

          setNewFilterEvent(newGroups);
     };

     const selectLvl1Conjunction = (lvl1GroupIndex, conjunction) => {
          let newGroups = [...newFilterEvent];

          if (!newGroups[lvl1GroupIndex].conjunction) {
               newGroups.push({
                    conjunction: '',
                    g0: { ...initialGroup },
               });
          }

          newGroups[lvl1GroupIndex].conjunction = conjunction;
          setNewFilterEvent(newGroups);
     };

     const changeAValueOfGroupFilters = ({ lvl1GroupIndex, lvl2GroupIndex, propName, propValue }) => {
          let newGroups = [...newFilterEvent];
          let type = filterOptions.filter((item) => item.value === propValue);

          if (propName === 'type') {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`] = {
                    ...initialGroupFilter,
                    id: propValue,
                    type: type[0].label,
                    // dataType: type[0].dataType,
                    operator: initialGroupFilter.operator,
               };
          } else if (propName === 'operator') {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`] = {
                    ...newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`],
                    value: '',
                    operator: propValue,
               };
          } else {
               newGroups[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName] = propValue;
          }

          let newErrors = [...errors];

          if (newErrors[lvl1GroupIndex]) {
               delete newErrors[lvl1GroupIndex].group;

               if (newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`]) {
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`].row;
                    delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`][propName];

                    if (propName === 'type') {
                         delete newErrors[lvl1GroupIndex][`g${lvl2GroupIndex}`];
                    }
               }
          }

          setErrors(newErrors);
          setNewFilterEvent(newGroups);
     };

     const validate = useCallback(() => {
          // check require field
          let validate = true;
          let newErrors = [];
          if (newFilterEvent[0]['g0'].type !== '' || newFilterEvent[0]['g0'].operator !== '' || newFilterEvent[0]['g0'].value !== '') {
               for (let index = 0; index < newFilterEvent.length; index++) {
                    const lvl1Group = newFilterEvent[index];
                    newErrors[index] = {};
                    let workingLvl1Group = { ...lvl1Group };
                    delete workingLvl1Group.conjunction;

                    for (var lvl2GroupKey in workingLvl1Group) {
                         const { type, operator, value } = workingLvl1Group[lvl2GroupKey];
                         let lv2Errors = {};

                         // if operator = 1 of ['exav', 'nex', 'itp', 'itft'], value variable is empty, so don't need to check value
                         if (['exav', 'nex', 'itp', 'itft', 'dr'].includes(operator)) {
                              validate = true;
                         } else if (!type || !operator || (typeof value === 'string' && !value.trim() && !value)) {
                              validate = false;
                         }

                         if (!validate) {
                              if (!type) {
                                   lv2Errors = { ...lv2Errors, type: 'This field is required.' };
                              }
                              if (!operator) {
                                   lv2Errors = { ...lv2Errors, operator: 'This field is required.' };
                              }
                              if (!value) {
                                   lv2Errors = { ...lv2Errors, value: 'This field is required.' };
                              }
                         }
                         newErrors[index][lvl2GroupKey] = lv2Errors;
                    }
               }
          }

          // check validate dupplicate groups
          let duplicateGroups = false;
          let groupsToValidate = newFilterEvent.map((group) => {
               let newGroup = { ...group };
               delete newGroup.conjunction;

               return newGroup;
          });

          for (let i = 0; i < groupsToValidate.length - 1; i++) {
               for (let j = i + 1; j < groupsToValidate.length; j++) {
                    if (isEqual(groupsToValidate[i], groupsToValidate[j])) {
                         newErrors[j] = { group: 'There are duplicate group' };
                         duplicateGroups = true;
                         validate = false;
                         break;
                    }
               }

               if (duplicateGroups) {
                    break;
               }
          }

          if (!duplicateGroups) {
               for (let index = 0; index < groupsToValidate.length; index++) {
                    const lvl1Group = groupsToValidate[index];
                    const lvl1GroupLength = Object.keys(lvl1Group).length;

                    for (let i = 0; i < lvl1GroupLength; i++) {
                         let iGroup = { ...lvl1Group[`g${i}`] };
                         iGroup.conjunction = '';

                         for (let j = i + 1; j < lvl1GroupLength; j++) {
                              let jGroup = { ...lvl1Group[`g${j}`] };
                              jGroup.conjunction = '';

                              if (isEqual(iGroup, jGroup)) {
                                   if (!newErrors[index]) {
                                        newErrors[index] = {};
                                   }
                                   if (!newErrors[index][`g${j}`] || Object.keys(newErrors[index][`g${j}`]).length === 0) {
                                        newErrors[index][`g${j}`] = { row: "There are duplicate row's values in the same group" };
                                   }
                                   duplicateGroups = true;
                              }
                         }
                    }

                    if (duplicateGroups) {
                         validate = false;
                    }
               }
          } else {
               validate = false;
          }

          setErrors(newErrors);
          return validate;
     }, [newFilterEvent]);

     const handleSubmit = (groups) => {
          if (validate()) {
               setFilterEvent(groups);
               setActivePage(1)
               setOffSet(0)
               setShowCondition(false);
               setAdd([])
               setAddAll(false)
          }
     };

     const handleClearAllFilters = () => {
          const groups = [
               {
                    g0: {
                         id: '',
                         type: '',
                         operator: '',
                         value: '',
                         conjunction: '',
                    },
               },
          ];
          setShowCondition(false);
          setNewFilterEvent(groups);
          setFilterEvent(groups)
     };

     const handleKeyDown = (e, groups) => {
          if (e.key === 'Enter') {
               handleSubmit(groups);
          }
     };

     const checklistOptions = {};

     if (conditionValue) {
          Object.entries(conditionValue).forEach(([key, value]) => {
               let newValue = value;

               if(key === 'monthlyVisitors'){
                    newValue = uniq(value.map((item) => item.trim().toUpperCase()));
               }

               checklistOptions[key] = newValue.map((item) => ({
                    label: item,
                    value: item,
               }));
          });

     }

     return (
          <div className="rule-detail filter-report" style={{ padding: '10px 10px 0px 10px' }}>
               {newFilterEvent.map((lvl1Group, lvl1GroupIndex) => {
                    let workingLvl1Group = { ...lvl1Group };
                    delete workingLvl1Group.conjunction;
                    const lvl1GroupConjunction = lvl1GroupIndex > 0 ? newFilterEvent[lvl1GroupIndex - 1].conjunction : '';
                    const groupDupplicate = errors[lvl1GroupIndex] && errors[lvl1GroupIndex].group;
                    return (
                         <React.Fragment key={lvl1GroupIndex}>
                              <CCard className="conditions-group conditions-validate">
                                   <CCardHeader className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center ">
                                             <h6>{`Rule Group ${lvl1GroupIndex + 1}`}</h6>
                                             {lvl1GroupIndex > 0 && (
                                                  <div className="d-flex align-items-center button-new-rule">
                                                       <CButton
                                                            className={`btn-add-or-rule ${lvl1GroupConjunction === 'and' ? 'active' : ''}`}
                                                            color={lvl1GroupConjunction === 'and' ? 'info' : 'dark'}
                                                            onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'and')}
                                                       >
                                                            AND
                                                       </CButton>
                                                       <CButton
                                                            className={`btn-add-or-rule ${lvl1GroupConjunction === 'or' ? 'active' : ''}`}
                                                            color={lvl1GroupConjunction === 'or' ? 'info' : 'dark'}
                                                            onClick={() => updateLvl1Conjunction(lvl1GroupIndex, 'or')}
                                                       >
                                                            OR
                                                       </CButton>
                                                       match this group
                                                  </div>
                                             )}
                                        </div>

                                        {newFilterEvent.length > 1 && (
                                             <CButton className="remove-group" onClick={() => deleteLvl1Group(lvl1GroupIndex)}>
                                                  Remove Group
                                             </CButton>
                                        )}
                                   </CCardHeader>
                                   <CCardBody>
                                        <div className="list-rule-wrapper list-rules-and-or">
                                             <div className="mb-2 pb-2">
                                                  {Object.entries(workingLvl1Group).map(([key, lvl2Group], lvl2GroupIndex) => {
                                                       const lvl2GroupConjunction =
                                                            (lvl1Group && lvl1Group[`g0`] && lvl1Group[`g0`].conjunction) || '';

                                                       const errorLvl2Group =
                                                            errors[lvl1GroupIndex] && errors[lvl1GroupIndex][key] ? errors[lvl1GroupIndex][key] : {};
                                                       const rowDupplicateValidate = errorLvl2Group.row;
                                                       const isLastRow = key === `g${Object.entries(workingLvl1Group).length - 1}`;
                                                       const isValidate = rowDupplicateValidate || (isLastRow && groupDupplicate);

                                                       let selectOption = filterOptions.find((option) => {
                                                            return option.value === lvl2Group.id;
                                                       });

                                                       return (
                                                            <React.Fragment key={key}>
                                                                 {lvl2GroupIndex === 0 && (
                                                                      <div className="d-flex align-items-center mb-2">
                                                                           <CLabel className="text-decoration-none">Match</CLabel>
                                                                           <div className="button-new-rule ml-1">
                                                                                <CButton
                                                                                     className={`btn-all-or-any ${lvl2GroupConjunction === 'and' ? 'active' : ''
                                                                                          }`}
                                                                                     onClick={() =>
                                                                                          updateLvl2Conjunction({
                                                                                               lvl1GroupIndex,
                                                                                               conjunction: 'and',
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     All
                                                                                </CButton>
                                                                                <CButton
                                                                                     className={`btn-all-or-any ${lvl2GroupConjunction === 'or' ? 'active' : ''
                                                                                          }`}
                                                                                     onClick={() =>
                                                                                          updateLvl2Conjunction({
                                                                                               lvl1GroupIndex,
                                                                                               conjunction: 'or',
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     Any
                                                                                </CButton>
                                                                           </div>
                                                                           <CLabel className="mb-0 text-decoration-none">
                                                                                conditions in this group.
                                                                           </CLabel>
                                                                      </div>
                                                                 )}
                                                                 <div
                                                                      className={`mb-3 conditions-match d-flex align-items-top${!!isValidate ? ' is-invalid' : ''
                                                                           }`}
                                                                 >
                                                                      <div
                                                                           className={`${errorLvl2Group.type ? 'is-invalid' : ''
                                                                                } select-type custom-report-select`}
                                                                           style={{ width: '220px' }}
                                                                      >
                                                                           <CLabel className="text-decoration-none">Type</CLabel>
                                                                           <SelectSearchField
                                                                                tabIndex="0"
                                                                                value={
                                                                                     selectOption
                                                                                          ? { label: selectOption.label }
                                                                                          : { label: 'Select...' }
                                                                                }
                                                                                onChange={(e) =>
                                                                                     changeAValueOfGroupFilters({
                                                                                          lvl1GroupIndex,
                                                                                          lvl2GroupIndex,
                                                                                          propName: 'type',
                                                                                          propValue: e.value,
                                                                                     })
                                                                                }
                                                                                maxMenuHeight={225}
                                                                                className={`${errorLvl2Group.type ? 'is-invalid' : ''}`}
                                                                                invalid={!!errorLvl2Group.type}
                                                                                options={filterOptions}
                                                                                onKeyDown={(e) => handleKeyDown(e, newFilterEvent)}
                                                                           />
                                                                           <CInvalidFeedback>{errorLvl2Group.type}</CInvalidFeedback>
                                                                      </div>
                                                                      <Cfilters
                                                                           lvl1GroupIndex={lvl1GroupIndex}
                                                                           lvl2GroupIndex={lvl2GroupIndex}
                                                                           lvl2Group={lvl2Group}
                                                                           condition={FIND_ACCOUNT_CONDITION}
                                                                           changeAValueOfGroup={changeAValueOfGroupFilters}
                                                                           filterEvent={newFilterEvent}
                                                                           handleKeyDown={handleKeyDown}
                                                                           checklistOptions={checklistOptions}
                                                                           error={errors[lvl1GroupIndex] && errors[lvl1GroupIndex][key]}
                                                                           isFindAccount={true}
                                                                      />
                                                                      <div
                                                                           className={`button-delete-rule`}
                                                                           style={{
                                                                                marginLeft: "10px",
                                                                                zIndex: '9',
                                                                           }}
                                                                      >
                                                                           {(Object.keys(workingLvl1Group).length > 1 ||
                                                                                (selectOption && selectOption.value)) && (
                                                                                     <CIcon
                                                                                          name="iconDeleteField"
                                                                                          className="icon-delete"
                                                                                          onClick={() =>
                                                                                               deleteLvl2Group({
                                                                                                    lvl1GroupIndex,
                                                                                                    lvl2GroupIndex,
                                                                                                    groupType: 'permanent-filters',
                                                                                               })
                                                                                          }
                                                                                     />
                                                                                )}
                                                                      </div>
                                                                 </div>
                                                                 <CInvalidFeedback>{rowDupplicateValidate}</CInvalidFeedback>
                                                                 {isLastRow && <CInvalidFeedback>{groupDupplicate}</CInvalidFeedback>}
                                                                 {lvl2GroupIndex === Object.entries(workingLvl1Group).length - 1 && (
                                                                      <>
                                                                           <CButton
                                                                                className="btn-add-row"
                                                                                onClick={() =>
                                                                                     addLvl2Conjunction({
                                                                                          lvl1GroupIndex,
                                                                                          lvl2GroupIndex,
                                                                                          // conjunction: lvl2GroupConjunction,
                                                                                          groupType: 'permanent-filters',
                                                                                     })
                                                                                }
                                                                           >
                                                                                <CIcon name="iconAddField" className="icon-add" />
                                                                                <CLabel className="add-row">ADD ROW</CLabel>
                                                                           </CButton>
                                                                      </>
                                                                 )}
                                                            </React.Fragment>
                                                       );
                                                  })}
                                             </div>
                                        </div>
                                   </CCardBody>
                              </CCard>
                              {lvl1GroupIndex === newFilterEvent.length - 1 && (
                                   <NewGroup lvl1GroupIndex={lvl1GroupIndex} selectLvl1Conjunction={selectLvl1Conjunction} />
                              )}
                         </React.Fragment>
                    );
               })}
               <CRow className="filter-report-action mb-3">
                    <CCol>
                         <div className="d-flex align-items-center">
                              <CButton
                                   type="submit"
                                   className="px-4"
                                   color="primary"
                                   onClick={() => handleSubmit(newFilterEvent)}
                              >
                                   APPLY
                              </CButton>
                              <CButton
                                   // disabled={isLoading}
                                   onClick={() => handleClearAllFilters()}
                              >
                                   <CLabel>clear all</CLabel>
                                   <CIcon name="iconDeleteCircle" />
                              </CButton>
                         </div>
                    </CCol>
               </CRow>
          </div>
     )

}

export default FindAccountCondition;