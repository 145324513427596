import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { CButton, CModal, CModalBody, CModalTitle, CProgress } from '@coreui/react';
import XLSX from 'xlsx';
import { groupBy } from 'lodash';

import { callTokenApi } from '../../../../../../apiCaller';
import { toastError } from '../../../../../../utils';
import { API_CLIENT_GET_LIST_EXPORT_ALL_FORM, API_CLIENT_UPDATE_FORM_CATEGORIES } from '../../../../../../constants';

import CFormGroup from '../../../../../migration/CFormGroup';
import CLabel from '../../../../../migration/CLabel';
import { CInputFile, CSelect } from '../../../../../migration/CInput';

import {
     setSubscriberState,
     setAllFormsRequiring,
     setFormRequiringAction,
     setFormInventory,
     setAllFormsInventory,
     setFormIgnored,
     setFormRequiringEdit,
     setFormInventoryEdit,
     setFormsDraft,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsIgnored,
     handleFetchCountFormsInventory,
     handleUpdateCustomCategoriesDetails,
     handleFetchFormsInventory,
     handleUpdateChildFormsInventory,
     setListFormsChild,
} from '../../../../../../actions/subscriber';

const IMPORT_OPTIONS = [
     {
          LABEL: 'Ignore forms that already have category and name',
          VALUE: 'ignore',
          OPTIONAL_TEXT: 'The records will ignore your existing category and name.',
     },
     {
          LABEL: 'Overwrite the entire existing table with new values - Overwrite form drafts',
          VALUE: 'overwrite-all',
          OPTIONAL_TEXT: 'The new records will overwrite your entire existing table.',
     },
     {
          LABEL: 'Overwrite the entire existing table with new values - Not overwrite form drafts',
          VALUE: 'overwrite-no-draft',
          OPTIONAL_TEXT: 'The new records will overwrite your entire existing table.',
     },
];

const ImportTableModalForm = ({ className, formsInventory, toggleModal, exportToFile }) => {
     const inputFileRef = useRef(null);

     const [importedFileName, setImportedFileName] = useState('');
     const [importedRows, setImportedRows] = useState([]);
     const [importedValues, setImportedValues] = useState([]);
     const [importMethod, setImportMethod] = useState('');
     const [isUploadedError, setIsUploadedError] = useState(false);
     const [isUploadSuccess, setIsUploadSuccess] = useState(false);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [listDataExportForm, setListDataExportForm] = useState([]);

     const dispatch = useDispatch();
     const allFormsInventory = useSelector((state) => state.subscriber.allFormsInventory);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const formInventory = useSelector((state) => state.subscriber.formInventory);
     const listCustomCategories = useSelector((state) => state.subscriber.customCategories);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const filterFormCategories = useSelector((state) => state.subscriber.filterFormCategories);

     const accountId = activeAccount.id;

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === 'Categorize Forms') : [];

     const tableTemplate = [['ID', 'Location', 'Category', 'Name']];

     const headerDataFile = ['ID', 'Location', 'Category', 'Name'];

     const formsParent = useMemo(() => {
          const _formsParent = {}; // id: [form]

          for (let i = 0; i < listDataExportForm.length; i++) {
               const currentForm = listDataExportForm[i];

               if (!currentForm.parentId && !!currentForm.hasChild) {
                    _formsParent[currentForm.id] = currentForm;
               }
          }

          return _formsParent;
     }, [listDataExportForm]);

     const fetchListExportForms = useCallback(async () => {
          return await callTokenApi(API_CLIENT_GET_LIST_EXPORT_ALL_FORM.replace(':accountId', accountId), 'GET').then((response) => {
               if (response.status !== 200) {
                    toastError(response);
                    return;
               }

               const { exportAllForm } = response.data;
               if (exportAllForm?.length > 0) {
                    return exportAllForm;
               }
          });
     }, [accountId]);

     const resetState = () => {
          setImportedFileName('');
          setImportMethod('');
          setImportedValues([]);
          setIsUploadedError(false);
          setIsUploadSuccess(false);
     };

     const handleSelectImportMethod = useCallback((e) => {
          setImportMethod(e.target.value);
     }, []);

     const clickCancelBtn = () => {
          if (isUploadedError) {
               inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
               resetState();
          }

          toggleModal();
     };

     const onClose = () => {
          toggleModal();
     };

     const uploadFile = (e) => {
          const { files } = e.target;

          if (files && files.length > 0) {
               var reader = new FileReader();

               reader.onload = function (_) {
                    // Use reader.result
                    var workbook = XLSX.read(reader.result, {
                         type: 'binary',
                         raw: true, // Plain text parsing will not parse values ** (for example: Aug 2019 will not be parsed to 8/1/2019)
                    });

                    setImportedFileName(files[0].name);

                    // Range: 1 => Skip first row => Second row becomes the header
                    // defval: '' => All null and undefined points will be filled with defval
                    let jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 0, raw: false, defval: '' });

                    if (jsonData.length > 0) {
                         setImportedRows(jsonData);

                         const newImportedValues = jsonData.filter((item) => {
                              const cellInvalid = headerDataFile.some((cell) => item[cell].trim() === '');
                              return !cellInvalid;
                         });
                         setImportedValues(newImportedValues);
                    }
               };

               reader.readAsBinaryString(files[0]);
          }
     };

     const uploadTable = async () => {
          setIsUploadedError(false);
          setSubmitLoading(true);

          if (!importedValues[0]) {
               return setIsUploadedError(true);
          }

          const importedHeaderValues = Object.entries(importedValues[0]).map(([key, _]) => key.trim());

          // Make sure all keys of first element match with second row values, the order isn't matter
          const hasError = headerDataFile.some((secondRowValue) => {
               return importedHeaderValues.indexOf(secondRowValue) === -1; // Not found, break
          });

          if (hasError) {
               setIsUploadedError(true);
               return;
          }

          const _listDataExportForm = (await fetchListExportForms()) || [];
          if (_listDataExportForm.length) {
               setListDataExportForm(_listDataExportForm);
          }

          const newImportedValues = _listDataExportForm.reduce((acc, item) => {
               // Skip form inherit parent because it is inherited from the parent, the value is not overwritten
               if (item.inheritParent) return acc;

               const formData = importedValues.find((form) => {
                    const importFormValue = form.ID?.trim() || null;
                    const currentFormValue = item.formValue?.trim() || null;

                    const importLocation = form.Location?.trim() || null;
                    const currentLocation = item.formLocation?.trim() || null;

                    return importFormValue === currentFormValue && importLocation === currentLocation;
               });
               if (!formData) return acc;

               const newItem = { ...item };

               const formDataCategory = formData.Category?.trim() || null;
               const formDataName = formData.Name?.trim() || null;

               // Check changed data
               const changedData = item.categoryName !== formDataCategory || item.name !== formDataName;
               if (!changedData) return acc;

               // Prepare imported values
               const category = listCustomCategories.find((cat) => cat.name === formData.Category);

               const prepareUpdateForm = {
                    ...newItem,
                    name: formDataName,
                    categoryId: category?.id || null,
                    categoryName: category?.name || null,
                    categoryNameAdd: formDataCategory,
               };

               switch (importMethod) {
                    case 'ignore': // Bỏ qua form đã có category và name
                         if (newItem.categoryId || newItem.name) return acc;

                         acc.push(prepareUpdateForm);

                         return acc;
                    case 'overwrite-all': // Đè all forms đã có category và name
                         acc.push(prepareUpdateForm);

                         return acc;
                    case 'overwrite-no-draft': // Đè forms đã có category và name nhưng bỏ qua form draft
                         if (newItem.issue === 'isDrafted') return acc;

                         acc.push(prepareUpdateForm);

                         return acc;
                    default:
                         return acc;
               }
          }, []);

          setImportedValues(newImportedValues);
          setIsUploadSuccess(true);
          setSubmitLoading(false);
     };

     const applyChanges = async () => {
          inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
          setSubmitLoading(true);

          //#region Create form categories
          const categoriesCreatePayloads = [];
          const categoriesAdd = [];

          importedValues.forEach((importedValue) => {
               if (!importedValue.categoryId && importedValue.categoryNameAdd) {
                    const code = (Math.floor(Math.random() * 5555) + 1111).toString().substring(0, 4);
                    const categoryCreatePayload = {
                         id: `categories${code}`,
                         name: importedValue.categoryNameAdd.trim(),
                         isFallback: false,
                         flg_add: true,
                         type: null,
                         accountId,
                         disable: false,
                         isChecked: true,
                    };
                    categoriesCreatePayloads.push(categoryCreatePayload);
               }
          });

          if (categoriesCreatePayloads.length) {
               const nextDispatch = {};
               const responseData = await dispatch(handleUpdateCustomCategoriesDetails(categoriesCreatePayloads, nextDispatch));
               categoriesAdd.push(...responseData.categoryAdd);
          }
          //#endregion Create form categories

          for (let i = 0; i < importedValues.length; i++) {
               const importedValue = importedValues[i];
               const parentId = importedValue.parentId || null;

               // Assign new categoryId and categoryName
               if (!importedValue.categoryId) {
                    const categoryAdd = categoriesAdd.find((category) => category.name === importedValue.categoryNameAdd);
                    importedValues[i].categoryId = categoryAdd?.id || null;
                    importedValues[i].categoryName = categoryAdd?.name || null;
               }

               delete importedValues[i].categoryNameAdd;

               const updateIgnored = allFormsInventory.some(
                    (form) => form.id === importedValues[i].parentId && form.issue === 'isIgnored' && importedValues[i].inheritParent
               );

               if (
                    !importedValues[i].categoryId &&
                    !importedValues[i].name &&
                    importedValues[i].issue !== 'isIgnored' &&
                    importedValues[i].issue !== 'isDrafted'
               ) {
                    importedValues[i].issue = 'isAttention';
               }

               if (importedValues[i].issue === 'isDrafted') {
                    importedValues[i].issue = !importedValues[i].name && !importedValues[i].categoryId ? 'isAttention' : '';
               }

               if (updateIgnored) {
                    importedValues[i].issue = 'isIgnored';
               }

               // Form parent has category and check that the child form inherits from the parent form
               if (parentId && formsParent[parentId]?.categoryId) {
                    const sameCategory = importedValue.categoryName && formsParent[parentId].categoryName.trim() === importedValue.categoryName;
                    const sameName = importedValue.name && formsParent[parentId].name.trim() === importedValue.name;

                    if (sameCategory && sameName) {
                         importedValues[i].inheritParent = true;
                    }
               }
          }

          //#region Prepare import data payload
          const importValuesGroupBy = groupBy(importedValues, 'parentId');
          const importDataPayload = [];

          // The parent form has no category
          Object.entries(importValuesGroupBy).forEach(([parentId, values]) => {
               const firstFormChild = values[0];
               const isSameCategoryAndNameForFormChild =
                    values.length > 1 && values.every((form) => firstFormChild.categoryId === form.categoryId && firstFormChild.name === form.name);

               if (isSameCategoryAndNameForFormChild) {
                    const formParent = formsParent[parentId];

                    if (formParent && !formParent.categoryId) {
                         const newFormParent = {
                              ...formParent,
                              categoryId: firstFormChild.categoryId,
                              categoryName: firstFormChild.categoryName || null,
                              name: firstFormChild.name,
                              issue: 'isCategorized',
                         };

                         importDataPayload.push(newFormParent, ...values.map((item) => ({ ...item, inheritParent: true })));
                    }
                    return;
               }

               // Skip form parent
               const newValues = values.filter((value) => !value.hasChild);

               importDataPayload.push(...newValues);
          });
          //#endregion Prepare import data payload

          if (importDataPayload.length > 0) {
               const newDataRequest = {
                    accountId,
                    data: importDataPayload,
                    type: 'inventory',
               };

               callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, 'PUT', newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const { countChildInventory } = response.data;
                              if (filterFormCategories && filterFormCategories.length > 0) {
                                   dispatch(handleFetchFormsInventory(20, false, 0, filterFormCategories));
                              } else {
                                   const newData = [];
                                   formsInventory &&
                                        formsInventory.forEach((form) => {
                                             const formExisted = importDataPayload.find((item) => item.id === form.id);
                                             const childIgnored = importDataPayload.find(
                                                  (formEdit) =>
                                                       !formEdit.hasChild &&
                                                       formEdit.parentId === form.id &&
                                                       formEdit.inheritParent &&
                                                       formEdit.issue === 'isIgnored'
                                             );
                                             if (
                                                  !childIgnored ||
                                                  !countChildInventory.some((child) => child.id === childIgnored.parentId && child.count === 0)
                                             ) {
                                                  let newForm = form;
                                                  if (formExisted) {
                                                       newForm = { ...form, ...formExisted };
                                                  }

                                                  newData.push(newForm);
                                             }
                                        });
                                   dispatch(handleUpdateChildFormsInventory());
                                   if (className === 'inventory') {
                                        dispatch(setFormInventory(newData));
                                        const newAllFormInventory = allFormsInventory.map((form) => {
                                             const formExisted = importDataPayload.find((item) => item.id === form.id);

                                             if (formExisted) {
                                                  return { ...form, ...formExisted };
                                             }

                                             dispatch(setSubscriberState({ [`requiring${form.id}`]: undefined }));
                                             return form;
                                        });
                                        dispatch(setAllFormsInventory(newAllFormInventory));
                                   } else {
                                        dispatch(setAllFormsInventory(newData));
                                        const newDataFormInventory = formInventory.map((form) => {
                                             const formExisted = importDataPayload.find((item) => item.id === form.id);

                                             if (formExisted) {
                                                  return { ...form, ...formExisted };
                                             }
                                             return form;
                                        });
                                        dispatch(setFormInventory(newDataFormInventory));
                                   }
                              }

                              importDataPayload.forEach((form) => {
                                   if (form.hasChild) {
                                        dispatch(
                                             setListFormsChild({
                                                  [`requiring${form.id}`]: [],
                                                  [`numberOffset${form.id}`]: 0,
                                             })
                                        );
                                   }
                              });

                              // reset form requiring
                              dispatch(setFormRequiringAction(null));
                              dispatch(setAllFormsRequiring(null));
                              dispatch(setFormRequiringEdit(null));
                              dispatch(setFormInventoryEdit(null));
                              dispatch(setFormsDraft(null));
                              dispatch(setFormIgnored(null));
                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount, filterFormCategories));

                              if (rulesDefault && rulesDefault.length === 0) {
                                   dispatch(setSubscriberState({ formCategoriesRules: null }));
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setSubmitLoading(false);
                         resetState();
                         toggleModal();
                    });
          }
     };

     const cancelChange = () => {
          inputFileRef.current.value = ''; // Set input file value = '', so the user can upload the same file again
          resetState();
     };

     return (
          <div className="import-modal">
               <CModal visible onClose={onClose} alignment="center" portal={false} backdrop={!submitLoading ? true : 'static'}>
                    <CModalBody>
                         <div className="import-modal-body">
                              <CIcon icon="cil-x" className="icon-close-popup" height={14} onClick={onClose} />
                              <CModalTitle>Import Table Values</CModalTitle>
                              <p>
                                   Use this feature to import new values for your table. You can overwrite all existing values or add new values to
                                   the top or bottom of your table. To begin, export your Table Template below. Then populate the values and import
                                   it.
                              </p>
                              <div className={`import-modal-step${isUploadSuccess ? ' import-modal-step-disable' : ''}`}>
                                   <p>Step 1: Download my table's template:</p>
                                   <CButton
                                        color="light"
                                        onClick={() => exportToFile('csv', 'table-template', tableTemplate)}
                                        disabled={isUploadSuccess}
                                   >
                                        DOWNLOAD
                                   </CButton>
                              </div>
                              <p>Upload my new values:</p>
                              <CFormGroup className={`col-12${isUploadSuccess ? ' import-modal-file-disable' : ''}`}>
                                   <CInputFile
                                        innerRef={inputFileRef}
                                        custom
                                        id="custom-file-input"
                                        onChange={uploadFile}
                                        disabled={isUploadSuccess}
                                   />
                                   <CLabel htmlFor="custom-file-input" variant="custom-file">
                                        {importedFileName || 'Choose file...'}
                                   </CLabel>
                              </CFormGroup>
                              <CFormGroup className={`${isUploadSuccess ? 'import-modal-select-disable' : ''}`}>
                                   <CSelect value={importMethod} onChange={handleSelectImportMethod} disabled={isUploadSuccess}>
                                        <option value="" disabled hidden>
                                             Select an option...
                                        </option>
                                        {IMPORT_OPTIONS.map((importOption) => (
                                             <option key={importOption.VALUE} value={importOption.VALUE}>
                                                  {importOption.LABEL}
                                             </option>
                                        ))}
                                   </CSelect>
                              </CFormGroup>
                              {!isUploadSuccess && (
                                   <div className="import-modal-button">
                                        <CButton color="primary" disabled={!importedFileName || !importMethod || submitLoading} onClick={uploadTable}>
                                             UPLOAD
                                        </CButton>
                                        <CButton color="light" type="button" onClick={clickCancelBtn}>
                                             CANCEL
                                        </CButton>
                                   </div>
                              )}
                              {isUploadedError && (
                                   <div className="import-modal-error">
                                        <p>
                                             File upload error. Please make sure you are using your table template and saving the file as the same
                                             format it was downloaded in.
                                        </p>
                                   </div>
                              )}
                              {isUploadSuccess && (
                                   <div className="import-modal-progress">
                                        <CProgress color="success" value={100} size="md" />
                                        <p>
                                             File processed successfully. Out of the {importedRows.length} rows you uploaded, only{' '}
                                             {importedValues.length} rows met the requirements for upload and appeared in the form requiring action.
                                        </p>
                                        <p>{IMPORT_OPTIONS.find((importOption) => importOption.VALUE === importMethod).OPTIONAL_TEXT}</p>
                                        <div className="import-modal-button">
                                             <CButton color="primary" disabled={!importedValues?.length || submitLoading} onClick={applyChanges}>
                                                  {submitLoading ? <span className="dots-waiting">Waiting</span> : 'APPLY CHANGES'}
                                             </CButton>
                                             <CButton color="light" disabled={submitLoading} type="button" onClick={cancelChange}>
                                                  CANCEL CHANGES
                                             </CButton>
                                        </div>
                                   </div>
                              )}
                         </div>
                    </CModalBody>
               </CModal>
          </div>
     );
};

export default ImportTableModalForm;
