import React from 'react';
import {
     CCard,
     CCardBody,
     CButton,
     CRow,
     CCol,
     CCardHeader,
} from '@coreui/react';

const Step1 = ({ activeStep, setActiveStep, ruleType, setRuleType, actionType, id }) => {
     const isEditting = (actionType === 'edit');

     const handleSelectRule = (ruleType) => {
          setRuleType(ruleType);
          setActiveStep(3);
     }

     const numberActive = actionType === 'edit' && id ? 1 : 2;

     return (
          <>
               {
                    activeStep !== 6 && (
                         <CCard className={`cvr-step-1 ${activeStep !== numberActive ? 'difference-step' : 'cvr-step-card'}`}>
                              <CCardHeader>
                                   {
                                        activeStep !== numberActive ? (
                                             <div className="rule-step d-inline-flex justify-content-between align-items-center w-100">
                                                  <h5 className="mb-0 inactive">Step {numberActive}: Rule Type</h5>
                                                  <div className="d-inline-flex align-items-center">
                                                       <span className="given-data mr-3">{ruleType === "simple" ? "Simple Rule" : "Lookup Rule"}</span>
                                                       {
                                                            !isEditting && (
                                                                 <CButton className="btn-edit" onClick={() => setActiveStep(numberActive)}>
                                                                      Edit
                                                                 </CButton>
                                                            )
                                                       }
                                                  </div>
                                             </div>
                                        ) : (
                                             <h5>Step {numberActive}: Select rule type</h5>
                                        )
                                   }
                              </CCardHeader>
                              {
                                   activeStep === numberActive ? (
                                        <CCardBody>
                                             <div className="rule-step">
                                                  <p className='mb-4 ml-0'>Select how you will provide the data</p>
                                                  <CRow className="mb-3">
                                                       <CCol md="12">
                                                            <div className='d-flex align-items-center'>
                                                                 <CButton
                                                                      className="f-13 mr-5"
                                                                      disabled={isEditting}
                                                                      color={ruleType === "simple" ? "primary" : "dark"}
                                                                      onClick={() => handleSelectRule("simple")}
                                                                      style={{ cursor: isEditting ? 'default' : '' }}
                                                                 >
                                                                      Simple Rule
                                                                 </CButton>
                                                                 <p>Enter one or more conditions to define a single Trigger.</p>

                                                            </div>
                                                       </CCol>
                                                  </CRow>
                                                  <CRow className="mb-3">
                                                       <CCol md="12">
                                                            <div className='d-flex align-items-center'>
                                                                 <CButton
                                                                      className=" f-13 mr-5"
                                                                      disabled={isEditting}
                                                                      color={ruleType === "lookup" ? "primary" : "dark"}
                                                                      onClick={() => handleSelectRule("lookup")}
                                                                      style={{ cursor: isEditting ? 'default' : '' }}
                                                                 >
                                                                      Lookup Rule
                                                                 </CButton>
                                                                 <p>Define multiple triggers using a table that relies on similar conditions.</p>
                                                            </div>
                                                       </CCol>
                                                  </CRow>
                                             </div>
                                        </CCardBody>
                                   ) : ""
                              }
                         </CCard>
                    )
               }
          </>
     )
}

export default Step1
